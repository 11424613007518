import { handleError } from "../../../../utils/handles/errorHandle";

const createSKU = async (clientId,batchType,skuId,formData,axiosPrivate) => {
    try {
        const res = await axiosPrivate.post(`/api/qr/sku/save/${clientId}/${batchType}/${skuId}`, formData, {
            headers: {
                'Content-Type': { "Content-Type": "multipart/form-data" },
            },
            withCredentials: true
        });

        return res.data;
    } catch (error) {
        handleError(error);
    }
}

const editSKU = async (clientId,skuId,formData,axiosPrivate) => {
    try {
        const res = await axiosPrivate.post(`/api/qr/sku/edit/${clientId}/${skuId}`, formData, {
            headers: {
                'Content-Type':  { "Content-Type": "multipart/form-data" },
            },
            withCredentials: true
        });

        return res.data;
    } catch (error) {
        handleError(error);
    }
}

const getSkuIdsByBatchType = async(clientId,batchType,axiosPrivate) => {
    try {
        const res = await axiosPrivate.post(`/api/qr/sku/get-by-batchType`, { clientId: clientId,batchType }, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        })

        return [{ key: 'select option', value: '' }, ...res.data.data.map(item => ({ key: item.skuId, value: item.skuId, _id: item._id }))];
    } catch (error) {
        handleError(error);
    }
}

const getSkuData = async(clientId, skuId,axiosPrivate) => {
    try {
        const res = await axiosPrivate.post(`/api/qr/sku/get`, {
            clientId: clientId,
            skuId: skuId
        },
        {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        });

        return res.data.data;
    } catch (error) {
        handleError(error);
    }
} 

export{
    getSkuIdsByBatchType,
    getSkuData,
    createSKU,
    editSKU
}