import React, { useEffect, useState } from 'react'
import { SubmitButton } from '../ui/buttons/submitButton'
import Loader from '../ui/Loader/Loader';
import { FaLongArrowAltDown } from "react-icons/fa";
import { CiLock } from "react-icons/ci";
import { SORT_ORDER } from '../../config/constants';


function SortingTable({headerData, tableData, onSelction, nextPage, previousPage, sortTable, nextDisabled, previousDisabled, selectedSortedKey, selectedSortedOrder, loading}) {
    
    //Sorting variables
    const [sortedKey, setSortedKey] = useState('');
    const [sortedWay, setSortedWay] = useState('');

    useEffect(() => {
        setSortedKey(selectedSortedKey || headerData[0].key);
    }, [selectedSortedKey, headerData])

    useEffect(() => {
        setSortedWay(selectedSortedOrder || SORT_ORDER.ASC);
    }, [selectedSortedOrder])

    const sortValues = async (sortingKey) => {
        let sortingWay = SORT_ORDER.ASC;
        if(sortingKey === sortedKey) 
            if(sortedWay === SORT_ORDER.ASC) sortingWay = SORT_ORDER.DESC;
            else if(sortedWay === SORT_ORDER.DESC) sortingWay = SORT_ORDER.ASC;

        setSortedKey(sortingKey);
        setSortedWay(sortingWay);
        sortTable && sortTable(sortingKey, sortingWay);
    }

    return (
      <div className='w-[100%] mt-10'>
        <div>
            <div className='flex bg-bgLightBlue py-8 rounded-t-[12px]'>
                  {
                      headerData && headerData.map((header, index) => {
                        return (
                            <div key={index} style={header.sorting? {cursor: 'pointer'} : {}} className={`w-[${header.space}] flex justify-center items-center`} onClick={header.sorting ? () => sortValues(header.key): () => {}}>
                                {header.sorting && <FaLongArrowAltDown className={`z-1 h-7 text-gray-500 relative bottom-[1px] mx-1 ${sortedKey === header.key? 'text-red-400' : ''} transform  ${sortedKey === header.key && sortedWay === SORT_ORDER.ASC? 'rotate-0' : 'rotate-180'}`} />}
                                <p className={`text-center font-semibold text-3xl text-gray-600`}>{header.value}</p>
                            </div>
                        )
                  })
              }
            </div>
            <div className='bg-white w-[100%] rounded-b-[12px] mb-10 relative'>
            {loading && <Loader componental={true}/>}
                    {
                        tableData && Array.isArray(tableData) && tableData.map((rowData, index) => {
                            return <div key={index} className='flex flex-col justify-center items-center'>
                                <div className='flex w-[100%]' 
                                style={onSelction? {cursor: 'pointer'} : {}}
                                    onClick={() => {
                                    onSelction && onSelction(rowData);
                                    }}>
                                {
                                    headerData && headerData.map((header, index) => {
                                        const data = rowData[header.key];
                                        if(header.isAction) return <div key={index} className={`w-[${header.space}]`}>
                                            {data}
                                        </div>;
                                        if(data === "LOCKED" || data === "Locked")
                                            return <div key={index} className={`w-[${header.space}] text-3xl flex justify-center items-center`}>
                                                <div className='flex border-2 px-4 py-2'>
                                                    <p className='text-3xl mr-[1rem]'>LOCKED</p>
                                                    <CiLock />
                                                </div>
                                            </div>
                                        else
                                            return <p key={index} className={`w-[${header.space}] text-center text-3xl py-10`}>{data}</p>
                                    })
                                }
                            </div>
                            <hr className='w-[95%]' />
                        </div>
                    })
                    }
                    {
                        (!tableData || tableData?.length === 0) && <p className='text-center py-6'>No data Found</p>
                    }
            </div>
        </div>

        <div className='w-[100%] flex justify-between my-10'>
            <div className='w-[25rem]'>
                <SubmitButton value='Previous' btnWidth='full' disabled={previousDisabled} onClick={() => previousPage && previousPage()} />
            </div>
            <div className='w-[25rem]'>
                <SubmitButton value='Next' btnWidth='full' disabled={nextDisabled} onClick={() => nextPage && nextPage()} />
            </div>
        </div>
  </div>
  )
}

export default SortingTable;