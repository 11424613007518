import React, { useRef, useState } from 'react'

import { Header } from '../../layouts/header'
import Sidebar from '../../layouts/sidebar'
import sideBarData from './components/sidebarData'
import Navbar from '../../layouts/navbar'
// import FinanceForm from './components/financeForm'
// import FinanceDetails from './components/financeDetails'
import useSidebar from '../../hooks/useSidebar'

import navData from './utils/navData'

import UnderConstruction from '../../components/UnderConstruction'

function Index() {
    const { isSidebarOpen, toggleSidebar } = useSidebar();

    //Form
    const [ isFormOpen, setIsFormOpen ] = useState(false);
    const [ month, setMonth ] = useState('');
    const [ clientId, setClientId ] = useState('');
    const inVoiceNum = month === 'January' ? 'KSJ87356TRH9800' : 
        month === 'February' ? 'KSJ87356TRH9801' :
        month === 'March' ? 'KSJ87356TRH9802' :
        month === 'April' ? 'KSJ87356TRH9803' :
        month === 'May' ? 'KSJ87356TRH9804' :
        month === 'June' ? 'KSJ87356TRH9805' : 'KSJ87356TRH9806';

    return (
        <div className='layout-wrapper '>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>
            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sideBarData} />
                </div>
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className='px-[30px] pb-[30px]'>
                        <div className='h-10 flex items-center'>
                            <Navbar navData={navData} />
                        </div>
                        <div className='h-[80%] flex flex-col'>
                            <UnderConstruction />
                        </div>
                        {/* <div className='mt-10 mb-10'>
                            <FinanceForm setIsFormOpen={setIsFormOpen} setMonth={setMonth} month={month} setClientId={setClientId} clientId={clientId} isFormOpen={isFormOpen} inVoiceNum={inVoiceNum} />
                        </div>
                        {
                            isFormOpen && <>
                                <div className='text-generateQRheading mt-20 flex justify-start items-center'>
                                    <h5 className='text-font24 font-bold'>Details</h5>
                                </div>
                                <div className='mt-5 '>
                                    <FinanceDetails month={month} clientId={clientId} inVoiceNum={inVoiceNum} />
                                </div>
                            </>
                        } */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index