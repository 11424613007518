export const COMPANY_NAME = "KSJ RUDH PVT LTD"
export const SUB_CLIENT_ROLES = {
    ADMIN: 51276,
    USER: 93741,
    CUSTOMER: 24680,
    DASHBOARD: 68539,
}

export const URL_TYPES = {
    BASE: 'BASE',
    QR: 'QR'
}

export const SORT_ORDER = {
    ASC:"asc",
    DESC:"desc"
}

export const USER_TYPES = {
    ADMIN: "admin",
    SUB_CLIENT: "sub_client",
    CUSTOMER: "customer",
    MASTERCLIENT:"masterclient"
}