import { handleError } from "../../../../utils/handles/errorHandle";

async function getAllSubClientsPaginated(masterClientId, pageNo, pageSize, axiosPrivate){
    try{
        const response = await axiosPrivate.get(`/api/client/${masterClientId}/subclients/paginated`, { params: {pageNo, pageSize} });
        return response?.data;
    }catch(error){  
        handleError(error);
    }
}

async function createSubClient(subClientEmail, subClientName, axiosPrivate){
    try{
        const response = await axiosPrivate.post("/api/client/create-sub-client",{
            'userName': subClientName,
            'emailId': subClientEmail,
        });
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

export {
    getAllSubClientsPaginated,
    createSubClient
}