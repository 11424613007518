import React from 'react'

function Missing() {
    return (
        <>
            <h1>404</h1>
        </>
    )
}

export default Missing