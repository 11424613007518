import { handleError } from '../../../../utils/handles/errorHandle';

const getGenerationDates = async (values,axiosPrivate) => {
    const { clientId, batchNo, option } = values;

    try {
        const res = await axiosPrivate.post("/api/qr/get-generation-dates", { clientId: clientId, batchNo: batchNo, option: option }, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        });
        return res.data.data;
    } catch (error) {
        handleError(error);
    }
}

const downloadQrs = async(values,axiosPrivate) => {
    console.log(values, "values");
    const {clientId, batchNo, type, dateIndex} = values;
    try {
        const res = await axiosPrivate.post("/api/qr/download-data", { 
            clientId: clientId, 
            batchNo: batchNo,
            qrRequired: type,
            dateIndex: dateIndex,
        },
        {
            headers: {"Content-Type": "application/json"},
            withCredentials: true,
        });

        const downloadUrl = res.data.signedUrl;
        return downloadUrl
    } catch (error) {
        handleError(error);
    }
}

export {
    getGenerationDates,
    downloadQrs
}