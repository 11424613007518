import React from 'react'
import { useNavigate } from 'react-router-dom'
import { HeaderPlain } from '../../../layouts/header'
import loginImage from '../../../assets/images/login_image.svg'
import LoginForm from './components/loginForm'
function Index() {
    const navigate = useNavigate();

    return (
        <div className='flex flex-col h-[100vh]'>
            <div>
                <HeaderPlain />
            </div>
            <div className='w-full pb-[20px] grid grid-cols-1 md:grid-cols-2 gap-4 px-[30px] lg:px-[0]'>
                <div className='md:h-[83vh] gap-12 flex flex-col justify-center items-center md:order-1 order-2 mt-[50px] md:mt-[0px]'>
                    <img className='md:w-[50%] md:h-[70%] md:max-w-[100%] hidden md:flex' src={loginImage} />
                    <div className='md:mt-0 mt-5 md:h-[20%] flex flex-col justify-around gap-6'>
                        <span className='text-popupText text-[30px] font-semibold md:leading-[50px] leading-[40px] text-center'>Counter the <span className='text-loginBlue font-bold'>Counterfeits</span></span>
                        <span className='text-popupText text-[30px] font-semibold md:leading-[50px] leading-[40px] text-center'>Login Now!!!</span>
                        <div className='w-full flex justify-between items-center gap-4'>
                            <button className='w-[50%] h-[45px] text-[13px] border border-loginBorder rounded-[5px]'>Know More</button>
                            <button className='w-[50%] h-[45px] text-[13px] border border-loginBorder rounded-[5px]'>Watch Video</button>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col justify-around items-center h-full md:order-2 order-1 mt-[5rem] md:mt-0'>
                    <div className='md:w-[60%] w-full h-full flex items-center'>
                        <LoginForm/>
                    </div>

                    {/* <div className='md:w-[60%]'>
                        <p className='md:w-[80%]  flex justify-center text-2xl font-medium'>
                            Don't have an account? 
                            <button 
                                onClick={() => {
                                    navigate('/signup');
                                }}
                                className='ml-2 text-blue-700'
                            >
                                Create your Account
                            </button>
                        </p>
                    </div> */}
                </div>
                
            </div>
        </div>
    )
}

export default Index
