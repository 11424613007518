import React, { useState } from 'react';
import { Formik, Form } from 'formik';

import FormikControl from '../../../../components/form/formikControl';
import { SubmitButton } from '../../../../components/ui/buttons/submitButton';
import Loader from "../../../../components/ui/Loader";
import TextPopup from '../../../../components/popups/textPopup';
import useAuth from '../../../../hooks/useAuth';

import {
    initialValues_form as initialValues
} from '../utils/initialValues';

import {
    validationSchema_form as validationSchema
} from '../validations/validations';

import { FetchBatches } from '../services/api';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { URL_TYPES } from '../../../../config/constants';

function BatchForm(props) {

    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR);
    const [loading, setLoading] = useState(false);

    const [showTextPopup, setShowTextPopup] = useState(false)
    const [alertText, setAlertText] = useState("");
    const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);
    const blurredBackgroundStyles = isBackgroundBlurred
        ? {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(100, 100, 100, 0.5)",
            backdropFilter: "blur(1.8px)",
            zIndex: 1,
        }
        : {};

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            const res = await FetchBatches({...values, clientId: auth.masterClientId},axiosPrivate);
            
            const data = res.data;
            const totalPages = res.totalPages;
            const currentPage = res.currentPage;
            if (data?.length === 0)  throw new Error('No data Found');

            props.setProperties.setDataFromApi(data);
            props.setProperties.setTotalPages(totalPages)
            props.setProperties.setCurrentPage(currentPage)
            props.setProperties.setFormValues({
                batchNo: values.batchNo,
                toDate: values.toDate,
                fromDate: values.fromDate,
            })
        } catch (error) {
            setAlertText(error.message || 'Unable to load data');
            setShowTextPopup(true);
            setIsBackgroundBlurred(true);
        }  finally {
            setLoading(false);
        }
    }

    const validateForm = (values) => {
        const { batchNo, fromDate } = values;
        if (!batchNo && !fromDate) {
            return {
                fromDate: 'At least one field is required',
                batchNo: 'At least one field is required'
            };
        }
        return {};
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validate={validateForm}
            onSubmit={handleSubmit}
        >
            {
                formik => (
                    <Form>
                        {isBackgroundBlurred && <div style={blurredBackgroundStyles} />}
                        {showTextPopup && (
                            <TextPopup
                                heading="Alert"
                                description={alertText}
                                onClose={() => {
                                    setShowTextPopup(false);
                                    setIsBackgroundBlurred(false);
                                }}
                            />
                        )}
                        {
                            loading && <Loader />
                        }
                        <div className=' w-[100%]'>
                            {/* <div className='h-14 bg-BorderLight opacity-10'>

                            </div> */}
                            <div className='bg-bgWhite flex flex-col p-10 h-[100%] border border-navBorderLight rounded-[12px]'>
                                <div className='w-[50%] h-[33%] mb-5'>
                                    <FormikControl control='input' label='Batch Number :' name='batchNo' labelColor='popupText' bgcolor={'selectorBg'} onInputChange={() => {formik.setFieldValue('fromDate', ''); formik.setFieldValue('toDate', '');}} />
                                </div>
                                <div className='w-[37%] flex justify-end items-center h-20'>
                                    <span className='text-couponsRadioHeading text-font16 font-medium'>OR</span>
                                </div>
                                <div className='flex items-center w-[100%] gap-20'>
                                    <div className='w-[20%] text-sideBarBtnText font-medium flex items-center -mt-6'>
                                        <h5>Creation Period</h5>
                                    </div>
                                    
                                    <div className='w-[90%] md:w-[60%] -ml-10 md:ml-0 flex justify-center items-center'>
                                        <h6 className='w-[10%] text-couponsRadioHeading text-3xl font-medium text-left -mt-6'>from</h6>
                                        <div className='w-[40%] '>
                                            <FormikControl control='date' name={'fromDate'} label={''} fieldWidth={'full'} bgcolor={'selectorBg'} onChange={() => formik.setFieldValue('batchNo', '')} />
                                        </div>
                                        <div className='w-[10%]'></div>
                                        <h6 className='w-[10%] text-couponsRadioHeading text-3xl font-medium text-left -mt-6'>to</h6>
                                        <div className='w-[40%]'>
                                            <FormikControl control='date' name={'toDate'} label={''} fieldWidth={'full'} bgcolor={'selectorBg'} onChange={() => formik.setFieldValue('batchNo', '')} />
                                        </div>
                                    </div>

                                    <div className='w-[20%] flex justify-end items-center -mt-6'>
                                        <SubmitButton value={'Search'} imageUrl={''} btnWidth='[100%]' height={'16'} type="submit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )
            }
        </Formik>
    )
}

export default BatchForm