import CreateManuSku from "./components/manuSku";
import CreateRetaSku from "./components/retaSku";

function EditSku({clientId, batchType}) {

    if(batchType === 'Manufacturer')
        return <CreateManuSku clientId={clientId} batchType={batchType}/>
    else if(batchType === 'Retailer')
        return <CreateRetaSku clientId={clientId} batchType = {batchType}/>
    else
        return (
            <div>
                Please select a valid Option
            </div>
        )
}

export default EditSku;