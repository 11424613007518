import React from 'react' 
import { Route, Routes, Navigate } from "react-router-dom";

import Layout from "../layouts/layout"
import RequireAuth from "../auth/requireAuth";
import {SUB_CLIENT_ROLES} from "../config/constants"

//Pages
//Open
import Login from '../features/authentication/login'
import ForgotPassword from '../features/authentication/forgotPassword'

//EveryOne loggedIn can access
import Home from '../features/homePage'
import ChnageAvater from '../features/changeAvatar';
import ResetPassword from '../features/authentication/RestPassword'

//Dashboard
import Dashboard from '../features/dashboard/dashboard';
import DataSummary from '../features/dashboard/dataSummary';

//Admin
import ViewProfile from '../features/admin/viewProfile/index';
import AddUser from '../features/admin/addUsers'
import DeleteUser from '../features/admin/deleteUser'
import AccessManagement from '../features/admin/accessManagement'
import Logs from '../features/admin/logs';

//CUSTOMER
import CustomerProfile from '../features/customer/customerProfile';
import PurchasesAndWarranty from '../features/customer/purchasesAndWarranty';
import ProductDetails from '../features/customer/productDetails';
import Rewards from '../features/customer/rewards';
import WarrantyTC from '../features/customer/warrantyTC';

//USER
import GenerateQRCode from '../features/user/generateQRCode'
import GenerateDownloadQRCode from "../features/user/GenerateAnddownloadQRCodes"
import DownloadQrCodes from '../features/user/downloadQRCode'
import Sku from '../features/user/sku'
import BatchActions from '../features/user/batchActions'
import BatchAppend from '../features/user/batchActions/batchAppend'
import BatchEdit from '../features/user/batchActions/batchEdit'
//Coupons

//Finance
import Finance from '../features/finance/index';

//Open - Something wrong
import MissingPage from "../components/404Page";
import UnAuthorized from "../components/unauthorized";

//Login manager
import PersistLogin from "../features/authentication/login/components/persistentLogin";

function AppRoutes() {
  return (
    <div>
        <Routes>
          <Route path="/" element={<Layout/>}>

            {/* Public Routes */}
            <Route path='/login' element={<Login/>} />
            <Route path="/unauthorized" element={<UnAuthorized/>}/>
            <Route path="/forgot-password" element={<ForgotPassword/>}/>

            {/* Protected Routes */}
            <Route element={<PersistLogin/>}>
              {/* HOME */}
              <Route element={<RequireAuth allowedRoles={Object.keys(SUB_CLIENT_ROLES).map(key => { return SUB_CLIENT_ROLES[`${key}`]})}/>}>
                <Route path="/" element={<Navigate to='/home' />} />
                <Route path='/home' element={<Home/>} />
                <Route path="/change-avatar" element={<ChnageAvater/>}/>
                <Route path='/reset-password' element={<ResetPassword/>} />
              </Route>

              {/* DASHBOARD */}
              <Route element={<RequireAuth allowedRoles={[SUB_CLIENT_ROLES.ADMIN]}/>}>
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/data-summary' element={<DataSummary />} />
              </Route>

              {/* ADMIN */}
              <Route element={<RequireAuth allowedRoles={[SUB_CLIENT_ROLES.ADMIN]}/>}>
                <Route path='/add-user' element={<AddUser/>} />
                <Route path='/view-profile' element={<ViewProfile />} />
                <Route path='/delete-user' element={<DeleteUser/>} />
                <Route path="/access-management" element={<AccessManagement/>} />
                <Route path="/logs" element={<Logs />} />
              </Route>

              {/* CUSTOMER */}
              <Route element={<RequireAuth allowedRoles={[SUB_CLIENT_ROLES.ADMIN, SUB_CLIENT_ROLES.CUSTOMER]}/>}>
                <Route path='/view-customer' element={<CustomerProfile />} />
                <Route path="/purchases-and-warranty/:customerId" element={<PurchasesAndWarranty />} />
                <Route path="/product-details" element={<ProductDetails />} />
                <Route path="/rewards/:customerId" element={<Rewards />} />
                <Route path="/warranty-terms-and-conditions" element={<WarrantyTC />} />
              </Route>

              {/* USER */}
              <Route element={<RequireAuth allowedRoles={[SUB_CLIENT_ROLES.ADMIN,SUB_CLIENT_ROLES.USER]}/>}>
                <Route path="/new-batch-details" element={<GenerateQRCode/>} />
                <Route path='/download-qr-code' element={<DownloadQrCodes/>}/>
                <Route path="/generated-qr-codes" element={<GenerateDownloadQRCode/>}/>
                <Route path='/batch-actions' element={<BatchActions/>} />
                <Route path='/batch-actions/append/:batchno' element={<BatchAppend/>} />
                <Route path='/batch-actions/edit/:batchno' element={<BatchEdit/>} />
                <Route path='/sku' element={<Sku/>} />
                {/* <Route path='/coupons' element={<UnderConstruction/>} /> */}
              </Route>

              {/* Finance */}
              <Route element={<RequireAuth allowedRoles={[SUB_CLIENT_ROLES.ADMIN]}/>}>
                <Route path='/finance' element={<Finance />} />
              </Route>
            </Route>

            {/* Catch All */}
            <Route path="*" element={<MissingPage />} />
          </Route>
        </Routes>
    </div>
  )
}

export default AppRoutes