import React from 'react'

function BlueButton(props) {
    const {label,width,height,...rest} = props
    return (
    <div className='w-[100%]'>
        <button type={props.type} onClick={props.onClick} className={`bg-formBlueHeading w-${width} h-${height} text-popupScreen text-3xl rounded-[5px]`}>
            {label}
        </button>
    </div>
    )
}

export default BlueButton