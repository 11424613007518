import homeImage from '../../../assets/images/sidebar_icons/home_icon.svg'
import avtarImage from '../../../assets/svg/home_icons/avtar_icon.svg'
import resetPassword from '../../../assets/svg/home_icons/reset_password.svg'

const sideBarData = [
    {
        name:"home",
        img:homeImage,
        desc:"Home"
    },
    {
        name:"reset-password",
        img:resetPassword,
        desc:"Reset Password"
    },
    {
        name:"change-avatar",
        img:avtarImage,
        desc:"Change avatar"
    },
]

export default sideBarData