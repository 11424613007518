import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { useParams } from 'react-router-dom'

import {Header} from '../../../layouts/header'
import Navbar from '../../../layouts/navbar'
import Sidebar from '../../../layouts/sidebar'
import sideBarData from '../utils/sidebarData'
import { SubmitButton } from '../../../components/ui/buttons/submitButton'
import FormikControl from '../../../components/form/formikControl'
import SearchIcon from '../../../assets/svg/form_images/search_icon.svg'
import DownloadQRTable from './components/downloadQRTable'
import Loader from "../../../components/ui/Loader/Loader"
import TextPopup from '../../../components/popups/textPopup'

import validationSchema from './validations/validationSchema';
import initialValues from './utils/initialValues'

import { getGenerationDates } from "./services/api";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'

import useSidebar from '../../../hooks/useSidebar'
import navData from './utils/navData'
import { URL_TYPES } from '../../../config/constants'

function Index() {

    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR);
    const { isSidebarOpen, toggleSidebar } = useSidebar();
    const {auth} = useAuth();

    const [dataFromApi, setDataFromApi] = useState([]);
    const [loading, setLoading] = useState(false);
    const [batchNo, setBatchNo] = useState("");
    const clientid = auth.masterClientId;

    const [showTextPopup, setShowTextPopup] = useState(false)
    const [alertText, setAlertText] = useState("");
    const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);

    const blurredBackgroundStyles = isBackgroundBlurred
        ? {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(100, 100, 100, 0.5)",
            backdropFilter: "blur(1.8px)",
            zIndex: 1,
        }
        : {};

    const handleSubmit = async (values) => {
        setBatchNo(values.batchNo);
        setDataFromApi([]);
        const dataObj = {
            batchNo: values.batchNo,
            clientId: clientid
        }

        try {
            setLoading(true);
            const data = await getGenerationDates(dataObj,axiosPrivate);
            
            const QRDetails = [], trackingQrDetails_tert = [], trackingQrDetails_quat = [];

            data.QRDetails.map((timestamp) => {
                QRDetails.push({
                    index: timestamp.index,
                    type: 1,
                    date: new Date(timestamp.time).toLocaleDateString(),
                    time: new Date(timestamp.time).toLocaleTimeString()
                })
                return ''
            });

            data.trackingQrDetails_tert.map((timestamp) => {
                trackingQrDetails_tert.push({
                    index: timestamp.index,
                    type: 2,
                    date: new Date(timestamp.time).toLocaleDateString(),
                    time: new Date(timestamp.time).toLocaleTimeString()
                })
                return ''
            });

            data.trackingQrDetails_quat.map((timestamp) => {
                trackingQrDetails_quat.push({
                    index: timestamp.index,
                    type: 3,
                    date: new Date(timestamp.time).toLocaleDateString(),
                    time: new Date(timestamp.time).toLocaleTimeString()
                })
                return ''
            });

            setDataFromApi({ QRDetails, trackingQrDetails_tert, trackingQrDetails_quat });
        } catch (error) {
            console.log(error, "ash")
            setAlertText(error.message);
            setShowTextPopup(true);
            setIsBackgroundBlurred(true);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
        {isBackgroundBlurred && <div style={blurredBackgroundStyles} />}
        <div className='layout-wrapper'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>

            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sideBarData} />
                </div>
                
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className='px-[20px]'>
                        <div className='h-10 flex items-center'>
                            <Navbar navData={navData} />
                        </div>
                    <div className='ml-10 mr-10 flex  items-center '>
                        <div className='w-[40%]'>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {
                                    formik => {
                                        return (
                                            <Form>
                                                    <div>
                                                        <FormikControl control='input' type='text' label={"Batch No :"} name={'batchNo'} bgcolor='bgWhite' placeholderIcon={SearchIcon} />
                                                    </div>
                                                    <div className='w-full mt-10 flex justify-end mx-auto' >
                                                        <div className="w-full md:w-[55%]">
                                                            <SubmitButton value='Search' type="submit" btnWidth='full' />
                                                        </div>
                                                    </div>
                                                </Form>
                                        )
                                    }
                                }
                            </Formik>
                        </div>
                    </div>
                    <div className='ml-5 mr-5 mt-10 flex-1 mb-10'>
                        {showTextPopup && (
                            <TextPopup
                                heading="Alert"
                                description={alertText}
                                onClose={() => {
                                    setShowTextPopup(false);
                                    setIsBackgroundBlurred(false);
                                }}
                            />
                        )}
                        {
                            loading && <Loader />
                        }
                        {
                            dataFromApi.length !== 0 &&
                            <DownloadQRTable
                                ProductQRs={dataFromApi.QRDetails}
                                TrackingQrType1={dataFromApi.trackingQrDetails_tert}
                                TrackingQrType2={dataFromApi.trackingQrDetails_quat}
                                clientId={clientid}
                                batchNo={batchNo}
                            />
                        }
                    </div>
                </div>
            </div>
            </div>
        </div>
        </>
    )
}

export default Index