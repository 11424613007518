import {createContext, useState} from "react"

const UserDataContext = createContext();

export const UserDataProvider = ({children}) => {
    const [user,setUser] = useState(null);

    return (
        <UserDataContext.Provider value={{ user,setUser }}>
            {children}
        </UserDataContext.Provider>
    )
};

export default UserDataContext;