const initialValues_form = {
    'batchNo':'',
    'fromDate':'',
    'toDate':''
}

const initialValues_update = {
    'type':'',
    'batchNo':'',
    'clientId':'',
    'Pimage': '',
    'QROnprod': '',
    'brand': '',
    'prodName': '',
    'mrp': '',
    'serialNo': '',
    'shelfLife': '',
    'expiryDate': '',
    'warrantyApp': '',
    'warrantyPeriod': '',
    'manuLicenseNo': '',
    'manuAdd': '',
    'addDetails': '',
    'additionalImageDetails': '',
    'extraImage1': '',
    'extraImage2': '',
    'manuWebsiteLink': ''
}

export {
    initialValues_form,
    initialValues_update
}