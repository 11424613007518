import { handleError } from "../../../../utils/handles/errorHandle";

async function getAllSubClientsPaginated(masterClientId,sortBy,sortOrder,pageNo,pageSize,axiosPrivate){
    try{
        const response = await axiosPrivate.get(`/api/client/${masterClientId}/subClients/paginated`, { params:{ pageNo, pageSize, sortBy, sortOrder }});
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function getUserInfo(queryString,sortBy,sortOrder,pageNo,pageSize,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/client/sub-client/user-info", { params:{ queryString, sortBy, sortOrder, pageNo, pageSize }});
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function getUserIdSuggestion(queryString,userType,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/client/sub-client/userid-suggestions",{ params:{ queryString, userType } });
        return response?.data;
    }catch(error){  
        handleError(error);
    }
}

export {
    getUserInfo,
    getAllSubClientsPaginated,
    getUserIdSuggestion
}