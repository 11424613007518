import React, { useState, useRef, useEffect } from 'react';
import useAuth from '../../../../hooks/useAuth';

const Dashboard = () => {

    const [height, setHeight] = useState();
    const divRef = useRef(null);
    useEffect(() => {
        if(!divRef.current) return;

        console.log(divRef.current.clientWidth, "width")
        setHeight(((divRef.current.clientWidth*9) / 16) + 50);
    }, [divRef])

    const { auth } = useAuth();
    const clientId = auth.masterClientId;

    return (
        <div ref={divRef} className='w-full' style={{ height: height }} >
            <iframe className='w-full h-full rounded-[12px] p-5 bg-white' src={`https://app.powerbi.com/reportEmbed?reportId=fcf52546-8a63-4660-93d1-b8283dc9e613&autoAuth=true&ctid=7da38a6f-e425-4b8e-88b3-28a78290ffe5&filter=ANALYTICS_V/ClientId eq '${clientId}'`} frameborder="0"></iframe>
        </div>
    );
}

export default Dashboard;