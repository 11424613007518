import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as YUP from 'yup';
import { motion, AnimatePresence, MotionConfig } from 'framer-motion';

import FormikControl from '../../../../components/form/formikControl';
import { SubmitButtonWide } from '../../../../components/ui/buttons/submitButton';
import Loader from '../../../../components/ui/Loader/Loader';
import PopUpManager from '../../../../components/popups2/popupManager';

import Otp from '../../../../components/form2/Otp';

import { forgotPasswordRequestOtp, forgotPasswordVerification } from '../services/api';

const EmailVerification = ({ setIsEmailVerified }) => {

    const [isOtpSent, setIsOtpSent] = useState(false);
    const [mail, setMail] = useState('');
    const [otp, setOtp] = useState('');

    const [loading, setLoading] = useState(false);
    const [isTextPopupDisabled, setIsTextPopupDisabled] = useState(true);
    const [textPopupText, setTextPopupText] = useState('');

    const initialValues = {
        email: ''
    }

    const validationSchema = YUP.object({
        email: YUP.string().email().required('This field is required'),
    }) 

    const handleSendOtp = async (values) => {
        setMail(values.email);
        try {
            setLoading(true);
            await forgotPasswordRequestOtp(
                false, //isMobile
                values.email
            );

            setIsOtpSent(true);
        } catch (error) {
            setTextPopupText(error.message || 'Unable to send Otp');
            setIsTextPopupDisabled(false)
        } finally {
            setLoading(false);
        }
    }

    const handleVerify = async () => {
        try {
            await forgotPasswordVerification(
                false,
                mail,
                otp
            );

            setIsEmailVerified(true);
        } catch (error) {
            setTextPopupText(error.message || 'Unable to verify OTP');
            setIsTextPopupDisabled(false)
        }
    }

    return (
        <>
            {loading && <Loader />}
            <PopUpManager 
                control={'text'}
                disable={isTextPopupDisabled}
                heading={'Alert'}
                description={textPopupText}
                onClose={() => {
                    setIsTextPopupDisabled(true)
                }}
            />
            <div className='flex flex-col md:h-[83vh] gap-10 items-center md:items-start justify-between'>
                <div className='w-[100%] pb-30 flex flex-col justify-start items-center'>
                    <h3 className='text-[30px] sm:text-[40px] text-popupText font-bold text-center leading-none'>
                        Forgot password?
                    </h3>
                    <span className='text-popupText opacity-50 text-[14px] flex justify-center mt-3 text-center cursor-pointer'>
                        Your new password must be different from the previous used password.
                    </span>
                </div>
                <div className='flex-1 flex justify-center items-center md:w-[80%] w-full mx-auto relative'>
                    <MotionConfig
                        transition={{
                            duration: 0.3,
                            type: 'tween',
                            ease: 'easeInOut'
                        }}
                    >
                        <AnimatePresence>
                        {
                            !isOtpSent && <motion.div
                                className='w-full absolute'
                                initial={{
                                    x: '100%',
                                    opacity: 0
                                }}
                                animate={{
                                    x: 0,
                                    opacity: 1
                                }}
                                exit={{
                                    opacity: 0
                                }}
                            >
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSendOtp}
                                >
                                    {(formik) => {
                                        return (
                                            <Form className='w-full'>
                                                <div className='flex-1 w-[100%]'>
                                                    <div className='w-[100%] mb-[20px]'>
                                                        <h6 className='text-popupText text-[13px] font-medium mb-[9px]'>
                                                            Email
                                                        </h6>
                                                        <FormikControl
                                                            control='input'
                                                            label=''
                                                            name={'email'}
                                                            width='full'
                                                            bgcolor='selectorBg'
                                                        />
                                                    </div>
                                                    <div className='w-[100%]'>
                                                        <SubmitButtonWide 
                                                            value='Next'
                                                        />
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </motion.div>
                        }
                        </AnimatePresence>
                        <AnimatePresence>
                        {
                            isOtpSent && <motion.div 
                                className='w-full'
                                initial={{
                                    x: '100%',
                                    opacity: 0
                                }}
                                animate={{
                                    x: 0,
                                    opacity: 1
                                }}
                                exit={{
                                    opacity: 0
                                }}
                            >
                                <div className='w-[100%] flex flex-col mb-[20px]'>
                                    <h6 className='text-popupText text-[13px] font-medium mb-[15px] text-center'>
                                        If your email is registered with us, you will receive an email with an OTP to reset your password.
                                    </h6>
                                    <div className='w-[80%] mx-auto'>
                                        <Otp 
                                            widthClass='w-full'
                                            marginClass='text-4xl'
                                            onChnage={setOtp}
                                        />
                                    </div>
                                </div>
                                <div className='w-[100%]'>
                                    <SubmitButtonWide 
                                        value='Verify'
                                        onClick={handleVerify}
                                    />
                                </div>
                            </motion.div>
                        }
                        </AnimatePresence>
                    </MotionConfig>
                </div>
            </div>
        </>
    );
}

export default EmailVerification;
