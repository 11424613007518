function numberToMonthName(monthNumber) {
    // Array of month names
    const monthNames = [
      "January", "February", "March", "April",
      "May", "June", "July", "August",
      "September", "October", "November", "December"
    ];
  
    // Check if the input is a valid month number
    if (monthNumber >= 1 && monthNumber <= 12) {
      // Subtract 1 to match array index
      return monthNames[monthNumber - 1];
    } else {
      // Handle invalid input
      return "Invalid month number";
    }
  }
  
export default numberToMonthName;
  