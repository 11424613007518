import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import FormikControl from '../../../../components/form/formikControl'
import { SubmitButtonWide } from '../../../../components/ui/buttons/submitButton'

import validationSchema from '../validations/loginValidations'
//importing necessary hooks
import useAuth from '../../../../hooks/useAuth'
//importing API services
import {login} from "../services/api"

import TextPopup from '../../../../components/popups/textPopup';

import Loader from "../../../../components/ui/Loader/Loader";
function LoginForm() {

    const navigate = useNavigate();
    const {setAuth} = useAuth();

    const [loading, setLoading] = useState(false);
    const [showTextPopup, setShowTextPopup] = useState(false);
    const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);
    const [alertText, setAlertText] = useState("");
    const blurredBackgroundStyles = isBackgroundBlurred
        ? {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(100, 100, 100, 0.5)",
            backdropFilter: "blur(1.8px)",
            zIndex: 1,
        }
        : {};
        const intialValues = {
            "login": '',
            "password": ''
        }
    
    const handleSubmit = async(values) => {
        try{
            setLoading(true);
            const response = await login(values.login,values.password);
            console.log(response, "data from login");
            
            const accessToken = response?.accessToken;
            const roles = response?.roles;
            const userId = response?.userId;
            const masterClientId = response?.masterClientId;
            const clientName = response?.organizationName;
            const masterClientObjectId = response?.masterClientObjectId;

            setAuth({
                userId,
                clientName,
                accessToken,
                roles,
                masterClientId,
                masterClientObjectId
            })
            navigate("/home")
        }catch(error){
            setLoading(false)
            setIsBackgroundBlurred(true);
            setAlertText(error.message)
            setShowTextPopup(true)
        }
    }

    const handleForgotPass = () => {
        navigate('/forgot-password')
    }
    return (
        <div className='w-[100%]'>
            {/* loader */}
            {loading && <Loader />}
            {isBackgroundBlurred && <div style={blurredBackgroundStyles} />}
            <Formik
                initialValues={intialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={true}
            >
                {
                    formik => {
                        return (
                            <Form>
                                <div className='flex flex-col gap-10 items-center md:items-start'>
                                    <div className='w-full md:h-[25%] text-center md:text-left' >
                                        <h3 className='text-[#000] text-[30px] sm:text-[40px] font-bold'>Login</h3>
                                    </div>
                                    <div className='flex flex-col md:h-[75%] items-center md:items-start w-full'>
                                        <div className='w-[80%] !md:w-[100%] lg:w-[80%] flex flex-col pb-6 mb-[18px]'>
                                            <h6 className='text-popupText font-medium mb-[9px] text-[13px]'>Email / User ID</h6>
                                            <FormikControl bgcolor={'selectorBg'} control='input' type='text' label='' name='login'  width= 'full' placeholder=""/>
                                        </div>
                                        <div className='w-[80%] !md:w-[100%] lg:w-[80%] pb-[35px]'>
                                            <h6 className='text-popupText font-medium mb-[9px] text-[13px]'>Password</h6>
                                            <FormikControl bgcolor={'selectorBg'} control='input' type='password' label='' name='password' width='full' placeholder=""/>
                                            <span className='text-forgotPassword text-[13px] flex justify-end items-center leading-[50px]'><span className='cursor-pointer' onClick={handleForgotPass}>Forgot Password?</span></span>
                                        </div>
                                        <div className='w-[80%] !md:w-[100%] lg:w-[80%] md:h-[40%] '>
                                            <SubmitButtonWide value='Get Started' type='submit' />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                }
            </Formik>
            {showTextPopup && (
                <TextPopup
                    heading="Alert"
                    description={alertText}
                    onClose={() => {
                        setShowTextPopup(false);
                        setIsBackgroundBlurred(false);
                    }}
                />
            )}
        </div>
    )
}

export default LoginForm