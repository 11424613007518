import React from 'react'
import TextInput from './textInput'
import { InputTextBox, InputTextBoxSmallHeight } from './inputTextBox'
import RadioButton from './radioButton'
import Selector from './selector'
import DatePicker from './datePicker'
import DatePickerType2 from './datePickerType2'
import ImageUpload from './imageUpload'
import Checkbox from './checkBox'
import VideoUpload from './videoUpload'
import FileUpload from './fileUpload'
import ColorInput from './colorInput'

function formikController(props) {
    const {control , ...rest} = props
    switch(control)
    {
        case 'input': return <TextInput {...rest}/>
        case 'input-textarea-small': return <InputTextBoxSmallHeight {...rest}/>
        case 'input-textarea-large': return <InputTextBox {...rest}/>
        case 'radio': return <RadioButton {...rest}/>
        case 'select': return <Selector {...rest}/>
        case 'date': return <DatePicker {...rest}/>
        case 'datepicker2': return <DatePickerType2 {...rest}/>
        case 'file': return <ImageUpload {...rest}/>
        case 'video': return <VideoUpload {...rest}/>
        case 'checkbox': return <Checkbox {...rest}/>
        case 'upload': return <FileUpload {...rest} />
        case 'color': return <ColorInput {...rest} />

        default: return null
    }
}

export default formikController