import React, { useRef, useState } from 'react';
import { Field } from 'formik'

import removedExifFile from '../../utils/files/exifRemover';

const FileUpload = (props) => {

    const { name, acceptableFiles = '.png, .jpg, .jpeg, .pdf', onChnage, formik, fileSizeLimit = 5, ...rest} = props;
    const [fileName, setFileName] = useState('No File Chosen');
    const inpRef = useRef(null);

    const handleFileChange =(event) => {
        let file = event.target.files[0];

        const MAX_FILE_SIZE = fileSizeLimit * 1024 * 1024;
        if(file.size > MAX_FILE_SIZE) {
            alert(`File size should be less than ${fileSizeLimit}MB`);
            return;
        }

        setFileName(file.name);
        file = new File([file], `${Math.floor(Math.random()*1000000)}.${file.type.split('/')[1]}`, { type: file.type });
        
        if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
            removedExifFile(file).then((modifiedFile) => {
                onChnage && onChnage(modifiedFile);
            });
        } else {
            onChnage && onChnage(file);
        }
    }

    return (
        <div className='w-full h-full min-h-[46px] flex items-center bg-[#F1F2FA] rounded-xl'>
            <Field
                id={name}
                name={name}
                type='file'
                className="hidden"
                accept={acceptableFiles}
                onChange={handleFileChange}
                innerRef={inpRef}
                {...rest}
            />

            <button
                type='button'
                className='border bg-[#ECECFF] h-[60%] w-[15rem] mx-5 text-xl font-medium'
                onClick={() => {
                    console.log("Hii")
                    inpRef.current  && inpRef.current.click();
                }}
            >
                Choose File
            </button>
            <p className='flex-1 text-xl'>{fileName}</p>
        </div>
    );
}

export default FileUpload;
