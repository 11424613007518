import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './textError'

function radioButton(props) {
  const { label, name, options, textcolor, width='14', height='9', nextLineError, onChange, mdB = 0, ...rest } = props


  return (
    <div className={`flex items-center ${nextLineError && 'flex-col'} mb-${mdB}`}>
      <div className='flex items-center mr-4'>
        {label &&
          <label htmlFor={name} className={` ${textcolor ? `text-${textcolor}` : 'text-popupText'} font-normal text-font16 w-[45%]`}>
            {label}
          </label>
        }
        <div className='flex flex-row w-full pl-14 justify-flex-start items-center gap-5'>
          <Field name={name} {...rest}>
            {
              ({ field }) => {
                return options.map(option => {
                  return (
                    <React.Fragment key={option.key} >
                      <div className='flex items-center'>
                      <input 
                        className={`w-14 h-${height} mx-2`}
                        type='radio'
                        id={option.value} {...field}
                        value={option.value}
                        checked={field.value === option.value}
                        onClick={() => {
                            onChange && onChange(option.value)
                        }}
                        />
                      <label htmlFor={option.value} className='text-sideBarBtnText text-3xl w-[auto] '>{option.key}</label>  
                      </div>
                    </React.Fragment>
                  )
                })
              }
            }
          </Field>
        </div>
      </div>
      <div className='w-[100%]'>
        <div className={`${nextLineError && 'ml-[5rem] mt-2 -mb-10'}`}>
          <ErrorMessage name={name} component={TextError}/>
        </div>
      </div>
  </div>
  )
}

export default radioButton