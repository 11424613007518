import React from 'react'
import {Header} from '../../../layouts/header'
import Sidebar from '../../../layouts/sidebar'
import sideBarData from '../utils/sidebarData'
import Navbar from '../../../layouts/navbar'
import DeleteClient from './components/deleteClient'
import useSidebar from '../../../hooks/useSidebar'

import navData from './utils/navData'

import useUserData from '../../../hooks/useUserData'

function Index() {

    const { isSidebarOpen, toggleSidebar } = useSidebar();
    const { user } = useUserData();
    
    return (
        <div className='layout-wrapper'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>
            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sideBarData} />
                </div>
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className='px-[30px]'>
                        <div className='h-10  flex items-center '>
                            <Navbar navData={navData} />
                        </div>
                    
                        {user && <DeleteClient adminContactMail={user.emailId} />}
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Index