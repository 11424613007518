import qrCodeImage from '../../../assets/images/sidebar_icons/qr_code_icon.svg'
import downloadQrImage from '../../../assets/images/sidebar_icons/download_qr_icon.svg'
import batchActionsImage from '../../../assets/images/sidebar_icons/batch-actions_icon.svg'
import skuImage from '../../../assets/images/sidebar_icons/sku_icon.svg'

export default [
    {
        name:`new-batch-details`,
        img:qrCodeImage,
        desc:"Generate"
    },
    {
        name:`download-qr-code`,
        img:downloadQrImage,
        desc:"Download QR"
    },
    {
        name:`batch-actions`,
        img:batchActionsImage,
        desc:"Batch Actions"
    },
    {
        name:"sku",
        img:skuImage,
        desc:"SKU"
    }
];