import React from "react";
import { Formik, Form } from 'formik'
import FormikControl from '../../../../components/form/formikControl'

function ProfileForm({initialValues}) {
    return (
        <Formik
            initialValues={Object.keys(initialValues).length? initialValues : {}}
            enableReinitialize={true}
        >
            {
                formik => {
                    return (
                        <Form>
                            <div className="grid grid-cols-1 gap-7 profileForm">
                                <FormikControl disabled={true} placeholder={"EMail ID"} control='input' label={"Email Address:"} name={'emailID'} bgcolor='selectorBg' width={'full'} textcolor='financeSideBoxText' />
                                <FormikControl disabled={true} placeholder={"Mobile number"} control='input' label={"Contact No: "} name={'intelMobileNo'} bgcolor='selectorBg' width={'full'} textcolor='financeSideBoxText' />
                                <FormikControl disabled={true} placeholder={"Status"} control='input' label={"Status: "} name={'status'} bgcolor='selectorBg' width={'full'} textcolor='financeSideBoxText' />
                                <FormikControl disabled={true} placeholder={"Customer Name"} control='input' label={"Customer Name :"} name={'userName'} bgcolor='selectorBg' width={'full'} textcolor='financeSideBoxText' />
                                <FormikControl disabled={true} placeholder={"Date"} control='date' label={"Registration date :"} name={"createdAt"} bgcolor='selectorBg' width={'full'} textcolor='financeSideBoxText' />
                            </div>
                        </Form>
                    )
                }
            }
        </Formik>
    )
}

export default ProfileForm

