import React,{useState} from 'react'
import CloseIcon from '../../assets/svg/popups/popUpCloseIcon.svg'
function TextPopup(props) {
    const { heading, description,onClose } = props
    return (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-popupScreen flex flex-col w-[25%] z-10'>
            <div className='bg-bgWhite flex flex-col justify-center w-[100%] z-10'>
                <div className='flex justify-between items-center p-10 h-14 w-[100%] bg-gradient-to-r from-gradientPurple  to-gradientBlue to-90% '>
                    <span className='font-bold text-4xl text-popupScreen'>{heading}</span>
                    <img className='w-9 h-9 cursor-pointer' src={CloseIcon} alt='' onClick={onClose}/>
                </div>
                <div className='px-12 py-20 flex justify-center items-center'>
                    <span className='text-popUpTextColor text-3xl font-medium'>{description}</span>
                </div>
            </div>
        </div>
    )
}

export default TextPopup