const retaInitialValues = {
    'type':'',
    'batchNo':'',
    'Pimage': '',
    'QROnprod': '',
    'preSaleQRLocation': '',
    'prodName': '',
    'manuLicenseNo': '',
    'manuAdd': '',
    'addDetails': '',
    'additionalImageDetails': '',
    'extraImage1': '',
    'extraImage2': '',
    'extraVideo1': '',
    'manuWebsiteLink': '',
    'totalQRGen': '',
    'trackingQr_tert': '',
    'trackingQr_quat': ''
}

export default retaInitialValues;