import OtpPopup from "./otpPopUp";
import PopupOneButton from "./popUpOneButton";
import TextBoxPopup from "./textBoxPopUp";
import TextPopup from "./textPopup";
import TwoButtonsPopUp from "./twoButtonsPopUp";
import { motion, AnimatePresence } from 'framer-motion';

function PopUpManager({ disable, control, heading, title, description, onClose, clickedOk, clickedNo, okButtonText, ...rest }) {

    const blurredBackgroundStyles = !disable
        ? {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(100, 100, 100, 0.5)",
            backdropFilter: "blur(1.8px)",
            zIndex: 25,
        }
        : {};

    return <>
        <AnimatePresence>
            {!disable && 
                <motion.div 
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1
                    }}
                    exit={{
                        opacity: 0
                    }}
                    transition={{
                        duration: 0.1
                    }}
                    style={blurredBackgroundStyles} 
                />}
        </AnimatePresence>
        <AnimatePresence>
            {!disable && 
                <motion.div
                    initial={{
                        // rotate: "15deg",
                        scale: 0.5
                    }}
                    animate={{
                        // rotate: "0deg",
                        scale: 1
                    }}
                    exit={{
                        scale: 0.5,
                        opacity: 0
                        // y: '100%'
                    }}
                    transition={{
                        duration: 0.1
                    }}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 50
                    }}
                >
                        {
                        control === 'two-button' && <TwoButtonsPopUp 
                            heading={heading}
                            title={title}
                            description={description}
                            onClose={onClose}
                            clickedOk={clickedOk}
                            clickedNo={clickedNo}
                            okButtonText={okButtonText}
                        />
                    }
                    {
                        control === 'text' && <TextPopup 
                            heading={heading}
                            description={description}
                            onClose={onClose}
                        />
                    }
                    {
                        control === 'one-button' && <PopupOneButton 
                        heading={heading}
                        description={description}
                        onClose={onClose}
                        onSubmit={clickedOk}
                        okButtonText={okButtonText}
                        />
                    }
                    {
                        control === 'otp-text' && <OtpPopup 
                            heading={heading}
                            title={title}
                            description={description}
                            onClose={onClose}
                            onClickSubmit={clickedOk}
                            {...rest}
                        />
                    }
                    {
                        control === 'reson-pop' && <TextBoxPopup 
                            heading={heading}
                            subHeading={title}
                            description={description}
                            onClose={onClose}
                            onSubmit={clickedOk}
                            okButtonText={okButtonText}
                            {...rest}
                        />
                    }
                </motion.div>
            }
        </AnimatePresence>
    </>
}

export default PopUpManager;