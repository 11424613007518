import * as Yup from 'yup';

const manuValidationSchema = Yup.object({
    Pimage: Yup.mixed().required("Product image is required"),

    QROnprod: Yup.mixed(),  //validation in validationForm function
    preSaleQRLocation: Yup.mixed(),  //validation in validationForm function
    expiryImage: Yup.mixed(),

    brand: Yup.string().required('This field is required'),
    prodName: Yup.string().required('This field is required'),
    batchid: Yup.string().required('This field is required'),
    mrp: Yup.number().required('This field is required').test(
        'Is positive?', 
        'MRP can not be negative', 
        value => value >= 0
    ),
    serialNo: Yup.string(),
    warrantyApp: Yup.boolean().required('This field is required'),
    warrantyPeriod: Yup.number()
        .positive()
        .integer()
        .nullable()
        .when('warrantyApp', (warrantyApp, schema) => {
            if( warrantyApp[0] == false || !warrantyApp[0] ){
                return schema
            }else{
                return schema.required("This field is required");
            }
        }),
    warrantyTC: Yup.string()
        .when('warrantyApp', (warrantyApp, schema) => {
            if( warrantyApp[0] == false || !warrantyApp[0] ){
                return schema
            }else{
                return schema.required("This field is required");
            }
        }),
    manuLicenseNo: Yup.string(),
    manuAdd: Yup.string().required('This field is required'),
    addDetails: Yup.string(),
    additionalFileDetails: Yup.string(),
    extraImage1: Yup.string(),
    extraImage2: Yup.string(),
    manuWebsiteLink: Yup.string().url('Please provide a valid url. example: `https://example.com`'),
})

export default manuValidationSchema;
