import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from 'formik'
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import ProfileICon from '../../../../assets/svg/user_icon.svg'
import FormikControl from '../../../../components/form/formikControl'
import BlueButton from "../../../../components/ui/buttons/blueButton"
import { SubmitButton } from '../../../../components/ui/buttons/submitButton'
import searchIcon from '../../../../assets/svg/search_icon.svg'
import ProfileForm from './customerProfileForm';
import PopUpManager from "../../../../components/popups2/popupManager";

import { getCustomerProfile } from "../services/api";
import { axiosPrivate } from "../../../../axios/axios";
import MobileNumber from "../../../../components/form2/MobileNumber";
import Loader from "../../../../components/ui/Loader/Loader";

import { isValidNumberForRegion } from "libphonenumber-js";

import femaleProfile from '../../../../assets/images/customer/female.svg'
import maleProfile from '../../../../assets/images/customer/male.svg'

import { USER_TYPES, SORT_ORDER } from "../../../../config/constants";
import { requestOtp, resendOtp } from "../../../../services/otpServices";

const SEARCH_TYPES = {
    MOBILE: 'Mobile',
    EMAIL: 'Email'
}

const GENDER_TYPES = {
    MALE: 'male',
    FEMALE: 'female'
}

const CUSTOMERACCESS_TYPES = {
    WHITELISTED: 'Whitelisted',
    BLACKLISTED: 'Blacklisted'
}

function Profile() {

    const navigate = useNavigate()
    const [ customerData, setCustomerData ] = useState({});
    const [ customerId, setCustomerId ] = useState('');
    const [ searchBy, setSearchBy ] = useState('');

    //Popup
    const [adminInfo, setAdminInfo] = useState('');
    const [loading, setLoading] = useState(false);
    const [ textPopupDisabled, setTextPopupDisabled ] = useState(true);
    const [ textPopupDescription, setTextPopupDescription ] = useState('');

    const [otpPopupDisabled, setOtpPopupDisabled] = useState(true);
    const [otpPopupHeading, setOtpPopupHeading] = useState('');
    const otpPopupFun = useRef(() => {});
    const [otp, setOtp] = useState('');
    const [reason, setReason] = useState('');

    const [otpSentTo, setOtpSentTo] = useState('');

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const response = {pageData: ["hii"]}
                setAdminInfo(response.pageData[0]);
            } catch (error) {
                setTextPopupDescription(error.message || 'Unable to fetch Admin Data');
                setTextPopupDisabled(false);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [axiosPrivate]);

    const handleSearch = async (values) => {
        const data = {
            isMobile: values.searchBy === SEARCH_TYPES.MOBILE? true : false,
            emailId: values.email,
            intelMobileNo: values.mobile?.intelMobileNo
        };

        try {
            setLoading(true);
            const customerDetails = await getCustomerProfile(data, axiosPrivate);

            setCustomerId(customerDetails?.customer?._id);
            setCustomerData({
                ...customerDetails?.customer,
                emailID: customerDetails?.customer?.emailID || 'N/A',
                intelMobileNo: customerDetails?.customer?.mobileNoDetails?.intelMobileNo || 'N/A',
                createdAt: new Date(customerDetails?.customer?.createdAt)
            });
        } catch(err) {
            console.log(err)

            setCustomerId(null);
            setCustomerData(null);
            setTextPopupDescription(err.message || 'Unable to fetch customer');
            setTextPopupDisabled(false);
        } finally {
            setLoading(false);
        }
    }

    const searchOptions = [
        {key: 'Mobile', value: SEARCH_TYPES.MOBILE},
        {key: 'Email', value: SEARCH_TYPES.EMAIL},
    ];

    const initialValues = {
        searchBy: '',
        email: '',
        mobile : {},
    }

    const validationSchema = Yup.object({
        searchBy: Yup.string().required('This field is required'),
        email: searchBy === SEARCH_TYPES.EMAIL ? Yup.string().when('searchBy', (searchBy, schema) => {
            if(searchBy[0] === SEARCH_TYPES.EMAIL) return schema.required('This Field is required!');
            else return schema
        }).email('Provide a valid Email ID') : Yup.string(),
        mobile : searchBy === SEARCH_TYPES.MOBILE? Yup.object().when('searchBy', (searchBy, schema) => {
            if(searchBy[0] === SEARCH_TYPES.MOBILE) return schema.required('This Field is required!');
            else return schema
        }).test('mobile-number-required', 'This field is required', value => {
            return value?.intelMobileNo && Boolean(value.intelMobileNo)
        }).test('valid-mobile-number', 'Please provide a valid mobile number', value => {
            return value?.intelMobileNo && value?.countryCode && isValidNumberForRegion(value.intelMobileNo, value.countryCode)
        }) : Yup.object()
    });
    
    const handleReqOtp = async () => {
        let success = false;
        try {
            setLoading(true);
            const data = await requestOtp(axiosPrivate);
            setOtpSentTo(data.emailId);

            success = true;
        } catch (err) {
            setTextPopupDescription(err.message || 'Error sending OTP')
            setTextPopupDisabled(false)

            success = false;
        } finally {
            setLoading(false)
        }
        return success;
    }

    const handleResendOtp = async () => {
        let success = false;
        try {
            setLoading(true)
            await resendOtp(adminInfo.emailId);

            success = true;
        } catch (err) {
            setTextPopupDescription(err.message || 'Error sending OTP')
            setTextPopupDisabled(false)

            success = false;
        } finally {
            setLoading(false)
        }
        return success;
    }

    const handleWhitelist = (customerId, otp, reason) => {

    }

    const handleBlacklist = (customerId, otp, reason) => {

    }

    const handlePurchaseAndWarranty = () => {
        navigate(`/purchases-and-warranty/${customerId}`);
    }

    const handleRewards = () => {
        navigate(`/rewards/${customerId}`);
    }

    return (
        <div>
            {loading && <Loader />}

            <PopUpManager 
                control={'otp-text'}
                disable={otpPopupDisabled}
                heading={'Alert'}
                title={otpPopupHeading}
                description={`Otp will be sent to ${otpSentTo.slice(0,2)}*******${otpSentTo.slice(otpSentTo.length-10, otpSentTo.length)}`}
        
                onClose={() => {
                    setOtpPopupDisabled(true);
                }}
                clickedOk={() => {
                    otpPopupFun.current(otp, reason);
                    setOtpPopupDisabled(true);
                }}
                otpSetter={setOtp}
                reasonSetter={setReason}
                resendOtp={handleResendOtp}
            />
            <PopUpManager 
                disable={textPopupDisabled}
                control='text'
                heading='Alert'
                description={textPopupDescription}
                onClose={() => setTextPopupDisabled(true)}
            />
            <div className="">
                <div className="flex flex-wrap items-center md:gap-[60px] gap-[15px] py-[20px]">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSearch}
                    >
                        {
                            formik => {
                                return (
                                    <Form className="rounded-3xl w-full flex flex-col">
                                        <div className="profile-search flex bg-bgLightBlue p-10 rounded-t-[12px] ">
                                            <label className="w-[20rem]">Search By :</label>
                                            <FormikControl control='radio' name={'searchBy'} width={'full'} options={searchOptions} startPadReq={false} onChange={(val) => {formik.setErrors({}); formik.setFieldValue('email', ''); setSearchBy(val)} } />
                                        </div>

                                        {
                                            formik.values.searchBy && <div className="bg-white flex flex-col gap-20 p-10 pt-20 rounded-b-[12px]">
                                                <div className="profile-search flex items-center max-w-[100rem] h-[5rem]">
                                                    <label className="w-[30rem] -mt-6">Profile Details :</label>
                                                    { formik.values.searchBy === SEARCH_TYPES.MOBILE && <MobileNumber formik={formik} name={'mobile'}  /> }
                                                    { formik.values.searchBy === SEARCH_TYPES.EMAIL && <FormikControl control='input' name={'email'} placeholder={"Email id"} bgcolor={'selectorBg'} width={'full'} textcolor='financeSideBoxText' placeholderIcon={searchIcon} /> }
                                                </div>
                                                <div className="">
                                                    <SubmitButton value={'Search'} btnWidth={'[100%]'} type={'submit'} />
                                                </div>
                                            </div>
                                        }
                                    </Form>
                                )
                            }
                        }
                    </Formik>
                </div>

            </div>
            {
                customerId && 
                <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px] py-[20px]">
                    <div className="flex flex-col justify-center items-center bg-white rounded-[12px] border border-[#182AB41A] pt-[38px] px-[20px] pb-[20px]">
                        <div className="icon-wrapper h-[70%] aspect-square">
                            <img className="mx-auto w-[100%] h-[100%]" src={
                                customerData.gender === GENDER_TYPES.FEMALE? femaleProfile : 
                                customerData.gender === GENDER_TYPES.MALE? maleProfile : ProfileICon
                            } alt="profile-img" />
                        </div>
                    </div>
                
                    <div className="bg-white rounded-[12px] border border-[#182AB41A] py-[20px] px-[20px]">
                        {/* pass the customer data as initial values */}
                        <ProfileForm initialValues={customerData} />
                    </div>
                </div >
                
                
                <div className="flex gap-[12px] flex-wrap mb-10">
                    {/* {
                        customerData.status === CUSTOMERACCESS_TYPES.WHITELISTED && <div className="flex-auto">
                            <BlueButton label={'Blacklist'} width={'[100%]'} height={'[50px]'} onClick={async () => {
                                const success = await handleReqOtp();
                                if(!success) return;

                                setOtpPopupHeading('Do you want to BLACKLIST this Customer?')
                                otpPopupFun.current = (otp, reason) => {
                                    handleBlacklist(customerId, otp, reason);
                                }
                                setOtpPopupDisabled(false);
                            }} />
                        </div>
                    }
                    {   
                        customerData.status === CUSTOMERACCESS_TYPES.BLACKLISTED && <div className="flex-auto">
                            <BlueButton label={'Whitelist'} width={'[100%]'} height={'[50px]'} onClick={async () => {
                                const success = await handleReqOtp();
                                if(!success) return;

                                setOtpPopupHeading('Do you want to WHITELIST this Customer?')
                                otpPopupFun.current = (otp, reason) => {
                                    handleWhitelist(customerId, otp, reason);
                                }
                                setOtpPopupDisabled(false);
                            }} />
                        </div>
                    } */}
                    
                    <div className="flex-auto">
                        <BlueButton type='submit' label={'Purchases & Warranty'} width={'[100%]'} height={'[50px]'} onClick={handlePurchaseAndWarranty} />
                    </div>
                    <div className="flex-auto">
                        <BlueButton type='submit' label={'Rewards'} width={'[100%]'} height={'[50px]'}  onClick={handleRewards} />
                    </div>
                </div>
                </>
            }
            
        </div >

    )
}

export default Profile