import { addDays, differenceInDays } from "date-fns";

const calculateManufacturingDateChange = async(values) => {
    const { manufacturingDate, shelfLife } = values;
    if(!shelfLife || !manufacturingDate){
        return null;
    }
    const expiryDate = addDays(Date.parse(manufacturingDate), shelfLife);
    return expiryDate
};

const calculateExpiryDateChange = async(values) => {
    const { expiryDate, mfgDate } = values;
    if(!mfgDate || !expiryDate){
        return null;
    }
    const shelfLife = differenceInDays(expiryDate, Date.parse(mfgDate));
    return shelfLife;
};

const calculateShelfLifeChange = async(values) => {
    const { shelfLife, mfgDate } = values;
    if(!mfgDate || !shelfLife){
        return null;
    }
    const expiryDate = addDays(Date.parse(mfgDate), shelfLife);
    return expiryDate;
};

export {
    calculateManufacturingDateChange, 
    calculateExpiryDateChange,
    calculateShelfLifeChange
}