import React, { useEffect, useRef, useState } from 'react';
import VideoSnapshot from 'video-snapshot';
import { Field, ErrorMessage } from 'formik'
import TextError from './textError'

function VideoUpload(props) {
  const { label, name, acceptableFiles, onFileSelect, blobRequired = true, defaultVideoUrl, pdX = 0, pdY = 0, fileSizeLimit = 10, ...rest } = props

  const [selectedFile, setSelectedFile] = useState(null);
  const [videoFrameUrl, setVideoFrameUrl] = useState(null);
  const [isItVideo, setIsItVideo] = useState(false);
  const [fileName, setFileName] = useState(null);
  const videoRef = useRef()

  useEffect(() => {
    if(!defaultVideoUrl) return;
    console.log("bui")
    setSelectedFile('')
    setFileName('')
    setVideoFrameUrl('')
    
  }, [defaultVideoUrl])
  
  const handleFileChange = async (event) => {
    let file = event.target.files[0];
    if(!isItVideoFunc(file)) return;

    const MAX_FILE_SIZE = fileSizeLimit * 1024 * 1024;
    if(file.size > MAX_FILE_SIZE) {
      alert(`File size should be less than ${fileSizeLimit}MB`);
      return;
    }

    setSelectedFile(file);
    setIsItVideo(isItVideoFunc(file));
    setFileName(file.name);
    file = new File([file], `${Math.floor(Math.random()*1000000)}.${file.type.split('/')[1]}`, { type: file.type });

    if (onFileSelect !== undefined) {
      if(blobRequired) {
        const fileUrl = URL.createObjectURL(file);
        onFileSelect(fileUrl);
      }
      else {
        onFileSelect(file);
      }
    }

    const snapshoter = new VideoSnapshot(file);
    const previewSrc = await snapshoter.takeSnapshot();
    console.log(previewSrc)
    setVideoFrameUrl(previewSrc);
  };


  return (
    <div style={{padding: `${pdY} ${pdX}`}}>
      <div className='md:flex justify-start items-center'>
        <label htmlFor={name} className='text-sideBarBtnText text-font16'>
          {label}
        </label>
        <div className="file-img-conatiner flex items-center gap-7 w-full">
          <div className="img-wrapper w-full p-3 h-[66px] bg-selectorBg rounded-[5px] ">
            {
              !videoFrameUrl && !fileName && !defaultVideoUrl &&
              <div className='h-full w-full flex justify-center items-center'>
                  <p className='text-3xl px-4'>Please Upload a Video file</p>
              </div>
            }
            {
              defaultVideoUrl && !videoFrameUrl && !fileName && <video 
                ref={videoRef} 
                style={{ maxWidth: '100%', maxHeight: '100%', width: '100%', height: 'auto', objectFit: 'cover' }}
              >
                <source src={defaultVideoUrl} type="video/mp4" />
              </video>
            }
            {videoFrameUrl ? 
              <img src={videoFrameUrl} name="wyy" alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '100%', width: '100%', height: 'auto', objectFit: 'cover' }} />
             : <div style={{ maxWidth: '100%', maxHeight: '100%', width: '100%', height: 'auto', objectFit: 'cover', overflow: 'hidden', textOverflow: 'clip' , textAlign: 'center'}}><h6>{fileName}</h6></div> }
          </div>
          <div className="input-file">
            <Field
              id={name}
              name={name}
              type='file'
              accept='video/mp4'
              className="w-full"
              onChange={handleFileChange}
              {...rest}
            />
          </div>
        </div>
      </div>
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

function isItVideoFunc (file)  {
  const name = file.name

  const data = name.split('.')
  const extension = data[data.length - 1];

  const extensions = [ 'mp4', 'webm', 'mkv', 'flv', 'avi', 'mov', 'wmv', 'm4v', '3gp', 'ogg', 'ogv', 'mpg', 'mpeg', 'm2v', 'ts', 'mxf', 'asf', 'vob', 'swf',];

  for(let i = 0; i < extensions.length; i++){
    if(extension === extensions[i]){
      return true;
    }
  }

  return false;
}

export default VideoUpload;


