import React, { useState, useMemo, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {Header} from '../../../layouts/header'
import Navbar from '../../../layouts/navbar'
import { SubmitButton } from '../../../components/ui/buttons/submitButton'
import Sidebar from '../../../layouts/sidebar'
import TextPopup from "../../../components/popups/textPopup"

import useSidebar from '../../../hooks/useSidebar'

import sidebarData from '../utils/sidebarData'
import warning from '../../../assets/images/warning.png';

function Index() {

    const navigate = useNavigate();
    const { isSidebarOpen, toggleSidebar } = useSidebar();
    const state = useLocation().state
    useEffect(() => {
        if(!state || !state.qrCodeLinks) {
            navigate('/new-batch-details')
        }
    }, [])

    const batchData = useMemo(() => {
        return state ? state.batchData || {} : {};
    }, [state]);
    const downloadLinks = useMemo(() => {
        return state ? state.qrCodeLinks || {} : {};
    }, [state]);

    const [showTextPopup, setShowTextPopup] = useState(false)
    const [alertText, setAlertText] = useState("");
    const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);

    const blurredBackgroundStyles = isBackgroundBlurred
        ? {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(100, 100, 100, 0.5)",
            backdropFilter: "blur(1.8px)",
            zIndex: 1,
        }
        : {};

    const navData = [
        {
            name: 'Qr',
            link: '/new-batch-details'
        },
        {
            name: 'Download Generated Qrs',
            link: '/generated-qr-codes',
            state: state
        },
    ]



    const handleDownload = async (url) => {
        try {
            const link = document.createElement('a');
            link.href = url;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); 
        } catch (error) {
            setAlertText(`Unable to download file, ${error.message}`);
            setShowTextPopup(true);
            setIsBackgroundBlurred(true);
        }
    }

    return (
        <>
        {isBackgroundBlurred && <div style={blurredBackgroundStyles} />}
        <div className='layout-wrapper'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>
        Hii mowa
            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sidebarData} />
                </div>
                
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className='px-[20px] h-full'>
                        <div className='h-10 flex items-center'>
                            <Navbar navData={navData} />
                        </div>
                    <div className='h-[90%]'>
                        <div className='bg-bgLightBlue  flex flex-row justify-between items-center h-16 border-solid border-navBorder rounded-t-xl'>
                            {
                                state && <h6 className=' font-medium pl-10 text-2xl'>Code list for the product with batch number {batchData.batchid}</h6>
                            }
                            <h6 className=' font-medium pr-10 text-2xl'>For queries: call on 9632123543</h6>
                        </div>
                        {showTextPopup && (
                            <TextPopup
                                heading="Alert"
                                description={alertText}
                                onClose={() => {
                                    setShowTextPopup(false);
                                    setIsBackgroundBlurred(false);

                                    navigate('/new-batch-details');
                                }}
                            />
                        )}
                        {
                            downloadLinks && <div className='h-[80%] bg-bgWhite flex flex-col justify-center items-center'>
                                <h6 className='font-bold h-11 text-3xl'>Your File is Ready!!</h6>
                                <h6 className='text-sideBarBtnText h-11 text-3xl'>Download the file to see the QR list</h6>
                                <div className='w-[90%] flex flex-col gap-5'>
                                    {downloadLinks.prodQrs && <SubmitButton btnWidth={'[100%]'} value={"Download Product Qrs"} onClick={() => handleDownload(downloadLinks.prodQrs)} />}
                                    {downloadLinks.quatQrs && <SubmitButton btnWidth={'[100%]'} value={"Download Quaternary Qrs"} onClick={() => handleDownload(downloadLinks.quatQrs)} />}
                                    {downloadLinks.tertQrs && <SubmitButton btnWidth={'[100%]'} value={"Download Tertiary Qrs"} onClick={() => handleDownload(downloadLinks.tertQrs)} />}
                                </div>

                                <div className='border-yellow-500 border bg-yellow-100 p-10 rounded-xl mt-20 flex justify-center items-center'>
                                    <img src={warning} alt="" className='h-[2rem] w-[2rem] mr-2' />
                                    <p className='text-2xl'><span className='font-semibold'>Please note:</span> These QR links will remain valid for 30 minutes. You can also download them later from the 'Download QR' section.</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            </div>
        </div>
        </>
    )
}

export default Index



