import { handleError } from "../../../../utils/handles/errorHandle";

async function getAllCustomerCouponsPaginated(masterClientObjectId, customerId, pageNumber, pageSize, last30Days, years, other,axiosPrivate){
    try{
        const response = await axiosPrivate.get(`/api/coupons/customer/reward-history/${masterClientObjectId}/${customerId}/paginated`, {
            params: {
                pageNumber,
                pageSize,
                last30Days, 
                years: JSON.stringify(years), 
                other
            }
        });
        
        return response?.data.rewardsHistory;
    }catch(error){
        handleError(error)
    }
}

async function getProductDetailsById(productId, axiosPrivate){
    console.log(productId, "as")
    try{
        const response = await axiosPrivate.get(`/api/customer/product-details/${productId}`);
        return response?.data.data;
    }catch(error){
        handleError(error)
    }
}

// 

export{
    getAllCustomerCouponsPaginated,
    getProductDetailsById
}