import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND;
const QR_GEN_URL= process.env.REACT_APP_BACKEND_QR;
const timeOutForQrServer = (60000) * 60; //60000 = 1min

const axiosInstance=axios.create({
    baseURL:BASE_URL,
});

export const axiosPrivate = axios.create({
    baseURL:BASE_URL,
});

export const axiosQrPrivate = axios.create({
    baseURL: QR_GEN_URL,
    timeout: timeOutForQrServer
});

export default axiosInstance; 
