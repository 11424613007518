import React, { useState,useEffect, useCallback } from 'react'

import { SubmitButton } from '../../../../components/ui/buttons/submitButton'
import SortingTable from '../../../../components/table/sortingTable'
import ClientAddUserForm from './clientAddUserForm'

import Loader from "../../../../components/ui/Loader/Loader";
import PopUpManager from '../../../../components/popups2/popupManager'

import useAxiosPrivate from "../../../../hooks/useAxiosPrivate"
import { getAllSubClientsPaginated } from '../services/api'

import addIcon from '../../../../assets/svg/add_icon_white.svg'
import useUserData from '../../../../hooks/useUserData';

const SubclientsHeaderData = [{value: 'User ID', space: '30%', key: 'userId'}, {value: 'Name', space: '40%', key: 'userName'}, {value: 'Email Address', space: '50%', key: 'emailId'}, {value: 'Status', space: '20%', key: 'status'}]

function ClientAddUser() {

    const axiosPrivate = useAxiosPrivate();
    const { user } = useUserData();

    const pageSize = 10;
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [maxPageNo, setMaxPageNo] = useState(0);
    
    const [addUserForm, setAddUserForm] = useState(false);
    const [subClientsData, setsubClientsData] = useState([]);
    const masterClientId = user?.masterClientId;
    
    
    const [canCreateSubClient, setCanCreateSubClient] = useState(true);
    const [canAddSubClient, setCanAddSubClient] = useState(false)
    
    const [tableLoading, setTableLoading] = useState(false);
    const [textPopupDisabled, setTextPopupDisabled] = useState(true);
    const [textPopupDescription, setTextPopupDescription] = useState('heading');

    const fetchSubClients = useCallback(async (currentPageNo) => {
        if(!masterClientId) return;
        try{
            setTableLoading(true);
            const data = await getAllSubClientsPaginated(masterClientId, currentPageNo, pageSize, axiosPrivate);

            data.pageData?.forEach(client => client.status = client.isBlocked? 'Blocked' : 'UnBlocked');
            setsubClientsData(data.pageData);
            setMaxPageNo(Math.floor(data.totalUsers / pageSize) + (data.totalUsers % pageSize ? 1 : 0));
            setAddUserForm(true);
        }catch(error){
            setTextPopupDescription(error.message || 'Unable to fetch sub clients');
            setTextPopupDisabled(false);
        } finally {
            setTableLoading(false);
        }
    }, [axiosPrivate, masterClientId]);

    useEffect(() => {
        if(masterClientId) {
            (async () => {
                await fetchSubClients(currentPageNo);
            })();
        }
    }, [fetchSubClients, masterClientId, currentPageNo]);


    const onCreateSuccess = async (newSubClient) => {
        setCanCreateSubClient(false);
        setCanAddSubClient(true);

        //Show popUp
        setTextPopupDescription(`New Sub client created with id: ${newSubClient.userId}`);
        setTextPopupDisabled(false);

        //update table
        setCurrentPageNo(1);
        await fetchSubClients(1);
    }

    return (
        <>
            <PopUpManager 
                disable={textPopupDisabled}
                control='text'
                heading='Alert'
                description={textPopupDescription}
                onClose={() => setTextPopupDisabled(true)}
            />

            <div className='h-full'>
                {addUserForm &&
                    <div className='bg-popupScreen py-5 rounded-[12px]'>
                        {canCreateSubClient && <ClientAddUserForm onCreateSuccess={onCreateSuccess} masterClientId={masterClientId}/>}
                    
                        <div className='w-[20%] pl-10 flex items-center pt-10' >
                            <SubmitButton value='Add More Users' imageUrl={addIcon} btnWidth='full' disabled={!canAddSubClient} onClick={() => {
                                setCanCreateSubClient(true);
                                setCanAddSubClient(false);
                            }}/>
                        </div>
                    </div>
                }

                {
                    addUserForm && subClientsData && subClientsData.length && 
                    <SortingTable 
                        headerData={SubclientsHeaderData} 
                        tableData={subClientsData} 
                        nextDisabled={currentPageNo >= maxPageNo} 
                        previousDisabled={currentPageNo === 1} 
                        nextPage={() => {if(currentPageNo < maxPageNo) setCurrentPageNo(currentPageNo + 1)}}
                        previousPage={() => {if(currentPageNo > 1) setCurrentPageNo(currentPageNo - 1)}}
                        loading={tableLoading}
                        />
                }

                {
                    addUserForm && (!subClientsData || !subClientsData.length) &&
                    <div className='h-10 flex justify-center items-center mt-10 bg-white'>
                        <p className='text-4xl'>No Sub clients Data Found</p>
                    </div>
                }
                
            </div>
        </>
    )
}

export default ClientAddUser