import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import CloseIcon from '../../assets/svg/popups/popUpCloseIcon.svg'
import { SubmitButton } from '../ui/buttons/submitButton'
import DeleteButtonRed from '../ui/buttons/deleteButtonRed'
import RedButton from '../ui/buttons/redButton'
import { ErrorMessage } from 'formik';

import removeSpecialCharacters from '../../utils/functionalities/removeSpecialCharacters';

function OtpPopup(props) {
    const { heading, title, description, onClose, errorMessage, onClickSubmit, isOtpValid, otpSetter, reasonSetter, resendOtp } = props
    const [otp, setOtp] = useState('');
    const [reason, setreason] = useState('')
    const [otpErr, setOtpErr] = useState('');
    const [reasonErr, setREasonErr] = useState('');
    const handleSubmit = () => {
        if(!reason) {
            setREasonErr('Please provide a valid reason');
            return;
        }
        if(!otp) {
            setOtpErr('Please provide a valid OTP');
            return;
        }
        const otpdata = { otp: otp, reason: reason }
        onClickSubmit && onClickSubmit(otpdata);
    }
    const handleInputChange = (event) => {
        const value = event.target.value;
        setreason(removeSpecialCharacters(value));
    };

    useEffect(() => {
        otpSetter && otpSetter(otp)
        if(otp) setOtpErr('');
    }, [otp]);

    useEffect(() => {
        reasonSetter && reasonSetter(reason)
        if(reason) setREasonErr('');
    }, [reason]);

    const handleCancle = () => { }

    const [time, setTime] = useState({ minutes: 2, seconds: 10});
    useEffect(() => {
        const timer = setInterval(() => {
            if(time.minutes === 0 && time.seconds === 0) {
                clearInterval(timer);
                return;
            }

            let newTime = { minutes: 0, seconds: 0 };
            if (time.seconds === 0) 
                newTime = { minutes: time.minutes - 1, seconds: 59 };
            else 
                newTime = { minutes: time.minutes, seconds: time.seconds - 1 };
            
            setTime(newTime);
        }, 1000);

        return () => clearInterval(timer);
    }, [time])

    return (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  flex flex-col w-[25%]  z-10'>
            <div className='bg-popupScreen flex flex-col w-[100%] justify-between items-center'>
                <div className='flex justify-between items-center p-10  h-14 w-[100%] bg-gradient-to-r from-gradientPurple  to-gradientBlue to-90% '>
                    <span className='font-bold text-4xl text-popupScreen'>{heading}</span>
                    <img className='w-9 h-9 cursor-pointer' src={CloseIcon} alt='' onClick={onClose} />
                </div>
                <div className='pl-12 pr-12 pt-8 pb-8  flex justify-center items-center '>
                    <span className='text-popUpTextColor text-xl font-medium'>{title}</span>
                </div>
                <div className='w-[100%] flex flex-col justify-start items-center'>
                    <OtpInput
                        className='w-[100%] flex '
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderInput={(props, i) => (
                            <input {...props} className="w-16 h-14 text-2xl " />
                        )}
                        containerStyle="flex space-x-4"
                        inputStyle={{
                            width: '4rem', // Adjust the width as needed
                            height: '4rem', // Adjust the height as needed
                            backgroundColor: '#182AB408', // Example background color
                            textAlign: 'center',
                            borderRadius: '0.25rem',
                            border: '1px solid #CBD5E0',
                            outline: 'none',
                            transition: 'border-color 0.3s, box-shadow 0.3s',
                        }}
                    />
                    <p className='text-red-600 text-lg mt-2'>{otpErr}</p>
                    <span className='text-red-400 text-xl w-[100%] font-medium pt-4  flex justify-center items-start'>{errorMessage}&nbsp;</span>
                    {resendOtp && (time.minutes > 0 || time.seconds > 0) && <p className='text-xl text-gray-600 my-6'>{(time.minutes || time.seconds) ? `Please wait for 0${time.minutes} : ${time.seconds < 10? '0' : ''}${time.seconds} to request new OTP` : ''}</p>}
                    {resendOtp && time.minutes === 0 && time.seconds === 0 && <p className='underline text-2xl my-6 cursor-pointer' onClick={() => {
                        setTime({ minutes: 2, seconds: 10}); 
                        resendOtp && resendOtp();
                    }}>Resend OTP</p>}
                </div>
                <div className='pl-12 pr-12 pb-10 flex justify-center items-center '>
                    <span className='text-popUpTextColor text-2xl font-medium text-center'>{description}</span>
                </div>
                <div className='bg-bgWhite w-[100%] pl-10 pr-10 pb-10'>
                    <input className='w-[100%] pt-2 pl-3 pb-60 text-xl bg-selectorBg placeholder-start flex items-start justify-start ' type='text-area' name='reason' placeholder='2000 characters allowed' value={reason} onChange={handleInputChange} />
                    <p className='text-red-600 text-lg'>{reasonErr}</p>
                </div>
                <div className='flex w-[100%] pl-10 pr-10 pb-5 justify-between items-center'>
                    <div className='w-[48%]' >
                        <SubmitButton type={'submit'} value='Submit' btnWidth='full' onClick={handleSubmit} disabled={!reason || otpErr} />
                    </div>
                    <div className='w-[48%]' onClick={onClose}>
                        <RedButton type={'submit'} label='Cancel' width='full' height='16' onClcik={handleCancle} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OtpPopup