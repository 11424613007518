const retaInitialValues = {
    'skuId': '',
    'pimage': '',
    'QROnprod': '',
    'preSaleQRLocation': '',
    'prodName': '',
    'manuLicenseNo': '',
    'manuAdd': '',
    'addDetails': '',
    'additionalImageDetails': '',
    'extraimage1': '',
    'extraimage2': '',
    'prodVedioLink': '',
    'manuWebsiteLink': ''
}

export default retaInitialValues;