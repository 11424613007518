import { handleError } from "../../../../utils/handles/errorHandle";

async function getDataSummary(type, year, month, masterClientId, axiosPrivate) {
    try {
        const response = await axiosPrivate.get('/api/report/raw-data-summary', {
            params: {   
                type,
                year,
                month,
                masterClientId
            },
            responseType: 'blob'
        })
        console.log(response.data);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

export {
    getDataSummary
}