import * as Yup from 'yup';

const retaValidationSchema = Yup.object({
    Pimage: Yup.mixed().required("Product image is required"),
    QROnprod: Yup.mixed(),  //validation in validationForm function
    preSaleQRLocation: Yup.mixed(),  //validation in validationForm function

    manuLicenseNo: Yup.string(),
    manuAdd: Yup.string().required('This field is required'),
    addDetails: Yup.string(),
    additionalFileDetails: Yup.string(),
    extraImage1: Yup.string(),
    extraImage2: Yup.string(),
    manuWebsiteLink: Yup.string().url('Please provide a valid url. example: `https://example.com`'),
})

export default retaValidationSchema;
