import { React, useEffect, useState } from 'react'
import DownArrowIcon from '../../../assets/svg/down_arrow_icon.svg'
import { motion, useAnimationControls } from 'framer-motion';

function FormHeadingComponent(props) {

  const controls = useAnimationControls(); 

  useEffect(() => {
    if(props.clicked) {
      controls.start('down')
    }
    else {
      controls.start('right')
    }
  }, [props.clicked])

  return (
    <div className=''>
      <div className={`bg-bgLightBlue flex flex-row rounded-t-3xl justify-start items-center py-5 border-solid border-navBorder cursor-pointer ${props.clicked? 'rounded-b-none' : 'rounded-b-3xl'}`}>
        <button className='ml-5' type='button'>
          <motion.img 
            variants={{
              down: {
                rotate: 0,
              },
              right: {
                rotate: -90,
              }
            }}
            animate={controls}
            
            className='w-5 aspect-square' 
            src={DownArrowIcon} 
            />
        </button>
        <h5 className='text-sideBarBtnText font-bold pl-5'>{props.name}</h5>
      </div>
    </div>
  )
}

function FormSubHeadingComponent(props) {

  const controls = useAnimationControls();

  useEffect(() => {
    if(props.clicked) {
      controls.start('down')
    }
    else {
      controls.start('right')
    }
  }, [props.clicked])

  return (
    <div className='bg-bgWhite flex flex-row justify-start items-center py-5 border-solid border-navBorder cursor-pointer' >
      <button className='ml-5' type='button'>
        <motion.img 
          variants={{
            down: {
              rotate: 0,
            },
            right: {
              rotate: -90,
            }
          }}
          animate={controls}

          className='w-5 aspect-square' 
          src={DownArrowIcon} 
        />
      </button>
      <h5 className='text-sideBarBtnText font-medium pl-5 w-[75%]'>{props.name}</h5>
      {props.extraText && <h6 className='text-financeSideBoxText w-[20%] text-font16'>{props.extraText}</h6>}
    </div>
  )
}
export { FormHeadingComponent, FormSubHeadingComponent }
