import React, { useState, useEffect, useRef, useCallback } from 'react'
import DeleteButtonRed from '../../../../components/ui/buttons/deleteButtonRed'
import { Tablehead } from '../../../../components/table/tablehead'

import Loader from "../../../../components/ui/Loader/Loader";
import PopupManager from '../../../../components/popups2/popupManager';

//importing necessary hooks
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate"
import useAuth from '../../../../hooks/useAuth'
import { SubmitButtonWide, SubmitButton } from '../../../../components/ui/buttons/submitButton';

import useUserData from '../../../../hooks/useUserData';

//importing neccessary api services
import {getAllSubClientsPaginated, blockSubClient, deleteSubClient, unBlockSubClient} from "../services/api"
//importing necessary utils
import {requestOtp, resendOtp} from "../../../../services/otpServices";

function DeleteClient({adminContactMail}) {

    console.log("mail", adminContactMail)

    const {auth} = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const { user : userData } = useUserData();

    const pageSize = 10;
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [maxPageNo, setMaxPageNo] = useState(0);
    const [tableLoading, setTableLoading] = useState(false);
    
    const [subClients, setsubClients] = useState([])
    const masterClientId = userData?.masterClientId;

    const [loading, setLoading] = useState()

    const [otpPopupDiabled, setOtpPopupDisabled] = useState(true); 
    const [otpPopupText, setOtpPopupText] = useState('');
    const otpOkHandler = useRef(null);
    const [otp, setOtp] = useState('');
    const [reason, setReason] = useState('');
    const [otpErr, setOtpErr] = useState('');

    const [twoButtonDisabled, setTwoButtonDisabled] = useState(true);
    const [twoButtonText, setTwoButtonText] = useState('');
    const twoButtonHandler = useRef(null);

    const [textPopupDiabled, setTextPopupDisabled] = useState(true);
    const [textPopupText, setTextPopupText] = useState('');

    const [otpSentTo, setOtpSentTo] = useState('');

    useEffect(() => {
        setOtpErr('');
    }, [otpPopupDiabled])

    const tableHeaderOptions = [
        { heading: 'User ID’s', width: '35%' },
        { heading: 'Email Address', width: '35%' },
    ]

    const fetchSubClients = useCallback(async (currentPageNo) => {
        if(!masterClientId) return;
        try{
            setTableLoading(true);
            const data = await getAllSubClientsPaginated(masterClientId, currentPageNo, pageSize, axiosPrivate);
            console.log(data, 'api daa')
            const subsData = data?.pageData?.map(sub => {
                return { ...sub };
            });

            setsubClients(subsData || []);
            setMaxPageNo(Math.floor(data.totalUsers / pageSize) + (data.totalUsers % pageSize ? 1 : 0));
        } catch(error) {
            setsubClients([]);
            setTextPopupText(error.message || 'Error fetching sub clients');
            setTextPopupDisabled(false);
        } finally {
            setTableLoading(false);
        }
    }, [axiosPrivate, masterClientId]);

    useEffect(() => {
        (async () => {
            await fetchSubClients(currentPageNo);
        })();
    }, [fetchSubClients, masterClientId, currentPageNo]);
    //loding subclients
    

    const handleReqOtp = async () => {
        let success = false;
        try {
            setLoading(true)
            const data = await requestOtp(axiosPrivate);
            setOtpSentTo(data.emailId);

            success = true;
        } catch (err) {
            setTextPopupText(err.message || 'Error sending OTP');
            setTextPopupDisabled(false);

            success = false;
        } finally {
            setLoading(false)
        }

        return success;
    }

    const handleResendOtp = async () => {
        let success = false;
        try {
            setLoading(true)
            await resendOtp(adminContactMail);

            success = true;
        } catch (err) {
            setTextPopupText(err.message || 'Error sending OTP');
            setTextPopupDisabled(false);

            success = false;
        } finally {
            setLoading(false)
        }
        return success;
    }

    const handleDeleteSubClient = async (user, otp, reason) => {
        let success = false;
        if(otp.length !== 4) {
            setOtpErr('Provide a valid otp');
            return (success = false);
        }

        try {
            setLoading(true)
            await deleteSubClient({
                userId: user.userId,
                isMobile: false,
                emailId: adminContactMail,
                otp,
                reason,
            },axiosPrivate);
            success = true;
            
            setTextPopupText(`${user.userId} Deleted Successfully`);
            setTextPopupDisabled(false);
        } catch(err) {
            success = false;
            if(err.message === 'Invalid OTP' && err.headers[`ratelimit-remaining`] > 0) {
                setOtpErr(`Invalid OTP, You still have ${err.headers[`ratelimit-remaining`]} attempts left`);
                setLoading(false);
                return success;
            }

            setTextPopupText(err.message || `Error Deleting ${user.userId}`);
            setTextPopupDisabled(false);
            setOtpPopupDisabled(true);
        } finally {
            setLoading(false)
        }
        return success;
    }

    const handleBlockSubClient = async (user, otp, reason) => {
        let success = false;
        if(otp.length !== 4) {
            setOtpErr('Provide a valid otp');
            return (success = false);
        }
        
        try {
            setLoading(true)
            await blockSubClient({
                userId: user.userId,
                isMobile: false,
                emailId: adminContactMail,
                otp,
                reason,
            },axiosPrivate);
            success = true;
            
            setTextPopupText(`${user.userId} Blocked Successfully`);
            setTextPopupDisabled(false);
        } catch(err) {
            success = false;
            if(err.message === 'Invalid OTP' && err.headers[`ratelimit-remaining`] > 0) {
                setOtpErr(`Invalid OTP, You still have ${err.headers[`ratelimit-remaining`]} attempts left`);
                setLoading(false);
                return success;
            }

            setTextPopupText(err.message || `Error Blocking ${user.userId}`);
            setTextPopupDisabled(false);
            setOtpPopupDisabled(true);
        } finally {
            setLoading(false)
        }

        return success;
    }

    const handleUnblockSubClient = async (user, otp, reason) => {
        let success = false;
        if(otp.length !== 4) {
            setOtpErr('Provide a valid otp');
            return (success = false);
        }

        try {
            setLoading(true)
            await unBlockSubClient({
                userId: user.userId,
                isMobile: false,
                emailId: adminContactMail,
                otp,
                reason,
            },axiosPrivate);
            success = true;
            
            setTextPopupText(`${user.userId} Unblocked Successfully`);
            setTextPopupDisabled(false);
        } catch(err) {
            success = false;
            if(err.message === 'Invalid OTP' && err.headers[`ratelimit-remaining`] > 0) {
                setOtpErr(`Invalid OTP, You still have ${err.headers[`ratelimit-remaining`]} attempts left`);
                setLoading(false);
                return success;
            }

            setTextPopupText(err.message || `Error Unblocking ${user.userId}`);
            setTextPopupDisabled(false);
            setOtpPopupDisabled(true);
        } finally {
            setLoading(false)
        }

        return success;
    }

    return (
        <>
            {loading && <Loader />}
            <PopupManager 
                control={'otp-text'}
                disable={otpPopupDiabled || !textPopupDiabled}
                heading={'Alert'}
                title={otpPopupText}
                description={`Otp will be sent to ${otpSentTo.slice(0,2)}*******${otpSentTo.slice(otpSentTo.length-10, otpSentTo.length)}`}
                okButtonText={'Confirm'}
                errorMessage={otpErr}

                onClose={() => {
                    setOtpPopupDisabled(true)
                }}
                clickedOk={async () => {
                    const success = await otpOkHandler.current(otp, reason);
                    if(success) setOtpPopupDisabled(true);
                }}
                otpSetter={setOtp}
                reasonSetter={setReason}
                resendOtp={handleResendOtp}
            />

            <PopupManager 
                control={'two-button'}
                disable={twoButtonDisabled}
                heading={'Alert'}
                title={twoButtonText}
                okButtonText={'Yes'}
                onClose={() => {
                    setTwoButtonDisabled(true);
                }}
                clickedOk={() => {
                    setTwoButtonDisabled(true);
                    twoButtonHandler.current && twoButtonHandler.current()
                }}
            />

            <PopupManager 
                control={'text'}
                disable={textPopupDiabled}
                heading={'Alert'}
                description={textPopupText}
                onClose={() => {
                    setTextPopupDisabled(true);
                }}
            />

            <div className='mt-10 h-full'>
                <table className='w-full rounded-t-[12px] overflow-hidden'>
                    <Tablehead options={tableHeaderOptions} height='20'/>
                </table>
                <div className='flex flex-col w-[100%] mb-10 relative'>
                    {tableLoading && <Loader componental={true} />}
                    <div className='flex flex-col w-[100%] bg-popupScreen rounded-b-[12px]' style={subClients && subClients.length > 0 ? {padding: '15px 0'}: {}}>
                        {
                            subClients && subClients.length > 0 && subClients.map((user, index) => (
                            !user.deleted &&
                            <div className='pl-10 pr-10 bg-black-200  flex justify-between items-center my-3' key={index}>
                                <div className='w-[35%] bg-selectorBg h-20 text-popupText font-medium text-font14 flex justify-center items-center'>
                                    {user.userId}
                                </div>
                                <div className='w-[35%] bg-selectorBg h-20 text-popupText font-medium text-font14 flex justify-center items-center'>
                                    {user.emailId}
                                </div>
                                
                                <div className='w-[25%] flex justify-between ' >
                                    <div className='w-[48%]' >
                                        <DeleteButtonRed 
                                            value='Delete' 
                                            btnWidth='full' 
                                            onClick={() => {
                                                twoButtonHandler.current = async () => {
                                                    const success = await handleReqOtp();
                                                    if(!success) return;

                                                    otpOkHandler.current = async (otp, reason) => {
                                                        const success = await handleDeleteSubClient(user, otp, reason); //Main function
                                                        if(success) user.deleted = true; 

                                                        return success;
                                                    }
                                                    setOtpPopupText(`DELETE ${user.userId}`)
                                                    setOtpPopupDisabled(false);
                                                }
                                                setTwoButtonText(`Do you want to DELETE this Sub Client ID ${user.userId}`);
                                                setTwoButtonDisabled(false);
                                            }} />
                                    </div>
                                    <div className='w-[48%]'>
                                    {user.isBlocked ? (
                                            <DeleteButtonRed 
                                                value='Unblock' 
                                                bgColor='financeSideBoxText' 
                                                btnWidth='full' 
                                                onClick={() => {
                                                    twoButtonHandler.current = async () => {
                                                        const success = await handleReqOtp();
                                                        if(!success) return;

                                                        otpOkHandler.current = async (otp, reason) => {
                                                            const success = await handleUnblockSubClient(user, otp, reason); //Main function
                                                            if(success) user.isBlocked = false; 

                                                            return success;
                                                        }
                                                        setOtpPopupText(`UNBLOCK ${user.userId}`)
                                                        setOtpPopupDisabled(false);
                                                    }
                                                    setTwoButtonText(`Do you want to UNBLOCK this Sub Client ID ${user.userId}`);
                                                    setTwoButtonDisabled(false);
                                                }}  />
                                        ) : (
                                            <DeleteButtonRed
                                                value='Block' 
                                                bgColor='financeSideBoxText' 
                                                btnWidth='full' 
                                                onClick={() => {
                                                    twoButtonHandler.current = async () => {
                                                        const success = await handleReqOtp();
                                                        if(!success) return;
                                                        
                                                        otpOkHandler.current = async (otp, reason) => {
                                                            const success = await handleBlockSubClient(user, otp, reason); //Main function
                                                            if(success) user.isBlocked = true; 

                                                            return success;
                                                        }
                                                        setOtpPopupText(`BLOCK ${user.userId}`)
                                                        setOtpPopupDisabled(false);
                                                    }
                                                    setTwoButtonText(`Do you want to BLOCK this Sub Client ID ${user.userId}`);
                                                    setTwoButtonDisabled(false);
                                                }}  />
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                        ))}
                        {
                            subClients && subClients.length === 0 &&
                            <div className='h-10 flex justify-center items-center'>
                                <p className='text-4xl'>No Sub clients Data Found</p>
                            </div>
                        }
                    </div>
                </div>

                <div className='w-[100%] flex justify-between my-10'>
                    <div className='w-[25rem]'>
                        <SubmitButton value='Previous' btnWidth='full' disabled={(subClients && subClients.length === 0) || currentPageNo === 1} onClick={() => {if(currentPageNo > 1) setCurrentPageNo(currentPageNo - 1)}} />
                    </div>
                    <div className='w-[25rem]'>
                        <SubmitButton value='Next' btnWidth='full' disabled={(subClients && subClients.length === 0) || currentPageNo >= maxPageNo} onClick={() => {if(currentPageNo < maxPageNo) setCurrentPageNo(currentPageNo + 1)}} />
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default DeleteClient