import axiosInstance from "../axios/axios";
import { handleError } from "../utils/handles/errorHandle";

async function requestOtp (axiosPrivate) {
    try{
        const response = await axiosPrivate.post(`/api/client/send-otp`);

        console.log(response.data)
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function resendOtp (emailId) {
    try{
        const response = await axiosInstance.post(`/otp/resend`, {
            type: 'email',
            emailId
        });

        return response?.data;
    }catch(error){
        handleError(error);
    }
}

export {
    requestOtp,
    resendOtp
}