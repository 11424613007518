import { React, useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence, MotionConfig } from 'framer-motion';

import { FormHeadingComponent, FormSubHeadingComponent } from '../../../../../components/ui/formSection';
import FormikControl from '../../../../../components/form/formikControl';
import { SubmitButtonWide } from '../../../../../components/ui/buttons/submitButton'
import Loader from '../../../../../components/ui/Loader';
import TextPopup from '../../../../../components/popups/textPopup';

import { warrantyDropDown } from "../../../../../utils/QR/batchDropdowns";
import initialValues from '../utils/manuInitialValues';
import validationSchema from '../validations/manuValidations';

import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate'
import { editSKU, getSkuIdsByBatchType, getSkuData } from '../../services/api';
import axios from 'axios';

import PopUpManager from '../../../../../components/popups2/popupManager';
import useAuth from '../../../../../hooks/useAuth';
import { URL_TYPES } from '../../../../../config/constants';

function CreateManuSku(props) {

    const clientid = props.clientId;
    const batchType = props.batchType;
    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR)
    const navigation = useNavigate()
    const { auth } = useAuth();

    const [clickedSkuDetails, setclickedSkuDetails] = useState(true)
    const [clickedProductDetails, setclickedProductDetails] = useState(true)
    const [clickedManufacturingDetails, setclickedManufacturingDetails] = useState(true)
    const [clickedSkuDetailsSub, setclickedSkuDetailsSub] = useState(false)
    const [clickedProductDetailsSub, setclickedProductDetailsSub] = useState(false)
    const [clickedManufacturingsub, setclickedManufacturingsub] = useState(false)
    const [loading, setLoading] = useState(false);

    const [skuIds, setSkuIds] = useState([]);
    const [skuData, setSkuData] = useState(initialValues);
    const [newSkuId, setNewSkuId] = useState();
    
    const [submitPopupController, setSubmitPopupController] = useState(false)
    const [erorPopupController, setErrorPopupController] = useState(false)
    const [errorPopupText, setErrorPopupText] = useState('Something went wrong');

    const handleClickSkudetails = (event) => {
        setclickedSkuDetails(!event)
        setclickedSkuDetailsSub(false)
    }
    const handleClickProductDetails = (event) => {
        setclickedProductDetails(!event)
        setclickedProductDetailsSub(false)
    }
    const handleClickManufacturingDetails = (event) => {
        setclickedManufacturingDetails(!event)
        setclickedManufacturingsub(false)
    }
    const handleClickSkuDetailsSub = (event) => {
        setclickedSkuDetailsSub(!event)
    }
    const handleClickProductDetailsSub = (event) => {
        setclickedProductDetailsSub(!event)
    }
    const handleClickManufacturingsub = (event) => {
        setclickedManufacturingsub(!event)
    }

    const openAllTabs = () => {
        setclickedSkuDetails(true)
        setclickedProductDetails(true)
        setclickedManufacturingDetails(true)
        setclickedSkuDetailsSub(true)
        setclickedProductDetailsSub(true)
        setclickedManufacturingsub(true)
    }

    useEffect(() => {
        setTimeout(() => {
            setclickedSkuDetailsSub(true);
            setclickedProductDetailsSub(true);
            setclickedManufacturingsub(true);
        }, 300)
    }, [])

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const data = await getSkuIdsByBatchType(clientid, 'Manufacturer',axiosPrivate);

                setSkuIds(data);
            } catch (error) {
                setErrorPopupText(error.message);
                setErrorPopupController(true);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const reqSkuData = async (skuid) => {
        if (skuid) {
            try {
                setLoading(true)
                const data = await getSkuData(clientid, skuid, axiosPrivate);
    
                setSkuData({ ...data[0], "warrantyApp": data[0].warrantyApp ? data[0].warrantyApp.toString() : "" });
            } catch (error) {
                setErrorPopupText(error.message);
                setErrorPopupController(true);
            } finally {
                setLoading(false)
            }
        }
    }

    const handleSubmit = async (values) => {
        const formData = new FormData();

        formData.append('action', 'Edit');
        formData.append("uploadType","SKU")
        Object.keys(values).map((key) => {
            formData.append(key, values[key]);
        })

        //Make a Api call here to create a new SKU
        try{
            setLoading(true)
            await editSKU(clientid,values.skuId,formData,axiosPrivate)
            
            setSubmitPopupController(true);
        }catch(error){
            setErrorPopupText(error.message);
            setErrorPopupController(true)
        } finally {
            setLoading(false)
        }
    }

    const animationSetUp = {
        initial: { height: 0, opacity: 0, overflow: 'hidden' },
        animate: { height: 'auto' , opacity: 1 },
        exit: { height: 0, opacity: 0},
    }

    return (
        <> 
            { loading && <Loader /> }
            <PopUpManager 
                control = {'text'}
                disable = {!erorPopupController}
                heading = {`Error`}
                description = {errorPopupText}
                onClose = {() => {
                    setErrorPopupController(false)
                }}
            />

            <PopUpManager 
                control = {'one-button'}
                disable = {!submitPopupController}
                heading = {`SkuId Edited Succefully`}
                description = {`SkuID '${newSkuId}' edited succefully`}
                onClose = {() => {
                    setSubmitPopupController(false)
                    window.location.reload(false)
                }}
                okButtonText = {'Generate Qrs'}
                clickedOk = {() => {
                    navigation(`/new-batch-details`)
                }}
            />

            <Formik
                onSubmit={handleSubmit}
                enableReinitialize={true}
                initialValues={skuData || initialValues}
                validationSchema={validationSchema}
            >
                {
                    formik => {
                        formik.values.clientId = clientid;
                        return (
                            <Form>
                                <MotionConfig
                                    transition={{
                                        duration: 0.25,
                                        ease: 'easeInOut'
                                    }}
                                >
                                <div className='pt-10'>
                                    <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                        <div onClick={() => handleClickSkudetails(clickedSkuDetails)}>
                                            <FormHeadingComponent name={"SKU Name/ID"} clicked={clickedSkuDetails} />
                                        </div>
                                        <AnimatePresence>
                                        {
                                            clickedSkuDetails &&
                                            <motion.div 
                                                {...animationSetUp}
                                                className='bg-bgWhite rounded-b-[12px]'
                                            >
                                                <div onClick={() => handleClickSkuDetailsSub(clickedSkuDetailsSub)}>
                                                    <FormSubHeadingComponent name={"Provide a name for SKU you are about to create"} clicked={clickedSkuDetailsSub} />
                                                </div>
                                                <AnimatePresence>
                                                {
                                                    clickedSkuDetailsSub &&
                                                    <motion.div 
                                                        {...animationSetUp}
                                                    >
                                                    <div className='bg-bgWhite pb-10 border-solid border-navBorder rounded-b-[12px] w-1/2 pl-14 '>
                                                        <FormikControl
                                                            control='select'
                                                            options={skuIds}
                                                            label='SKU ID'
                                                            name='skuId'
                                                            bgcolor={'selectorBg'}
                                                            onChange={(e) => {
                                                                formik.resetForm();

                                                                formik.setFieldValue('skuId', e.target.value)
                                                                reqSkuData(e.target.value)
                                                                setNewSkuId(e.target.value)
                                                            }}
                                                            height={'[46px]'}
                                                            padX={'3'}
                                                            radius={'[5px]'}
                                                        />
                                                    </div>
                                                    </motion.div>
                                                }
                                                </AnimatePresence>
                                            </motion.div>
                                        }
                                        </AnimatePresence>
                                    </div>

                                    <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                        <div onClick={() => handleClickProductDetails(clickedProductDetails)}>
                                            <FormHeadingComponent name={"Product Details"} clicked={clickedProductDetails} />
                                        </div>
                                        <AnimatePresence>
                                        {
                                            clickedProductDetails &&
                                            <motion.div 
                                                {...animationSetUp}
                                                className='bg-bgWhite rounded-b-[12px]'
                                            >
                                                <div className='rounded-b-[12px]'>
                                                    <div onClick={() => handleClickProductDetailsSub(clickedProductDetailsSub)}>
                                                        <FormSubHeadingComponent name={"Provide Product Details"} clicked={clickedProductDetailsSub} />
                                                    </div>
                                                </div>
                                                <AnimatePresence>
                                                {
                                                    clickedProductDetailsSub &&
                                                    <motion.div {...animationSetUp}>
                                                    <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10'>
                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            label={"Product Image :"}
                                                            name='product-image'
                                                            defaultImageUrl={formik.values.Pimage}
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            blobRequired={false}
                                                            onFileSelect={(image) => {
                                                                formik.setFieldValue('pimage', image)
                                                            }}
                                                        />

                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            label={"Expiry Product :"}
                                                            name={'expiry-qr-on-prod'}
                                                            defaultImageUrl={formik.values.expiryImage}
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            blobRequired={false}
                                                            onFileSelect={(image) => {
                                                                formik.setFieldValue('expiryImage', image)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10'>
                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            label={"Hidden QR on Product :"}
                                                            name={'hiddend-qr-on-prod'}
                                                            defaultImageUrl={formik.values.QROnprod}
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            blobRequired={false}
                                                            onFileSelect={(image) => {
                                                                formik.setFieldValue('QROnprod', image)
                                                            }}
                                                        />
                                                        
                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            label={"Outer QR on Box :"}
                                                            name={'outer-qr-on-box'}
                                                            defaultImageUrl={formik.values.preSaleQRLocation}
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            blobRequired={false}
                                                            onFileSelect={(image) => {
                                                                formik.setFieldValue('preSaleQRLocation', image)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='grid grid-cols-2 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 rounded-b-[12px]'>
                                                        <FormikControl control='input' type='text' label={"Brand :"} name={'brand'} bgcolor={'selectorBg'} />
                                                        <FormikControl control='input' type='text' label={"Product Name :"} name={"prodName"} bgcolor={'selectorBg'} />
                                                        <FormikControl control='input' type='number' label={"MRP. :"} name={"mrp"} bgcolor={'selectorBg'} />
                                                        <FormikControl control='input' type='text' label={"Serial No. :"} name={"serialNo"} bgcolor={'selectorBg'} />
                                                        <FormikControl control='input' type='number' label={"Shelf Life in days:"} name={"shelfLife"} bgcolor={'selectorBg'} />

                                                        {
                                                            // formik.values.type !== '1' && 
                                                            <>
                                                                <FormikControl
                                                                    control='select'
                                                                    label='Warranty Applicable: '
                                                                    name='warrantyApp'
                                                                    options={warrantyDropDown}
                                                                    bgcolor={'selectorBg'}
                                                                    height={'[46px]'}
                                                                    radius={'[5px]'}
                                                                    padX={'4'}
                                                                    value={formik.values.warrantyApp}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === "false") {
                                                                            formik.setFieldValue('warrantyPeriod', '')
                                                                            formik.setFieldValue('warrantyTC', '')
                                                                        }
                                                                        formik.setFieldValue('warrantyApp', e.target.value)
                                                                    }}
                                                                />
                                                                {
                                                                    formik.values.warrantyApp === "true" &&
                                                                    <FormikControl control='input' type='number' label={"Warranty in days: "} bgcolor={'selectorBg'} name={"warrantyPeriod"}
                                                                        disabled={formik.values.warrantyApp === "true" ? false : true}
                                                                    />

                                                                }
                                                                {
                                                                    formik.values.warrantyApp === "true" && <div className='col-span-full md:col-span-2'>
                                                                        <FormikControl control='input-textarea-large' type='text' label={"Warranty T&C"} name={"warrantyTC"} bgcolor={'selectorBg'} disabled={formik.values.warrantyApp === "true" ? false : true} />
                                                                    </div>
                                                                }
                                                            </>
                                                        }

                                                    </div>
                                                    </motion.div>
                                                }
                                                </AnimatePresence>
                                            </motion.div>
                                        }
                                        </AnimatePresence>
                                    </div>

                                    <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                        <div onClick={() => { handleClickManufacturingDetails(clickedManufacturingDetails) }}>
                                            <FormHeadingComponent name={"Manufacturing Details"} clicked={clickedManufacturingDetails} />
                                        </div>
                                        <AnimatePresence>
                                        {
                                            clickedManufacturingDetails &&
                                            <motion.div 
                                                {...animationSetUp}
                                                className='bg-bgWhite rounded-[12px]'
                                            >
                                                <div onClick={() => handleClickManufacturingsub(clickedManufacturingsub)}>
                                                    <FormSubHeadingComponent name={"Provide Manufacturing Details "} clicked={clickedManufacturingsub} />
                                                </div>
                                                <AnimatePresence>
                                                {
                                                    clickedManufacturingsub &&
                                                    <motion.div {...animationSetUp}>
                                                    <div className='px-[30px] pb-20 grid grid-cols-1 gap-y-10 rounded-[12px] qr-manufacturing-details'>
                                                        <FormikControl
                                                            control='input-textarea-small'
                                                            width={'full'}
                                                            type='description'
                                                            label={"Manufacturing license no :"}
                                                            name={'manuLicenseNo'}
                                                            placeholder={"Type your text here"} />
                                                        <FormikControl
                                                            control='input-textarea-large'
                                                            width={'full'}
                                                            type='description'
                                                            label={"Manufacturing Address :"}
                                                            name={'manuAdd'}
                                                            placeholder={"Type your text here"} />
                                                        <FormikControl
                                                            control='input-textarea-large'
                                                            type='description'
                                                            width={'full'}
                                                            label={"Additional Details :"}
                                                            name={'addDetails'}
                                                            placeholder={"Type your text here"} />
                                                        <FormikControl
                                                            control='input-textarea-small'
                                                            type='description'
                                                            width={'full'}
                                                            label={"Image or Video Header :"}
                                                            name={'additionalImageDetails'}
                                                            placeholder={"image header"}
                                                        />
                                                        <div className='flex flex-wrap md:flex-nowrap mb-[25px] md:mb-0 items-center h-[100%] gap-5 generate-img'>
                                                            <div className='generate-img-upload-wrapper w-[100%]'>
                                                                <div className='flex sm:flex-col md:flex-row flex-wrap w-[100%]'>
                                                                    <FormikControl
                                                                        control='file'
                                                                        type='file'
                                                                        label={""}
                                                                        name='extra-Image-1'
                                                                        defaultImageUrl={formik.values.extraImage1}
                                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                                        blobRequired={false}
                                                                        onFileSelect={(image) => {
                                                                            formik.setFieldValue('extraimage1', image)
                                                                        }}
                                                                        pdY={'1rem'}
                                                                    />

                                                                    <FormikControl
                                                                        control='file'
                                                                        type='file'
                                                                        label={""}
                                                                        name='extra-Image-2'
                                                                        defaultImageUrl={formik.values.extraImage2}
                                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                                        blobRequired={false}
                                                                        onFileSelect={(image) => {
                                                                            formik.setFieldValue('extraimage2', image)
                                                                        }}
                                                                        pdY={'1rem'}
                                                                    />

                                                                     <FormikControl
                                                                        control='video'
                                                                        type='file'
                                                                        label={""}
                                                                        name='reta-extra-video-1'
                                                                        defaultVideoUrl={formik.values.prodVedioLink}
                                                                        acceptableFiles='.mp4'
                                                                        blobRequired={false}
                                                                        onFileSelect={(url) => {
                                                                            formik.setFieldValue("prodVedioLink", url);
                                                                        }} 
                                                                        pdY={'1rem'}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <FormikControl
                                                            control='input-textarea-small'
                                                            type='description'
                                                            width={'full'}
                                                            label={"Website Link :"}
                                                            name={'manuWebsiteLink'}
                                                            placeholder={"Type your text here"} />
                                                    </div>
                                                    </motion.div>
                                                }
                                                </AnimatePresence>
                                            </motion.div>
                                        }

                                        </AnimatePresence>
                                    </div>

                                    <div className='h-40 pt-[15px]'>
                                        <SubmitButtonWide value={"Edit"} type={'submit'} onClick={openAllTabs} />
                                    </div>
                                </div>
                                </MotionConfig>
                            </Form>
                        )
                    }
                }

            </Formik>
        </>
    )
}

export default CreateManuSku;