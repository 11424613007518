import profile from '../../../assets/svg/sidebar_icons_3/profile_icon.svg'

const sideBarData = [
    {
        name:"view-customer",
        img:profile,
        desc:"Profile"
    },
]

export default sideBarData