import { React, useState, useEffect } from 'react'
import { Formik, Form } from 'formik';
import { motion, AnimatePresence, MotionConfig } from 'framer-motion';

import FormikControl from '../../../../../components/form/formikControl';
import Loader from '../../../../../components/ui/Loader';
import { FormHeadingComponent, FormSubHeadingComponent } from '../../../../../components/ui/formSection';
import { SubmitButtonWide } from '../../../../../components/ui/buttons/submitButton';
import PopUpManager from '../../../../../components/popups2/popupManager';

import retaIntialValues from '../utils/retaInitialValues';
import retaValidationSchema from '../validations/retaValidations';

import initialValues from '../utils/manuInitialvalues';

import {
    getBatchData,
    updateBatchDetails
} from '../../services/api';
import { getSkuIdsByBatchType, getSkuData } from '../../../sku/services/api';


import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';
import { URL_TYPES } from '../../../../../config/constants';

function RetaBatchEdit(props) {
    
    const navigate = useNavigate();
    const { clientid, batchno, rawBatchData} = props;
    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR);

    const [clickedSkuDetails, setclickedSkuDetails] = useState(true)
    const [clickedProductDetails, setclickedProductDetails] = useState(true)
    const [clickedManufacturingDetails, setclickedManufacturingDetails] = useState(true)

    const [clickedSkuDetailsSub, setclickedSkuDetailsSub] = useState(false)
    const [clickedProductDetailsSub, setclickedProductDetailsSub] = useState(false)
    const [clickedManufacturingsub, setclickedManufacturingsub] = useState(false)

    const [initialData, setInitialData] = useState({});
    const [batchData, setBatchData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [oneButtonPopupnDisbled, setOneButtonPopupDisabled] = useState(true);
    const [alertText, setAlertText] = useState("");
    const [textPopupDisabled, setTextPopupDisabled] = useState(true);

    const [skuIds, setSkuIds] = useState([]);
    const [slectedSku_Id, setSelectedSku_Id] = useState('')

    const handleClickSkudetails = (event) => {
        setclickedSkuDetails(!event)
        setclickedSkuDetailsSub(false)
    }
    const handleClickProductDetails = (event) => {
        setclickedProductDetails(!event)
        setclickedProductDetailsSub(false)
    }
    const handleClickManufacturingDetails = (event) => {
        setclickedManufacturingDetails(!event)
        setclickedManufacturingsub(false)
    }
    const handleClickSkuDetailsSub = (event) => {
        setclickedSkuDetailsSub(!event)
    }
    const handleClickProductDetailsSub = (event) => {
        setclickedProductDetailsSub(!event)
    }
    const handleClickManufacturingsub = (event) => {
        setclickedManufacturingsub(!event)
    }

    const openAllTabs = () => {
        setclickedSkuDetails(true)
        setclickedProductDetails(true)
        setclickedManufacturingDetails(true)
        setclickedSkuDetailsSub(true)
        setclickedProductDetailsSub(true)
        setclickedManufacturingsub(true)
    }

    useEffect(() => {
        setTimeout(() => {
            setclickedSkuDetailsSub(true);
            setclickedProductDetailsSub(true);
            setclickedManufacturingsub(true);
        }, 300)
    }, [])

    const handleSubmit = async (values) => {
        let batchData = Object.assign({}, values);
        batchData.clientId = clientid;
        batchData.batchNo = batchno;
        batchData.uploadType  = "EDIT_BATCH";
        batchData.type = rawBatchData.type;

        if (batchData.type === 1 || batchData.warrantyApp === "false" || !batchData.warrantyApp) {
            batchData.warrantyApp = false;
            batchData.warrantyPeriod = 0;
        } else {
            batchData.warrantyApp = true;
        }

        if (batchData.type !== 3) {
            batchData.QROnprod = '';
            batchData.preSaleQRLocation = '';
        }
        if(slectedSku_Id) batchData.skuId = slectedSku_Id;
        
        let formData = new FormData();
        for (var key in batchData) {
            if(batchData[key] !== initialData[key] && key !== '_id')
                formData.append(key, batchData[key]);
        }
        formData.append("clientId",clientid);
        formData.append("batchNo" ,batchno);
        formData.append("uploadType","EDIT_BATCH");

        try {
            setLoading(true);
            await updateBatchDetails(clientid,formData, axiosPrivate);
            
            setOneButtonPopupDisabled(false);
        } catch (error) {
            setAlertText(error.message || "Unable to update batch");
            setTextPopupDisabled(false);
        } finally {
            setLoading(false);
        }
    }

    const reqSkuData = async (formik, skuid) => {
        if (skuid) {
            try {
                setLoading(true)
                const data = await getSkuData(clientid, skuid, axiosPrivate);
                
                Object.keys(data[0]).forEach(key => {
                    formik.setFieldValue(key, data[0][key]);
                })
            } catch (error) {
                setAlertText(error.message || "Unable to load Sku data");
                setTextPopupDisabled(false);
            } finally {
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                console.log(clientid)
                const data = await getSkuIdsByBatchType(clientid, rawBatchData.batchType, axiosPrivate);

                setSkuIds(data)
            } catch (error) {
                setAlertText(error.message || "Unable to load SkuIDs");
                setTextPopupDisabled(false);
            } finally {
                setLoading(false)
            }
        })();
    }, []);

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                console.log("moo")
                const result = await getBatchData(clientid, batchno, axiosPrivate);

                const data = {
                    ...initialValues,
                    ...result,
                }

                setBatchData(data);
                setInitialData(data);
                setSelectedSku_Id(data.skuId);
            } catch (error) {
                setAlertText(error.message || "Unable to load batch data");
                setTextPopupDisabled(false);
            } finally {
                setLoading(false);
            }
        }
        getData();
    }, [clientid, batchno]);

    const animationSetUp = {
        initial: { height: 0, opacity: 0, overflow: 'hidden' },
        animate: { height: 'auto' , opacity: 1 },
        exit: { height: 0, opacity: 0},
    }

    return (
        <>
            <PopUpManager 
                control = {'text'}
                disable = {textPopupDisabled}
                heading = {`Error`}
                description = {alertText}
                onClose = {() => {
                    setTextPopupDisabled(true)
                }}
            />
            <PopUpManager 
                control={'one-button'}
                disable= {oneButtonPopupnDisbled}
                heading={`Info`}
                description={`${batchno} is succefully edited`}
                onClose={() => setOneButtonPopupDisabled(true)}
                okButtonText={"Generate Qr's"}
                clickedOk={() => navigate('/new-batch-details')}
            />
            {
                loading && <Loader />
            }
            <Formik
            initialValues={batchData || retaIntialValues} 
            enableReinitialize={true}
            validationSchema={retaValidationSchema}
            onSubmit={handleSubmit}
            > 
            {
                formik => {
                    return (
                        <Form>
                            <MotionConfig
                                transition={{
                                    duration: 0.25,
                                    ease: 'easeInOut'
                                }}
                            >
                            {
                                skuIds && Array.isArray(skuIds) && skuIds.length > 1 && <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                    <div onClick={() => handleClickSkudetails(clickedSkuDetails)}>
                                        <FormHeadingComponent name={"SKU Details"} clicked={clickedSkuDetails} />
                                    </div>
                                    <AnimatePresence>
                                    {
                                        clickedSkuDetails &&
                                        <motion.div 
                                            {...animationSetUp} 
                                            className='bg-bgWhite rounded-b-[12px]'
                                        >
                                            <div onClick={() => handleClickSkuDetailsSub(clickedSkuDetailsSub)}>
                                                <FormSubHeadingComponent name={"Select the sku id from drop down"} clicked={clickedSkuDetailsSub} />
                                            </div>
                                            <AnimatePresence>
                                            {
                                                clickedSkuDetailsSub &&
                                                <motion.div 
                                                    {...animationSetUp} 
                                                >
                                                <div className='bg-bgWhite border-solid border-navBorder rounded-b-[12px] w-1/2 pl-14 pb-6 dropdown-icon'>
                                                    <FormikControl
                                                        control='select'
                                                        label='SKU ID'
                                                        name='skuId'
                                                        options={skuIds}
                                                        bgcolor={'selectorBg'}
                                                        onChange={(e) => {
                                                            const _skuId = e.target.value;
                                                            formik.setFieldValue('skuId', _skuId)
                                                            reqSkuData(formik, _skuId)

                                                            skuIds.forEach(sku => {
                                                                if(sku.value === _skuId) {
                                                                    console.log(sku._id)
                                                                    setSelectedSku_Id(sku._id);
                                                                }
                                                            });
                                                        }}
                                                        height={'[46px]'}
                                                        radius={'[5px]'}
                                                    />
                                                </div>
                                                </motion.div>
                                            }
                                            </AnimatePresence>
                                        </motion.div>
                                    }
                                    </AnimatePresence>
                                </div>
                            }
                            <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                <div onClick={() => handleClickProductDetails(clickedProductDetails)}>
                                    <FormHeadingComponent name={"Product Details"} clicked={clickedProductDetails} />
                                </div>
                                <AnimatePresence>
                                {
                                    clickedProductDetails &&
                                    <motion.div 
                                        {...animationSetUp} 
                                        className='w-full bg-bgWhite rounded-b-[12px]'
                                    >
                                        <div onClick={() => handleClickProductDetailsSub(clickedProductDetailsSub)}>
                                            <FormSubHeadingComponent name={"Provide Product Details"} clicked={clickedProductDetailsSub} />
                                        </div>
                                        <AnimatePresence>
                                        {clickedProductDetailsSub &&
                                            <motion.div 
                                                {...animationSetUp} 
                                            >
                                                <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10'>
                                                    <FormikControl
                                                        control='file'
                                                        type='file'
                                                        defaultImageUrl={formik.values.Pimage}
                                                        label={"Product Image :"}
                                                        name='Product-image'
                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                        blobRequired={false}
                                                        customerror={formik.touched.Pimage && formik.errors.Pimage}
                                                        onFileSelect={(url) => {
                                                            if(url)
                                                                formik.setFieldValue("Pimage", url);
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    batchData.type == "3" && <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10'>
                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            defaultImageUrl={formik.values.QROnprod}
                                                            label={"Hidden QR on Product :"}
                                                            name={'hiddend-qr-on-prod'}
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            blobRequired={false}
                                                            customerror={formik.touched.QROnprod && formik.errors.QROnprod}
                                                            onFileSelect={(url) => {
                                                                formik.setFieldValue("QROnprod", url);
                                                            }}
                                                        />

                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            defaultImageUrl={formik.values.preSaleQRLocation}
                                                            label={"Hidden QR on Product :"}
                                                            name={'hiddend-qr-on-prod'}
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            blobRequired={false}
                                                            customerror={formik.touched.preSaleQRLocation && formik.errors.preSaleQRLocation}
                                                            onFileSelect={(url) => {
                                                                formik.setFieldValue("preSaleQRLocation", url);
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 rounded-b-[12px]'>
                                                    <FormikControl control='input' type='text' label={"Sold by :"} name={"prodName"} bgcolor={'selectorBg'} />
                                                    <FormikControl control='input' type='text' label={"Batch Number :"} name={"batchNo"} bgcolor={'selectorBg'} disabled={true} />
                                                </div>
                                            </motion.div>
                                        }
                                        </AnimatePresence>
                                    </motion.div>
                                }
                                </AnimatePresence>
                            </div>
                            <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                <div onClick={() => { handleClickManufacturingDetails(clickedManufacturingDetails) }}>
                                    <FormHeadingComponent name={"Manufacturing Details"} clicked={clickedManufacturingDetails} />
                                </div>
                                <AnimatePresence>
                                {
                                    clickedManufacturingDetails &&
                                    <motion.div 
                                        {...animationSetUp} 
                                        className='bg-bgWhite rounded-b-[12px]'
                                    >
                                        <div onClick={() => handleClickManufacturingsub(clickedManufacturingsub)}>
                                            <FormSubHeadingComponent name={"Provide Manufacturing Details "} clicked={clickedManufacturingsub} />
                                        </div>
                                        <AnimatePresence>
                                        {
                                            clickedManufacturingsub &&
                                            <motion.div 
                                                {...animationSetUp} 
                                            >
                                            <div className='px-[30px] pb-20 grid grid-cols-1 gap-y-10 rounded-[12px] qr-manufacturing-details'>
                                                <FormikControl
                                                    control='input-textarea-small'
                                                    width={'full'}
                                                    type='description'
                                                    label={"License no :"}
                                                    name={'manuLicenseNo'}
                                                    placeholder={"Type your text here"} />
                                                <FormikControl
                                                    control='input-textarea-large'
                                                    width={'full'}
                                                    type='description'
                                                    label={"Address :"}
                                                    name={'manuAdd'}
                                                    placeholder={"Type your text here"} />
                                                <FormikControl
                                                    control='input-textarea-large'
                                                    width={'full'}
                                                    type='description'
                                                    label={"Details :"}
                                                    name={'addDetails'}
                                                    placeholder={"Type your text here"} />
                                                <FormikControl
                                                    control='input-textarea-small'
                                                    width={'full'}
                                                    type='description'
                                                    label={"Image or Video Header :"}
                                                    name={'additionalImageDetails'}
                                                    placeholder={"image header"}
                                                />
                                                <div className='flex flex-wrap md:flex-nowrap mb-[25px] md:mb-0 items-center h-[100%] gap-5 generate-img'>
                                                    <div className='generate-img-upload-wrapper w-[100%]'>
                                                        <div className='flex sm:flex-col md:flex-row flex-wrap w-[100%]'>
                                                            <FormikControl
                                                                control='file'
                                                                type='file'
                                                                label={""}
                                                                name='extra-Image-1'
                                                                defaultImageUrl={formik.values.extraImage1}
                                                                acceptableFiles='.jpg,.png,.jpeg'
                                                                blobRequired={false}
                                                                customerror={formik.touched.extraImage1 && formik.errors.extraImage1}
                                                                onFileSelect={(url) => {
                                                                    formik.setFieldValue("extraImage1", url);
                                                                }}
                                                                pdY={'1rem'}
                                                            />
                                                            <FormikControl
                                                                control='file'
                                                                type='file'
                                                                label={""}
                                                                name='extra-Image-2'
                                                                defaultImageUrl={formik.values.extraImage2}
                                                                acceptableFiles='.jpg,.png,.jpeg'
                                                                blobRequired={false}
                                                                customerror={formik.touched.extraImage2 && formik.errors.extraImage2}
                                                                onFileSelect={(url) => {
                                                                    formik.setFieldValue("extraImage2", url);
                                                                }}
                                                                pdY={'1rem'}
                                                            />
                                                            <FormikControl
                                                                control='video'
                                                                type='file'
                                                                label={""}
                                                                name='extra-video-1'
                                                                defaultVideoUrl={formik.values.prodVedioLink}
                                                                acceptableFiles='.mp4'
                                                                blobRequired={false}
                                                                customerror={formik.touched.prodVedioLink && formik.errors.prodVedioLink}
                                                                onFileSelect={(url) => {
                                                                    formik.setFieldValue("prodVedioLink", url);
                                                                }}
                                                                pdY={'1rem'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <FormikControl
                                                    control='input-textarea-small'
                                                    type='description'
                                                    label={"Website Link :"}
                                                    name={'manuWebsiteLink'}
                                                    placeholder={"Type your text here"} />
                                            </div>
                                            </motion.div>
                                        }
                                        </AnimatePresence>
                                    </motion.div>
                                }
                                </AnimatePresence>
                            </div>
                            <div className='h-40'>
                                <SubmitButtonWide value={"Process"} type={'submit'} onClick={openAllTabs} />
                            </div>
                            </MotionConfig>
                        </Form>
                    )
                }
            }
        </Formik>
        </>
    )
}

export default RetaBatchEdit;