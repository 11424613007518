import { handleError } from "../../../../utils/handles/errorHandle";

async function fetchCustomerPurchases(masterClientObjectId, customerId, pageNumber, pageSize, last30Days, years, other, axiosPrivate){
    console.log(masterClientObjectId, customerId, pageNumber, pageSize, last30Days, years, other) 
    try{
        const response = await axiosPrivate.get(`/api/customer/purchase-history/${masterClientObjectId}/${customerId}/paginated`,{
            params: {
                pageNumber,
                pageSize,
                last30Days, 
                years: JSON.stringify(years), 
                other
            }
        });

        return response?.data?.purchaseHistory;
    }catch(error){
        handleError(error);
    }
} 

export{
    fetchCustomerPurchases
}