import React, { useEffect, useState } from 'react';
import { Field, ErrorMessage } from 'formik'
import TextError from './textError'

import removedExifFile from '../../utils/files/exifRemover';

function ImageUpload(props) {
  const { imageFile, label, name, onFileSelect, blobRequired = true, bgcolor, defaultImageUrl, customerror, pdX = 0, pdY = 0, fileSizeLimit = 250, ...rest } = props

  const [selectedFile, setSelectedFile] = useState(null);
  const [isItImage, setIsItImage] = useState(false);
  const [fileName, setFileName] = useState(null);

  useEffect(() => {
    if(!(defaultImageUrl instanceof File))
      setSelectedFile('');
  }, [defaultImageUrl])
  
  const handleFileChange = (event) => {
    let file = event.target.files[0];
    if(!file) return;

    const MAX_FILE_SIZE = fileSizeLimit * 1024;
    if(file.size > MAX_FILE_SIZE) {
      alert(`File size should be less than ${fileSizeLimit}KB`);
      return;
    }

    setIsItImage(isItImageFunc(file));
    setFileName(file.name);
    file = new File([file], `${Math.floor(Math.random()*1000000)}.${file.type.split('/')[1]}`, { type: file.type });

    if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
      console.log('woorking goood man!!');

      removedExifFile(file).then((modifiedFile) => {
        if (onFileSelect !== undefined) {
          if(blobRequired) {
            const fileUrl = URL.createObjectURL(modifiedFile);
            setSelectedFile(fileUrl); // Create a URL for the selected file
            onFileSelect(fileUrl);
          }
          else {
            setSelectedFile(modifiedFile);
            onFileSelect(modifiedFile);
          }
        }
      });
    } else {
      if (onFileSelect !== undefined) {
        if(blobRequired) {
          const fileUrl = URL.createObjectURL(file);
          setSelectedFile(fileUrl); // Create a URL for the selected file
          onFileSelect(fileUrl);
        }
        else {
          setSelectedFile(file);
          onFileSelect(file);
        }
      }
    }
  };

  return (
    <div style={{padding: `${pdY} ${pdX}`}} className='mb-8'>
      <div className='md:flex justify-start items-center'>
        {
          label && <label htmlFor={name} className='text-sideBarBtnText text-font16 w-[40%]'>
            {label}
          </label>
        }
        <div className={`file-img-conatiner flex items-center gap-7 ${label? 'w-[60%]' : 'w-full'}`}>
          <div className={`w-[80%] p-3 h-[66px] bg-${bgcolor || 'selectorBg'} rounded-[5px] `}>
            {
              !selectedFile && !isItImage && !fileName && !defaultImageUrl && 
              <div className='h-full w-full flex justify-center items-center'>
                  <p className='text-3xl px-4'>Please Upload an Image</p>
              </div>
            }
            {isItImage && selectedFile ? 
              <img src={imageFile? URL.createObjectURL(imageFile): (blobRequired? selectedFile: URL.createObjectURL(selectedFile))} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '100%', width: '100%', height: 'auto', objectFit: 'cover' }} />
            : (defaultImageUrl? <img src={defaultImageUrl} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '100%', width: '100%', height: 'auto', objectFit: 'cover' }} /> : !isItImage && selectedFile ? <div style={{ maxWidth: '100%', maxHeight: '100%', width: '100%', height: 'auto', objectFit: 'cover', overflow: 'hidden', textOverflow: 'clip' , textAlign: 'center'}}><h6>{fileName}</h6></div> : '')}
          </div>
          <div className="input-file">
            <Field
              id={name}
              name={name}
              type='file'
              accept='image/png, image/jpeg, image/jpg'
              className="w-full"
              onChange={handleFileChange}
              {...rest}
            />
          </div>
        </div>
      </div>
      <div className={`${label && 'ml-[40%]'}`}>
        {
          customerror? 
            <TextError>{customerror}</TextError> : 
            <ErrorMessage name={name} component={TextError} />
        }
      </div>
    </div>
  )
}

function isItImageFunc (file)  {
  const name = file.name

  const data = name.split('.')
  const extension = data[data.length - 1];

  const extensions = ['png', 'jpg', 'jpeg','jpe', 'jif', 'jfif', 'jfi', 'gif','heif','heic', 'webp','tiff','tif', 'psd', 'raw','arw', 'cr2', 'nrw', 'k25','bmp','dib','indd','jp2', 'j2k', 'jpf', 'jpx', 'jpm', 'mj2']

  for(let i = 0; i < extensions.length; i++){
    if(extension === extensions[i]){
      return true;
    }
  }

  return false;
}

export default ImageUpload


