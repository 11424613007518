import React, { useRef, useState } from 'react';
import { Field } from 'formik'

const ColorInput = (props) => {

    const { name, defaultColor, onChnage, formik, ...rest } = props;
    const inpRef = useRef(null);
    const [color, setColor] = useState(defaultColor || 'black');

    return (
        <div className='w-full h-full min-h-[46px] flex items-center justify-center bg-[#F1F2FA] rounded-xl relative'>
            <Field
                id={name}
                name={name}
                type='color'
                onChange={(e) => {
                    setColor(e.target.value)
                    onChnage && onChnage(e.target.value);
                    formik && formik.setFieldValue && formik.setFieldValue(name, e.target.value);
                }}
                innerRef={inpRef}
                className="absolute top-0 left-0 right-0 bottom-0 z-10"
            />

            <div className='absolute top-0 left-0 right-0 bottom-0 bg-[#F1F2FA] flex items-center justify-center z-20'>
                <button 
                    type='button'
                    style={{
                        backgroundColor: color
                    }}
                    className='w-[95%] h-[60%]'
                    onClick={() => {
                        inpRef.current && inpRef.current.click();
                    }}    
                >

                </button>
            </div>
        </div>
    );
}

export default ColorInput;
