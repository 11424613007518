import React, { useState, useCallback, useEffect } from 'react';
import { Formik, Form } from 'formik';

import useUserData from '../../../../hooks/useUserData';
import FormikControl from '../../../../components/form/formikControl';
import { SubmitButton } from '../../../../components/ui/buttons/submitButton';
import SortingTable from '../../../../components/table/sortingTable';
import { SORT_ORDER, USER_TYPES } from '../../../../config/constants';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { getUserInfo, getUserIdSuggestion, getAllSubClientsPaginated } from '../services/api';
import PopUpManager from "../../../../components/popups2/popupManager";
// import 

const SubclientsHeaderData = [{value: 'Client ID', space: '25%', key: 'userId', sorting: 'true'}, {value: 'Created On', space: '20%', key: 'createdAt', sorting: 'true'}, {value: 'Email ID', space: '35%', key: 'emailId', sorting: 'true'}, {value: 'Created by', space: '20%', key: 'createdBy'}]

const ViewAllSubClients = () => {

    const axiosPrivate = useAxiosPrivate();
    const { user } = useUserData();

    const pageCount = 10;
    const [suggestionArray, setSuggetionArray] = useState([]);
    const [searchParam, setSearchParam] = useState('');

    //sub-clients data
    const [subClients, setSubClients] = useState([]);

    const [sortedKey, setSortedKey] = useState(SubclientsHeaderData[0].key);
    const [sortedWay, setSortedWay] = useState(SORT_ORDER.ASC);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(0)
    const [prevDisabled, setPrevDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);

    //popup
    const [tableLoading, setTableLoading] = useState(false);
    const [textPopupDisabled, setTextPopupDisabled] = useState(true);
    const [textPopupText, setTextPopupText] = useState('');

    //Sub-clients logic
    const loadSubs = useCallback(async (currentPage, sortedKey, sortedWay, searchParam) => {
        if(!user?.masterClientId) return;

        try {
            let res;
            setTableLoading(true);
            if(searchParam) 
                res = await getUserInfo(searchParam, sortedKey, sortedWay, currentPage, pageCount, axiosPrivate);
            else
                res = await getAllSubClientsPaginated(user?.masterClientId, sortedKey, sortedWay, currentPage, pageCount, axiosPrivate);
            
            const pageData = res?.pageData;
            if(!pageData) throw new Error("No data found");

            pageData.forEach(ele => {
                const date = new Date(ele.createdAt);
                ele.createdAt = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
            });
            setSubClients(res?.pageData);
            setMaxPage(Math.floor(res?.totalUsers/pageCount) + (res?.totalUsers%pageCount? 1 : 0));
        } catch (error) {
            //Popup
            setTextPopupText(error.message || `Unable to load Sub clients`);
            setTextPopupDisabled(false);
        } finally {
            setTableLoading(false);
        }
    }, [user?.masterClientId, axiosPrivate]);

    const setSubsSorting = (key, way) => {
        setSortedKey(key);
        setSortedWay(way);

        loadSubs(currentPage, key, way, searchParam);
    }

    const subsNext = () => {
        const newPageNum = currentPage + 1;
        setCurrentPage(newPageNum);

        loadSubs(newPageNum, sortedKey, sortedWay, searchParam);
    }

    const subsPrevious = () => {
        const newPageNum = currentPage - 1;
        setCurrentPage(newPageNum);

        loadSubs(newPageNum, sortedKey, sortedWay, searchParam);
    }

    useEffect(() => {
        if(currentPage === 1) 
            setPrevDisabled(true);
        else
            setPrevDisabled(false);

        if(currentPage === maxPage)
            setNextDisabled(true);
        else
            setNextDisabled(false); 
    }, [currentPage, maxPage]);
    //

    const refreshTables = async () => {
        setSearchParam('');
        setSuggetionArray([]);
        
        setCurrentPage(1);
        setSortedKey(SubclientsHeaderData[0].key);
        setSortedWay(SORT_ORDER.ASC);

        await loadSubs(1, SubclientsHeaderData[0].key, SORT_ORDER.ASC, '');
    }

    useEffect(() => {
        (async () => {
            await refreshTables();
        })();
    }, [user?.masterClientId])

    //Manage suggestion Array
    const searchParamChange = async (param) => {
        setSearchParam(param);

        if(param) {
            try {
                const res = await getUserIdSuggestion(param, USER_TYPES.SUB_CLIENT, axiosPrivate);
                setSuggetionArray(res);
            } catch (error) {
                setSuggetionArray([])
            }
        } else {
            await refreshTables();
        }
    }

    const initialValues = {
        'param': ''        
    }

    const handleSubmit = async ({param}) => {
        setSearchParam(param);

        setSortedKey(SubclientsHeaderData[0].key);
        setSortedWay(SORT_ORDER.ASC);
        setCurrentPage(1);

        await loadSubs(1, SubclientsHeaderData[0].key, SORT_ORDER.ASC, param);
    }

    return (
        <>
            <PopUpManager 
                disable={textPopupDisabled}
                control='text'
                heading='Alert'
                description={textPopupText}
                onClose={() => setTextPopupDisabled(true)}
            />
            <Formik
                initialValues={initialValues}
                validateOnChange={true}
                validateOnBlur={false}
                onSubmit={handleSubmit}
            >
                {
                    formik => {
                        return (
                            <Form>
                                <div className='bg-popupScreen py-10 flex w-[100%] items-center rounded-[12px]'>
                                    <div className='pl-10 flex items-center w-[100%]'>
                                        <div className='w-[50%]'>
                                            <FormikControl control='input' value={searchParam} requiredSuggestions={true} formSetter={formik.setFieldValue} formSubmitter={formik.submitForm} suggestions={suggestionArray} onInputChange={searchParamChange} type='search' label={"Profile Details :"} name={'param'} placeholder={'Sub Client Id'} bgcolor='selectorBg' width={'full'} />
                                        </div>
                                        <div className='w-[20%] pl-20 -mt-6'>
                                            <SubmitButton value='Search' btnWidth='full' />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                }
            </Formik>
            <SortingTable loading={tableLoading} headerData={SubclientsHeaderData} tableData={subClients} onSelction={() => {}} nextPage={subsNext} previousPage={subsPrevious} sortTable={setSubsSorting} nextDisabled={nextDisabled} previousDisabled={prevDisabled} selectedSortedKey={sortedKey} selectedSortedOrder={sortedWay} />
        </>
    );
}

export default ViewAllSubClients;
