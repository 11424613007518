import React from 'react'
import {Header} from '../../../layouts/header'
import Navbar from '../../../layouts/navbar'
import Sidebar from '../../../layouts/sidebar'
import GenerateQRCodeForm from './components/generateQRCodeForm'

import useSidebar from '../../../hooks/useSidebar'
import sideBarData from '../utils/sidebarData'
import navData from './utils/navData'

function Index() {
  const { isSidebarOpen, toggleSidebar } = useSidebar();

  return (
    <div className='layout-wrapper'>
      <div className='top-header w-full z-10 bg-#FFFFFF'>
        <Header toggleSidebar={toggleSidebar} />
      </div>

      <div className='content-wrapper flex flex-row'>
          <div className={`sidebar bg-white ${isSidebarOpen ? 'open' : ''}`}>
              <Sidebar details={sideBarData} />
          </div>
          
          <div className='w-full bg-bgLightBlue page-content'>
              <div className='px-[20px]'>
                  <div className='h-10 flex items-center'>
                      <Navbar navData={navData} />
                  </div>
                  <div className='text-generateQRheading font-bold flex justify-start items-center'>
                    <h5 className='mb-10 text-4xl'>QR Generation</h5>
                  </div>
                  <div>
                    <div className=''>
                        <GenerateQRCodeForm />
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index