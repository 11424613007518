import React from 'react';

import { Header } from "../../../layouts/header"
import Sidebar from '../../../layouts/sidebar'
import Navbar from '../../../layouts/navbar'
import useSidebar from '../../../hooks/useSidebar';
import Dashboard from './components/Dashboard';

import sidebarData from '../utils/sidebarData';
import navData from './utils/navData';

const Index = () => {
    const { isSidebarOpen, toggleSidebar } = useSidebar();

    return (
        <div className='layout-wrapper'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>
            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sidebarData} />
                </div>
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className="px-[20px] h-full">
                        <div className='h-10 flex items-center'>
                            <Navbar navData={navData} />
                        </div>
                        <div className="profile-wrapper h-[85%]">
                            <Dashboard />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index;