import React from 'react';
import { useNavigate } from 'react-router-dom';
import under_construction from '../assets/svg/under_construction.svg';

function UnderConstruction() {

  const navigate = useNavigate();

  return (
    <div className='flex-1 h-full flex' style={{backgroundImage: 'linear-gradient(45deg, #953BA945, #54A8F38F)'}} >
        <div className='flex-1 m-[30px] bg-white flex flex-col justify-center items-center'>
            <img className='h-[70%]' src={under_construction} alt="We are building this..." />
            <button onClick={() => navigate('/home')} className='h-[7%] px-[5rem] text-white text-font16 font-medium flex flex-row justify-around items-center rounded-lg bg-gradient-to-r from-gradientPurple to-gradientBlue to-90%'>
                Go Back
            </button>
        </div>
    </div>
  )
}

export default UnderConstruction;