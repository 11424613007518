const manuIntialValues = {
    'skuId': '',
    'Pimage': '',
    'QROnprod': '',
    'preSaleQRLocation': '',
    'brand': '',
    'prodName': '',
    'batchNo': '',
    'mfgDate': '',
    'mrp': '',
    'serialNo': '',
    'shelfLife': '',
    'expiryDate': '',
    'warrantyApp': '',
    'warrantyPeriod': null,
    'manuLicenseNo': '',
    'manuAdd': '',
    'addDetails': '',
    'additionalFileDetails': '',
    'extraImage1': '',
    'extraImage2': '',
    'prodVedioLink': '',
    'manuWebsiteLink': '',
}

export default manuIntialValues;