import React, { useState } from 'react'

import downloadIcon from '../../../../assets/svg/form_images/download_icon.svg'
import Loader from "../../../../components/ui/Loader/Loader"
import TextPopup from "../../../../components/popups/textPopup"

import { downloadQrs } from "../services/api";

import { URL_TYPES } from '../../../../config/constants'
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate"

function DownloadQRTable(props) {

    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR);

    const { ProductQRs, TrackingQrType1, TrackingQrType2 } = props;
    const [loading, setLoading] = useState(false);

    const [showTextPopup, setShowTextPopup] = useState(false)
    const [alertText, setAlertText] = useState("");
    const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);
    const blurredBackgroundStyles = isBackgroundBlurred
        ? {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(100, 100, 100, 0.5)",
            backdropFilter: "blur(1.8px)",
            zIndex: 1,
        }
        : {};

    const tableHeaderOptions = [
        { heading: 'Date', width: '38%' },
        { heading: 'Time', width: '38%' },
        { heading: 'Action', width: '29%' },
    ]

    const handleDownload = async (obj) => {
        try {
            setLoading(true);
            const data = await downloadQrs({
                clientId: props.clientId,
                batchNo: props.batchNo,
                type: obj.type,
                dateIndex: obj.index
            }, axiosPrivate);

            const link = document.createElement('a');
            link.href = data;
            link.click();
        } catch (error) {
            console.log("error", error, "vbhjn")

            setAlertText(error.message);
            setShowTextPopup(true);
            setIsBackgroundBlurred(true);
        } finally {
            setLoading(false);
        }
    }

    // const width = ['50%', '25%']
    return (
        <div className='border border-navBorder rounded-[12px]'>
            <div className='flex w-[100%] text-font16 font-semibold text-popupText bg-navBorder justify-center items-center rounded-t-[12px] py-5'>
                <span className='w-[33%] flex justify-center'>Product QR's</span>
                <span className='w-[33%] flex justify-center'>Tracking QR's : Type 1</span>
                <span className='w-[33%] flex justify-center'>Tracking QR's : Type 2</span>
            </div>
            <div className='bg-bgWhite flex w-[100%] pt-2 pb-7 justify-between rounded-b-[12px]'>
                <div className='w-[34%] flex justify-center items-center'>
                    <div className='w-[80%] h-[50vh] bg-navBorder border-r border-navBorder rounded-xl download-qr-code-table'>
                        <div className='h-[5rem] bg-bgLightBlue w-full flex'>
                            {
                                tableHeaderOptions.map((heading, ind) => {
                                    return <div style={{width: heading.width}} className={`flex justify-center items-center font-bold text-3xl ${ind !== 0 && 'border-l border-black'}`} key={ind}>
                                        {heading.heading}
                                    </div>
                                })
                            }
                        </div>
                        <div>
                            {ProductQRs.map((key, index) => {
                                return (
                                    <div key={index} className='flex h-[5rem]'>
                                        <div className='w-[76%] flex'>
                                            {Object.keys(key).map((value, ind) => {
                                                return (
                                                    value !== "index" && value !== "type" && <div className={`bg-bgWhite w-1/2 m-3 rounded-md flex justify-center items-center text-2xl `} value={ind}>
                                                        {key[value]}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='w-[29%] flex justify-center items-center p-3'>
                                            <img className='w-auto h-full cursor-pointer m-3' src={downloadIcon} alt='' onClick={() => handleDownload(key)} />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                {isBackgroundBlurred && <div style={blurredBackgroundStyles} />}
                {showTextPopup && (
                    <TextPopup
                        heading="Alert"
                        description={alertText}
                        onClose={() => {
                            setShowTextPopup(false);
                            setIsBackgroundBlurred(false);
                        }}
                    />
                )}
                {
                    loading && <Loader />
                }
                <div className='w-[34%] flex justify-center'>
                    <div className='w-[80%] h-[50vh] bg-navBorder border-r border-navBorder rounded-xl download-qr-code-table'>
                        <div className='h-[5rem] bg-bgLightBlue w-full flex'>
                            {
                                tableHeaderOptions.map((heading, ind) => {
                                    return <div style={{width: heading.width}} className={`flex justify-center items-center font-bold text-3xl ${ind !== 0 && 'border-l border-black'}`} key={ind}>
                                        {heading.heading}
                                    </div>
                                })
                            }
                        </div>
                        <div>
                            {TrackingQrType1.map((key, index) => {
                                return (
                                    <div key={index} className='flex h-[5rem]'>
                                        <div className='w-[76%] flex'>
                                            {Object.keys(key).map((value, ind) => {
                                                return (
                                                    value !== "index" && value !== "type" && <div className={`bg-bgWhite w-1/2 m-3 rounded-md flex justify-center items-center text-2xl `} value={ind}>
                                                        {key[value]}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='w-[29%] flex justify-center items-center p-3'>
                                            <img className='w-auto h-full cursor-pointer m-3' src={downloadIcon} alt='' onClick={() => handleDownload(key)} />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='w-[34%] flex justify-center'>
                    <div className='w-[80%] h-[50vh] bg-navBorder border-r border-navBorder rounded-xl download-qr-code-table'>
                        <div className='h-[5rem] bg-bgLightBlue w-full flex'>
                            {
                                tableHeaderOptions.map((heading, ind) => {
                                    return <div style={{width: heading.width}} className={`flex justify-center items-center font-bold text-3xl ${ind !== 0 && 'border-l border-black'}`} key={ind}>
                                        {heading.heading}
                                    </div>
                                })
                            }
                        </div>
                        <div>
                        {TrackingQrType2.map((key, index) => {
                                return (
                                    <div key={index} className='flex h-[5rem]'>
                                        <div className='w-[76%] flex'>
                                            {Object.keys(key).map((value, ind) => {
                                                return (
                                                    value !== "index" && value !== "type" && <div className={`bg-bgWhite w-1/2 m-3 rounded-md flex justify-center items-center text-2xl `} value={ind}>
                                                        {key[value]}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='w-[29%] flex justify-center items-center p-3'>
                                            <img className='w-auto h-full cursor-pointer m-3' src={downloadIcon} alt='' onClick={() => handleDownload(key)} />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadQRTable