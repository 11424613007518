import React from 'react'

function RedButton(props) {
    const {value,label,width,height,bgColor,fontSize, ...rest} = props
    return (
    <div className='w-[100%]'>
        <button className={`bg-redButtonColor w-${width} h-${height} text-popupScreen font-medium text-${fontSize} rounded-lg h-[50px] text-3xl`}>
            {label}
        </button>
    </div>
    )
}

export default RedButton