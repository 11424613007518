import React, { useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import HomeBanner from '../../../assets/images/home_images/home_banner.svg'
import DashBoardImage from '../../../assets/images/home_images/dashboard.svg'
import AdminImage from '../../../assets/images/home_images/admin.svg'
import UserImage from '../../../assets/images/home_images/user.svg'
import CustomerImage from '../../../assets/images/home_images/customer.svg'
import FinanceImage from '../../../assets/images/home_images/finance.svg'

import useAuth from "../../../hooks/useAuth"
import {SUB_CLIENT_ROLES} from "../../../config/constants"

function HomeContent() {
    const {auth} = useAuth();

    const navigate = useNavigate();

    const handleUserClick = () => {
        navigate(`/new-batch-details`);
    }

    const handleCustomerClick = () => {
        navigate('/view-customer');
    }

    const handleAdminClick = () => {
        navigate("/view-profile");
    }

    const handleDashboardClick = () => {
        navigate("/dashboard");
    }

    const handleFinanceClick = () => {
        navigate("/finance");
    }

    return (
    <div className='flex flex-col'>
        <div className='flex flex-col w-full'>
            <h4 className='text-popupText italic font-semibold'>howdy,</h4>
            <h3 className='md:text-[48px] text-[35px] text-popupText italic font-extrabold'>Welcome back ! ! !</h3>
        </div>
        <div className='pt-5 flex flex-col'>
            <div className='pb-5 '>
                <img draggable="false" src={HomeBanner} alt='home_banner' style={{width: '100%'}}/>
            </div>
            {auth?.roles?.find((role=>[SUB_CLIENT_ROLES.ADMIN,SUB_CLIENT_ROLES.DASHBOARD]?.includes(role)))&&
                <div onClick={handleDashboardClick} className='pt-5 pb-5 cursor-pointer'>
                    <img draggable="false" src={DashBoardImage} style={{width: '100%'}} />
                </div>
            }
            <div className='flex justify-between mt-[14px]'>
                {auth?.roles?.find((role=>[SUB_CLIENT_ROLES.ADMIN,SUB_CLIENT_ROLES.USER]?.includes(role)))&&
                    <div onClick={handleUserClick} className='w-[32%] cursor-pointer'>
                        <img draggable="false" src={UserImage} style={{width: '100%'}} />
                    </div>
                }
                {auth?.roles?.find((role=>[SUB_CLIENT_ROLES.ADMIN,SUB_CLIENT_ROLES.CUSTOMER]?.includes(role)))&&
                    <div onClick={handleCustomerClick} className='w-[32%] cursor-pointer'>
                        <img draggable="false" src={CustomerImage} style={{width: '100%'}} />
                    </div>
                }
                {auth?.roles?.find((role=>[SUB_CLIENT_ROLES.ADMIN]?.includes(role)))&&
                    <div onClick={handleAdminClick} className='w-[32%] cursor-pointer'>
                        <img draggable="false" src={AdminImage} style={{width: '100%'}} />
                    </div>
                }
                
            </div>

            <div className='flex justify-between mt-[14px]'>
                {auth?.roles?.find((role=>[SUB_CLIENT_ROLES.ADMIN]?.includes(role)))&&
                    <div onClick={handleFinanceClick} className='w-[32%] cursor-pointer'>
                        <img draggable="false" src={FinanceImage} style={{width: '100%'}} />
                    </div>
                }
            </div>
        </div>

    </div>
    )
}

export default HomeContent