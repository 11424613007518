import React from 'react';
import { useLocation } from 'react-router-dom';
import { Header } from "../../../layouts/header"
import Sidebar from '../../../layouts/sidebar'
import Navbar from '../../../layouts/navbar'
import useSidebar from '../../../hooks/useSidebar';
import WarrantyTC from './components/WarrantyTC';

import sideBarData from '../utils/sidebardata';

const Index = () => {

    const { isSidebarOpen, toggleSidebar } = useSidebar();
    const { state } = useLocation();

    const navData = [
        {
            name: 'Customer',
            link: '/view-customer'
        },
        {
            name: 'Profile',
            link: '/view-customer'
        },
        {
            name: 'Warranty Terms and Conditions',
            link: `/warranty-terms-and-conditions`,
            state: state
        }
    ];

    return (
<div className='layout-wrapper'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>

            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sideBarData} />
                </div>
                
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className='px-[20px]'>
                        <div className='h-10 flex items-center'>
                            <Navbar navData={navData} />
                        </div>
                        <div className="profile-wrapper">
                            <WarrantyTC />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
