import profile from '../../../assets/images/sidebar_icons/profile_icon.svg'
import addUser from '../../../assets/svg/sidebar_icons_3/add_user_icon.svg'
import deleteUser from '../../../assets/svg/sidebar_icons_3/delete_icon.svg'
import accessManagement from '../../../assets/svg/sidebar_icons_3/file_icon.svg'
import logs from '../../../assets/svg/sidebar_icons_3/log_icon.svg'


const sideBarData = [
    {
        name:"view-profile",
        img: profile,
        desc: "Profile"
    },
    {
        name: "add-user",
        img: addUser,
        desc: "Add Users"
    },
    {
        name: "delete-user",
        img: deleteUser,
        desc: "Delete/Block users"
    },
    {
        name: "access-management",
        img: accessManagement,
        desc: "Access management"
    },
    {
        name: "logs",
        img: logs,
        desc: "View Logs"
    },
]

export default sideBarData