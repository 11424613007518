import React, { useEffect, useRef, useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './textError';
import 'react-datepicker/dist/react-datepicker.css';

function DatePickerType2(props) {
    const {defaultNoDate, formikSetter, label, name, fieldWidth, cb, onChange, customError, dateNotNeed, notAvailableOption = false, setNotAvailableOption, notApplicableOption = false, setNotApplicableOption, dateSetAuto = false,  ...rest } = props;
    const [selectedYear, setSelectedYear] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState(0);
    const [selectedDay, setSelectedDay] = useState(0);
    const [notAvailable, setNotAvailable] = useState(false);
    const [notApplicable, setNotApplicable] = useState(false);
    let yearVal, monthVal, dateVal;

    const currentYear = new Date().getFullYear();
    const years = ['YYYY', ...Array.from({ length: currentYear - 1850 }, (_, i) => 1900 + i)];
    const months = ['MM', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const getDaysInMonth = (month, year) => {
        return new Date(year, month, 0).getDate();
    };

    const getDaysArray = (selectedMonth, selectedYear) => {
        const daysInMonth = selectedMonth !== '' && selectedMonth !== 'MM' ? getDaysInMonth(months.indexOf(selectedMonth), selectedYear) : 31;
        const daysArray = ['DD'];
        for (let i = 1; i <= daysInMonth; i++) {
            daysArray.push(i);
        }
        return daysArray;
    };

    const handleNotAvailableChange = () => {
        setNotAvailable(!notAvailable);
    };

    useEffect(() => {
        setNotAvailableOption && setNotAvailableOption(notAvailable)

        if(notAvailable) {
            formikSetter(name, 'notAvailable');
            setNotApplicable(false)
        }
    }, [notAvailable])

    useEffect(() => {
        if(defaultNoDate == 'notAvailable') setNotAvailable(true);
        else if(defaultNoDate == 'notApplicable') setNotApplicable(true);
    }, [defaultNoDate])

    const handleNotApplicableChange = () => {
        setNotApplicable(!notApplicable);
    };

    useEffect(() => {
        setNotApplicableOption && setNotApplicableOption(notApplicable)
        
        if(notApplicable) {
            formikSetter(name, 'notApplicable');
            setNotAvailable(false);
        }
    }, [notApplicable])

    useEffect(() => {
        if (selectedDay && selectedMonth && selectedDay ) {
            let dtObj = new Date(`${selectedYear}-${selectedMonth}-${selectedDay} 00:00:00`);
            cb && cb(dtObj); // Perform a call back
        }
    }, [selectedDay]);

    return (
        <div className='mb-8'>
            <div className='flex w-[100%] relative justify-between items-center'>
                <div className='md:flex w-[100%] justify-between items-center'>
                    {label && <label htmlFor={name} className='text-font16 w-[40%]'>{label}</label>}
                    <div className={`${label? 'w-[60%]' : 'w-full'} flex flex-wrap gap-10 pt-2 rounded-xl`}>
                        <div className='date-grid grid grid-cols-3 gap-[5px] dropdown-icon'>
                            <Field name={`${name}.year`}>
                                {({ form, field }) => {
                                    const { value } = field;
                                    yearVal = value;
                                    return (
                                        <select
                                            className='bg-selectorBg rounded-xl text-font14 text-left tracking-wider max-w-[80px] min-w-[40px]'
                                            value={notAvailable || notApplicable? 'YYYY': value}
                                            onChange={(e) => {
                                                yearVal = e.target.value;
                                                onChange({
                                                    year: yearVal,
                                                    month: '',
                                                    day: ''
                                                })
                                                form.setFieldValue(`${name}.year`, e.target.value);
                                                setSelectedYear(prev => e.target.value);
                                                setSelectedMonth(prev => '');
                                                form.setFieldValue(`${name}.month`, '');
                                                form.setFieldValue(`${name}.day`, '');
                                                console.log("Selected Year:", e.target.value);
                                            }}
                                            disabled={notAvailable || notApplicable? true : false }
                                        >
                                            {years.map((year) => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                    );
                                }}
                            </Field>
                            <Field name={`${name}.month`}>
                                {({ form, field }) => {
                                    const { value } = field;
                                    monthVal = value;
                                    return (
                                        <select
                                            className='bg-selectorBg rounded-xl text-font14 text-left tracking-wider max-w-[80px] min-w-[40px]'
                                            value={notAvailable || notApplicable? 'MM': value}
                                            onChange={(e) => {
                                                monthVal = e.target.value;
                                                onChange({
                                                    year: yearVal,
                                                    month: monthVal,
                                                    day: ''
                                                })
                                                form.setFieldValue(`${name}.month`, e.target.value);
                                                setSelectedMonth(prev => e.target.value);
                                                form.setFieldValue(`${name}.day`, '');
                                                console.log("Selected Month:", e.target.value);
                                            }}
                                            disabled={notAvailable || notApplicable? true : (dateSetAuto? !dateSetAuto : (!selectedYear || selectedYear === 'YYYY'))}
                                        >
                                            {months.map((month, index) => (
                                                <option key={month} value={index === 0 ? '' : month}>
                                                    {month}
                                                </option>
                                            ))}
                                        </select>
                                    );
                                }}
                            </Field>
                            <Field name={`${name}.day`}>
                                {({ form, field }) => {
                                    const { value } = field;
                                    dateVal = value;
                                    const daysArray = getDaysArray(selectedMonth, selectedYear);
                                    return (
                                        <select
                                            className='bg-selectorBg rounded-xl text-font14 text-left tracking-wider max-w-[80px] min-w-[40px]'
                                            value={notAvailable || notApplicable? 'DD': value}
                                            onChange={(e) => {
                                                dateVal = e.target.value;
                                                console.log(dateVal)
                                                onChange({
                                                    year: yearVal,
                                                    month: monthVal,
                                                    day: dateVal
                                                })
                                                setSelectedDay(prev => e.target.value);
                                                form.setFieldValue(`${name}.day`, e.target.value);
                                                console.log("Selected Day:", e.target.value);
                                            }}
                                            disabled={notAvailable || notApplicable? true : (dateSetAuto? !dateSetAuto : (!selectedMonth || selectedMonth === 'MM' || !selectedYear || selectedYear === 'YYYY'))}
                                        >
                                            {daysArray.map((day) => (
                                                <option key={day} value={day}>
                                                    {day}
                                                </option>
                                            ))}
                                        </select>
                                    );
                                }}
                            </Field>
                        </div>

                        {/* optiopns */}
                        <div className='choice-grid flex w-full'>
                            {
                                notAvailableOption && 
                                <div className='radio-btn-wrapper flex items-center gap-5 text-couponsRadioOPtions font-semibold text-3xl'>
                                    <div className="flex flex-col">
                                        <h5 htmlFor='notavailable' className='text-sideBarBtnText font-normal' style={{ minWidth: 'auto !important' }}>Not Available</h5>
                                        <span className='text-[10px] text-sideBarBtnText font-normal'>(during Qr Generation)</span>
                                    </div>
                                    <input checked={notAvailable} type='checkbox' name='notavailable' style={{ paddingRight: '0' }} onChange={handleNotAvailableChange} />
                                </div>
                            }
                                <div className='m-2' />
                            {
                                notApplicableOption && 
                                <div className='radio-btn-wrapper flex items-center gap-5 text-couponsRadioOPtions font-semibold text-3xl'>
                                    <div className="flex flex-col">
                                        <h5 htmlFor='notavailable' className='text-sideBarBtnText font-normal' style={{ minWidth: 'auto !important' }}>Not Applicable</h5>
                                        <span className='text-[10px] text-sideBarBtnText font-normal'></span>
                                    </div>
                                    <input checked={notApplicable} type='checkbox' name='notavailable' style={{ paddingRight: '0' }} onChange={handleNotApplicableChange} />
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className='relative w-full'>
                <div className={`absolute ${label && 'ml-[40%]'}`}>
                    {
                        customError? 
                            <TextError>{customError}</TextError> :
                            <ErrorMessage name={name} component={TextError} />
                    }
                </div>
            </div>
        </div>

    );
}

export default DatePickerType2;