import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'

const WarrantyTC = () => {

    const navigate = useNavigate();
    const state = useLocation().state;
    const warrantyTC = state?.warrantyTC;

    useEffect(() => {
        if(!state || !warrantyTC)
            navigate('/customer-profile');
    }, []);

    return (
        <div className='bg-white w-[100%] mx-auto border border-navBorderLight rounded-[12px] my-10 p-20'>
            <h4 className='text-sideBarActions mb-10 font-semibold'>Warranty Terms and Conditions</h4>
            <pre
                style={{
                    whiteSpace: 'pre-wrap'
                }}
                className='text-3xl'
            >
                {warrantyTC}
            </pre>
        </div>
    );
}

export default WarrantyTC;
