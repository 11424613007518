import { handleError } from '../../../../utils/handles/errorHandle';

async function getAllSubClientsPaginated(masterClientId, pageNo, pageSize, axiosPrivate){
    try{
        const response = await axiosPrivate.get(`/api/client/${masterClientId}/subClients/paginated`, { params: {pageNo, pageSize} });
        return response?.data;
    }catch(error){  
        handleError(error);
    }
}

async function deleteSubClient(deleteDetails,axiosPrivate){
    try{
        const response = await axiosPrivate.post("/api/client/delete/sub-client",deleteDetails);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function blockSubClient(blockDetails,axiosPrivate){
    try{
        const response = await axiosPrivate.post("/api/client/block/sub-client",blockDetails);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function unBlockSubClient(unBlockDetails,axiosPrivate){
    try{
        const response = await axiosPrivate.post("/api/client/unblock/sub-client",unBlockDetails);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

export{
    getAllSubClientsPaginated,
    deleteSubClient,
    blockSubClient,
    unBlockSubClient,
}