import { handleError } from '../../../../utils/handles/errorHandle';

async function fetchSubClientUserRoles(masterClientId,pageNo,pageSize,axiosPrivate){
    try {
        const res = await axiosPrivate.get(`/api/client/roles/${masterClientId}`, { params:{ pageNo, pageSize } });
        return res?.data;
    } catch (error) {
        handleError(error); 
    }
}

async function updateSubClientUserRoles(userId,roles,axiosPrivate){
    try {
        const res = await axiosPrivate.put(`/api/client/${userId}/roles`,{roles});
        return res;
    } catch (error) {
        handleError(error);
    }
}


export {
    fetchSubClientUserRoles,
    updateSubClientUserRoles
}