import { handleError } from "../../../../utils/handles/errorHandle";

const resetPassword = async(oldPassword,newPassword,axiosPrivate)=>{
    try {
        const res = await axiosPrivate.post("api/auth/sub-client/reset-password",{ oldPassword, newPassword });
        return res;
    } catch(error) {
        handleError(error);
    }
}

export {
    resetPassword
}