import { handleError } from '../../../../utils/handles/errorHandle';

const FetchBatches = async (values,axiosPrivate) => {
    const { clientId, batchNo, toDate, fromDate, page } = values;

    try {
        const res = await axiosPrivate.post("/api/report/generated-batches",  { 
            toDate: toDate,
            fromDate: fromDate,
            clientId: clientId,
            batchNo: batchNo,
            page:page
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        });
        
        return {
            data: res.data.data, 
            totalPages: res.data.totalPages, 
            currentPage: res.data.currentPage
        }
    } catch (error) {
        handleError(error);
    }
}

const getBatchData = async (clientId, batchNo,axiosPrivate) => {
    try {
        const res = await axiosPrivate.post(`/api/qr/get-details/${clientId}`, {
            batchNo
        });
    
        return res.data?.data;
    } catch (error) {
        handleError(error)
    }
}

const updateBatchDetails = async (clientId, formData,axiosPrivate) => {
    try {
        const res = await axiosPrivate.post(`/api/qr/edit-batch/${clientId}/EDIT_BATCH`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            withCredentials: true
        });

        return res.data;
    } catch (error) {
        handleError(error);
    }
}

const statusChange = async(statusDetails,axiosPrivate) => {
    console.log(statusDetails);

    try {
        const res = await axiosPrivate.post(`/api/qr/change-batch-status` , statusDetails);

        return res;
    } catch (error) {
        handleError(error);
    }
}

const deleteBatch = async (deleteDetails,axiosPrivate) => {
    try {
        const res = await axiosPrivate.post(`/api/qr/delete-batch`, deleteDetails);
        
        return res;
    } catch (error) {
        handleError(error);
    }
}

const addBatch = async (batchData,axiosPrivate) => {
    try{
        const response = await axiosPrivate.post(`/api/qr/add-batch/${batchData.clientId}/${batchData.type}`,
        { 
            "batchNo": batchData.batchNo,
            "totalQRGen": batchData.totalQRGen, 
            "trackingQr_tert": batchData.trackingQr_tert, 
            "trackingQr_quat": batchData.trackingQr_quat },
        {
            headers: {"Content-Type": "application/json"},
        });

        return {
            prodQrs: response?.data?.generatedQrcodes?.productQrsFile,
            quatQrs: response?.data?.generatedQrcodes?.quatQrsFile,
            tertQrs: response?.data?.generatedQrcodes?.tertQrsFile,
        }
    }catch (error) {
        handleError(error);
    }
}

export {
    FetchBatches,
    getBatchData,
    updateBatchDetails,
    statusChange,
    deleteBatch,
    addBatch
};