import React from 'react'
import { HeaderPlain } from '../../../layouts/header'
import createNewPassword from '../../../assets/images/create_new_password.svg'
import LoginForm from './components/loginForm'
function index() {
    return (
        <div className='flex flex-col h-[100vh]'>
            <div className='h-[110px]'>
                <HeaderPlain />
            </div>
            <div className='w-full pb-[20px] flex'>
                <div className='w-[50%] flex justify-center items-center h-full'>
                    <img className='md:w-[70%] h-[80%] md:mx-[0px] mx-auto' src={createNewPassword} />
                </div>

                <div className='w-[50%] flex justify-center items-center h-full'>
                    <div className='md:w-[75%] h-full'>
                        <LoginForm/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default index