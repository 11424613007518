import { handleError } from '../../../../utils/handles/errorHandle';

const getSkuIdsByBatchType = async(clientId,batchType,axiosPrivate) => {
    console.log(clientId,batchType)
    try {
        const res = await axiosPrivate.post(`/api/qr/sku/get-by-batchType`, { clientId: clientId,batchType }, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        });

        return [{ key: 'select option', value: '' }, ...res.data.data.map(item => ({ key: item.skuId, value: item.skuId, _id: item._id }))];
    } catch (error) {
        handleError(error);
    }
}

const getSkuData = async(clientId, skuId,axiosPrivate) => {
    try {
        const res = await axiosPrivate.post(`/api/qr/sku/get`, {
            clientId: clientId,
            skuId: skuId
        },  {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
    
        return res.data.data;
    } catch (error) {
        handleError(error);
    }
} 

const generateQrs = async (batchData,axiosPrivate) => {
    try {
        const formData = new FormData();

        if(batchData.skuId) formData.append("skuId", String(batchData.skuId));
        if (batchData.Pimage.includes('sku')) {
            formData.append("Pimage", batchData.Pimage);
        } else {
            const image1 = await fetch(batchData.Pimage)
            .then(r => r.blob())
            .then(bf => new File([bf], "Pimage", { type: "image/png" }))
            .catch((err) => {
                console.log(err.message);
                return [500, "Could not fetch the uploaded image"];
            });
            formData.append("Pimage", image1);
        }
        if (batchData.QROnprod) {
            if (batchData.QROnprod.includes('sku')) {
            formData.append("QROnprod", batchData.QROnprod);
            } else {
            const image2 = await fetch(batchData.QROnprod)
                .then(r => r.blob())
                .then(bf => new File([bf], "QROnprod", { type: "image/png" }))
                .catch((err) => {
                console.log(err.message);
                return [500, "Could not fetch the uploaded image"];
                });
            formData.append("QROnprod", image2);
            }
        }
        if (batchData.preSaleQRLocation) {
            if (batchData.preSaleQRLocation.includes('sku')) {
            formData.append("preSaleQRLocation", batchData.preSaleQRLocation);
            } else {
            const image2 = await fetch(batchData.preSaleQRLocation)
                .then(r => r.blob())
                .then(bf => new File([bf], "preSaleQRLocation", { type: "image/png" }))
                .catch((err) => {
                console.log(err.message);
                return [500, "Could not fetch the uploaded image"];
                });
            formData.append("preSaleQRLocation", image2);
            }
        }
        if (batchData.extraImage1) {
            if (batchData.extraImage1.includes('sku')) {
            formData.append("extraImage1", batchData.extraImage1);
            } else {
            const image3 = await fetch(batchData.extraImage1)
                .then(r => r.blob())
                .then(bf => new File([bf], "extraImage1", { type: "image/png" }))
                .catch((err) => {
                console.log(err.message);
                return [500, "Could not fetch the uploaded image"];
                });
            formData.append("extraImage1", image3);
            }
        }
        if (batchData.extraImage2) {
            if (batchData.extraImage2.includes('sku')) {
            formData.append("extraImage2", batchData.extraImage2);
            } else {
            const image4 = await fetch(batchData.extraImage2)
                .then(r => r.blob())
                .then(bf => new File([bf], "extraImage2", { type: "image/png" }))
                .catch((err) => {
                console.log(err.message);
                return [500, "Could not fetch the uploaded image"];
                });
            formData.append("extraImage2", image4);
            }
        }
        if (batchData.expiryImage) {
            if (batchData.expiryImage.includes('sku')) {
            formData.append("expiryImage", batchData.expiryImage);
            } else {
            const image5 = await fetch(batchData.expiryImage)
                .then(r => r.blob())
                .then(bf => new File([bf], "expiryImage", { type: "image/png" }))
                .catch((err) => {
                console.log(err.message);
                return [500, "Could not fetch the uploaded image"];
                });
            formData.append("expiryImage", image5);
            }
        }
        if (batchData.prodVedioLink) {
            if (batchData.prodVedioLink.includes('sku')) {
            formData.append("prodVedioLink", batchData.prodVedioLink);
            } else {
            const image6 = await fetch(batchData.prodVedioLink)
                .then(r => r.blob())
                .then(bf => new File([bf], "prodVedioLink", { type: "video/mp4" }))
                .catch((err) => {
                console.log(err.message);
                return [500, "Could not fetch the uploaded image"];
                });
            formData.append("prodVedioLink", image6);
            }
        }
        for (var key in batchData) {
            if (
            key !== "Pimage" &&
            key !== "QROnprod" &&
            key !== "preSaleQRLocation" &&
            key !== "extraImage1" &&
            key !== "extraImage2"&&
            key !== "expiryImage"&&
            key !== "prodVedioLink" &&
            key !== "skuId"
        )
            formData.append(key, batchData[key]);
        }
        formData.append("uploadType","QR_GEN")
    
        const response = await axiosPrivate.post(
            `/api/qr/new-batch/${batchData.clientId}/${batchData.batchType}/${batchData.type}/QR_GEN`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        );

        return {
            prodQrs: response?.data?.generatedQrcodes?.productQrsFile,
            quatQrs: response?.data?.generatedQrcodes?.quatQrsFile,
            tertQrs: response?.data?.generatedQrcodes?.tertQrsFile,
        }
    } catch (error) {
        handleError(error);
    }
}

export {
    getSkuIdsByBatchType,
    getSkuData,
    generateQrs
}