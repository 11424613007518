import React from 'react'
import homeIcon from '../assets/images/home_icon.svg'
import {SubmitButton} from '../components/ui/buttons/submitButton'
import { Link, useNavigate } from 'react-router-dom';

function Navbar({ navData }) {
    const navigate = useNavigate()
  return (
    <div className='bg-bgWhite pt-2 pb-2 pr-2 w-full right-0 border-3 border-navBorder rounded-[8px] flex flex-row justify-between items-center'>
        <div className='pl-[20px] flex'>
            {
                navData && Array.isArray(navData) && navData.map((data, ind) => {
                    return <div className='text-[14px]'>
                        <Link state={data.state} className={`${(ind !== navData.length - 1)? 'text-navGrey' : 'text-blue-600'}  font-normal text-[14px]`} to={data.link}>{data.name}</Link> 
                        {(ind !== navData.length - 1) && <span className='px-[10px]'>{' >'}</span>}
                    </div>
                })
            }
        </div>
        <div className='w-[100px]'>
            <SubmitButton imageUrl={homeIcon} value={"Home"} btnWidth={'full'} height={'[46px]'} onClick={() => navigate('/home')} />
        </div>
    </div>
  )
}

export default Navbar