import axios from "../axios/axios"
import useAuth from "./useAuth"

const useRefreshToken = () => {
    const {setAuth} = useAuth();

    const refresh = async ()=>{
        const response = await axios.get(`/api/auth/client/generate-access-token`,{
            withCredentials:true
        });

        console.log(response, "this is res")
        setAuth(prev=>{
            return {
                ...prev,
                accessToken:response?.data?.accessToken,
                roles:response?.data?.roles,
                masterClientId:response?.data?.masterClientId,
                clientName: response?.data?.organizationName,
                masterClientObjectId: response?.data?.masterClientObjectId
            }
        });
        return response.data.accessToken;
    }
    return refresh;
}

export default useRefreshToken