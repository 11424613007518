const retaIntialValues = {
    'skuId': '',
    'Pimage': '',
    'QROnprod': '',
    'preSaleQRLocation': '',
    'prodName': '',
    'batchNo': '',
    'manuLicenseNo': '',
    'manuAdd': '',
    'addDetails': '',
    'additionalFileDetails': '',
    'extraImage1': '',
    'extraImage2': '',
    'prodVedioLink': '',
    'manuWebsiteLink': '',
}

export default retaIntialValues;