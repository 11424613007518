export const removeExif = (arrayBuffer) => {
    const dataView = new DataView(arrayBuffer);
    let offset = 2; // Skip the JPEG header
    const segments = [];

    while (offset < dataView.byteLength) {
        const marker = dataView.getUint16(offset);
        const length = dataView.getUint16(offset + 2);

        // Check if it's an EXIF segment
        if (marker === 0xFFE1) {
            // Skip this segment
            offset += length + 2;
        } else {
            // Keep this segment
            segments.push(arrayBuffer.slice(offset, offset + length + 2));
            offset += length + 2;
        }
    }

    // Combine all segments into a new ArrayBuffer
    let newArrayBufferLength = 2; // Start with the length of the JPEG header
    segments.forEach(segment => newArrayBufferLength += segment.byteLength);

    const newArrayBuffer = new Uint8Array(newArrayBufferLength);
    newArrayBuffer.set(new Uint8Array(arrayBuffer.slice(0, 2)), 0); // Copy the JPEG header

    let newOffset = 2;
    segments.forEach(segment => {
        newArrayBuffer.set(new Uint8Array(segment), newOffset);
        newOffset += segment.byteLength;
    });

    return newArrayBuffer.buffer;
};

const removedExifFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            const arrayBuffer = reader.result;
            const modifiedArrayBuffer = removeExif(arrayBuffer);
            const blob = new Blob([modifiedArrayBuffer], { type: file.type });
            const modifiedFile = new File([blob], file.name, { type: file.type });
            
            resolve(modifiedFile);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
}
export default removedExifFile;