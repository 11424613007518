import React from 'react'

function textError(props) {
  return (
    <div className='text-red-400 text-xl font-medium '>
        {props.children}
    </div>
  )
}

export default textError