import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { motion, AnimatePresence } from 'framer-motion';

import FormikControl from '../../../../components/form/formikControl';
import { FormHeadingComponent, FormSubHeadingComponent } from '../../../../components/ui/formSection';

import retaIntialValues from '../utils/reataInitialValues';
import retaValidationSchema from '../validations/retaValidation';

export var retaForm = {};
function RetailerForm({type, skuData, animationSetUp}) {

    const [clickedRetailerProductDetailsHeading, setClickedRetailerProductDetailsHeading] = useState(true)
    const [clickedRetailerDetailsHeading, setClickedRetailerDetailsHeading] = useState(true)
    const [clickedRetailerProductDetailsSub, setClickedRetailerProductDetailsSub] = useState(false)
    const [clickedRetailerDetailsSub, setClickedRetailerDetailsSub] = useState(false)

    const [qrProdErr, setQrProdErr] = useState('');
    const [preSaleQRLocationErr, setPreSaleQRLocationErr] = useState('');

    const handleClickedRetailerProductDetailsHeadinag = (event) => {
        setClickedRetailerProductDetailsHeading(!event)
        setClickedRetailerProductDetailsSub(false)
    }
    const handleClickedRetailerDetailsHeading = (event) => {
        setClickedRetailerDetailsHeading(!event)
    }
    const handleClickedRetailerProductDetailsSub = (event) => {
        setClickedRetailerProductDetailsSub(!event)
    }
    const handleClickedRetailerDetailsSub = (event) => {
        setClickedRetailerDetailsSub(!event)
    }

    const openAllTabs = () => {
        setClickedRetailerProductDetailsHeading(true)
        setClickedRetailerDetailsHeading(true)
        setClickedRetailerProductDetailsSub(true)
        setClickedRetailerDetailsSub(true)
    }

    //To open All subs by default
    useEffect(() => {
        setTimeout(() => {
            setClickedRetailerProductDetailsSub(true)
            setClickedRetailerDetailsSub(true)
        }, 300)
    }, []);

    const handleSubmit = () => {
        //Nothing
    }

    const validateFormFields = (values) => {
        if(values.QROnprod) 
            setQrProdErr('');
        if(values.preSaleQRLocation) 
            setPreSaleQRLocationErr('');
    }

    let validateForm;

    return (
        <Formik
            initialValues={skuData || retaIntialValues}
            enableReinitialize={true}
            validationSchema={retaValidationSchema}
            validate={validateFormFields}
            onSubmit={handleSubmit}
        >
            {
                formik => {

                    validateForm = () => {
                        openAllTabs();
                        formik.validateForm();

                        let validateObj = {};
                        Object.keys(retaIntialValues).forEach(key => {
                            validateObj[key] = true;
                        })
                        formik.setTouched(validateObj);

                        if(type === "3" && !formik.values.QROnprod)
                            setQrProdErr('This Field is required');
                        if(type === "3" && !formik.values.preSaleQRLocation)
                            setPreSaleQRLocationErr('This Field is required');
                    }
                    retaForm = {
                        values: formik.values,
                        isFormValid: () => {
                            return Object.keys(formik.errors).length || qrProdErr || preSaleQRLocationErr ?false:true
                        },
                        valideForm: validateForm
                    }
                    
                    return (
                        <Form>
                            <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                <div onClick={() => { handleClickedRetailerProductDetailsHeadinag(clickedRetailerProductDetailsHeading) }}>
                                    <FormHeadingComponent name={"Product Details"} clicked={clickedRetailerProductDetailsHeading} />
                                </div>
                                <AnimatePresence>
                                {
                                    clickedRetailerProductDetailsHeading &&
                                    <motion.div 
                                            {...animationSetUp}  
                                        className='bg-bgWhite rounded-b-[12px]'
                                    >
                                        <div onClick={() => handleClickedRetailerProductDetailsSub(clickedRetailerProductDetailsSub)}>
                                            <FormSubHeadingComponent name={"Provide Product Details"} clicked={clickedRetailerProductDetailsSub} />
                                        </div>
                                        <AnimatePresence>
                                        {
                                            clickedRetailerProductDetailsSub &&
                                            <motion.div 
                                                {...animationSetUp} 
                                            >
                                                <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 '>
                                                    <FormikControl
                                                        control='file'
                                                        type='file'
                                                        defaultImageUrl={formik.values.Pimage}
                                                        label={"Product/Seller Image :"}
                                                        name='Retailer-product-image'
                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                        customerror={formik.touched.Pimage && formik.errors.Pimage}
                                                        onFileSelect={(url) => {
                                                            if(url)
                                                                formik.setFieldValue("Pimage", url);
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    type === "3" && <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10'>
                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            defaultImageUrl={formik.values.QROnprod}
                                                            label={"Hidden QR on Product :"}
                                                            name={'hiddend-qr-on-prod'}
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            customerror={qrProdErr}
                                                            onFileSelect={(url) => {
                                                                formik.setFieldValue("QROnprod", url);
                                                            }}
                                                        />

                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            defaultImageUrl={formik.values.preSaleQRLocation}
                                                            label={"Hidden QR on Product :"}
                                                            name={'hiddend-qr-on-prod'}
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            customerror={preSaleQRLocationErr}
                                                            onFileSelect={(url) => {
                                                                formik.setFieldValue("preSaleQRLocation", url);
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 rounded-b-[12px]'>
                                                    <FormikControl control='input' type='text' label={"Sold by :"} name={'prodName'} bgcolor={'selectorBg'} />
                                                </div>
                                            </motion.div>
                                        }
                                        </AnimatePresence>
                                    </motion.div>
                                }
                                </AnimatePresence>
                            </div>
                            <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                <div onClick={() => { handleClickedRetailerDetailsHeading(clickedRetailerDetailsHeading) }}>
                                    <FormHeadingComponent name={"Seller Details"} clicked={clickedRetailerDetailsHeading} />
                                </div>
                                <AnimatePresence>
                                {
                                    clickedRetailerDetailsHeading &&
                                    <motion.div 
                                        {...animationSetUp} 
                                        className='bg-bgWhite rounded-b-[12px]'
                                    >
                                        <div className='rounded-b-[12px]' onClick={() => handleClickedRetailerDetailsSub(clickedRetailerDetailsSub)}>
                                            <FormSubHeadingComponent name={"Provide Seller Details"} clicked={clickedRetailerDetailsSub} />
                                        </div>
                                        <AnimatePresence>
                                        {
                                            clickedRetailerDetailsSub &&
                                            <motion.div 
                                                {...animationSetUp}
                                                className='px-[30px] grid grid-cols-1 gap-y-10 rounded-[12px] qr-manufacturing-details'
                                            >
                                                    <FormikControl
                                                        control='input-textarea-small'
                                                        width={'full'}
                                                        type='description'
                                                        label={"License no :"}
                                                        name={'manuLicenseNo'}
                                                        placeholder={"Type your text here"} />
                                                    <FormikControl
                                                        control='input-textarea-large'
                                                        width={'full'}
                                                        type='description'
                                                        label={"Address :"}
                                                        name={'manuAdd'}
                                                        placeholder={"Type your text here"} />
                                                    <FormikControl
                                                        control='input-textarea-large'
                                                        width={'full'}
                                                        type='description'
                                                        label={"Details :"}
                                                        name={'addDetails'}
                                                        placeholder={"Type your text here"} />
                                                    <FormikControl
                                                        control='input-textarea-small'
                                                        width={'full'}
                                                        type='description'
                                                        label={"Image or Video Header :"}
                                                        name={'additionalFileDetails'}
                                                        placeholder={"image header"}
                                                    />
                                                    <div className='flex flex-wrap md:flex-nowrap mb-[25px] md:mb-0 items-center h-[100%] gap-5 generate-img'>
                                                        <div className='generate-img-upload-wrapper w-[100%]'>
                                                            <div className='flex sm:flex-col md:flex-row flex-wrap w-[100%]'>
                                                                <FormikControl
                                                                    control='file'
                                                                    type='file'
                                                                    label={""}
                                                                    name='reta-extra-Image-1'
                                                                    defaultImageUrl={formik.values.extraImage1}
                                                                    acceptableFiles='.jpg,.png,.jpeg'
                                                                    customerror={formik.touched.extraImage1 && formik.errors.extraImage1}
                                                                    onFileSelect={(url) => {
                                                                        formik.setFieldValue("extraImage1", url);
                                                                    }}
                                                                    pdY={'1rem'}
                                                                // onChange={(e) => formik.setFieldValue("extraImage1", URL.createObjectURL(e.currentTarget.files[0]))}
                                                                />
                                                                {/* <img style={{ "width": "20rem" }} src={formik.values.extraImage1} alt="" /> */}
                                                                <FormikControl
                                                                    control='file'
                                                                    type='file'
                                                                    label={""}
                                                                    name='reta-extra-Image-2'
                                                                    defaultImageUrl={formik.values.extraImage2}
                                                                    acceptableFiles='.jpg,.png,.jpeg'
                                                                    customerror={formik.touched.extraImage2 && formik.errors.extraImage2}
                                                                    onFileSelect={(url) => {
                                                                        formik.setFieldValue("extraImage2", url);
                                                                    }}
                                                                    pdY={'1rem'}
                                                                // onChange={(e) => formik.setFieldValue("extraImage2", URL.createObjectURL(e.currentTarget.files[0]))}
                                                                />
                                                                {/* <img style={{ "width": "20rem" }} src={formik.values.extraImage2} alt="" /> */}
                                                                
                                                                {/* <img style={{ "width": "20rem" }} src={formik.values.extraImage1} alt="" /> */}
                                                                <FormikControl
                                                                    control='video'
                                                                    type='file'
                                                                    label={""}
                                                                    name='reta-extra-video-1'
                                                                    defaultVideoUrl={formik.values.prodVedioLink}
                                                                    acceptableFiles='.mp4'
                                                                    customerror={formik.touched.prodVedioLink && formik.errors.prodVedioLink}
                                                                    onFileSelect={(url) => {
                                                                        formik.setFieldValue("prodVedioLink", url);
                                                                    }}
                                                                    pdY={'1rem'}
                                                                // onChange={(e) => formik.setFieldValue("extraImage2", URL.createObjectURL(e.currentTarget.files[0]))}
                                                                />
                                                            </div>
                                                        </div>
                                                                
                                                    </div>
                                                                
                                                    <FormikControl
                                                        control='input-textarea-small'
                                                        type='description'
                                                        label={"Website Link :"}
                                                        name={'manuWebsiteLink'}
                                                        placeholder={"Type your text here"} />

                                                    <div className='m-10'/>
                                                </motion.div>
                                        }
                                        </AnimatePresence>
                                    </motion.div>
                                }
                                </AnimatePresence>
                            </div>
                        </Form>
                    )
                }
            }
        </Formik>
    )
}

export default RetailerForm;