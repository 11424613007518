import React from 'react'
import DateView from 'react-datepicker'
import { Field, ErrorMessage } from 'formik'
import './datePicker.css'
import textError from './textError'
import 'react-datepicker/dist/react-datepicker.css'
import { ReactComponent as CalendarIcon } from '../../assets/svg/form_images/datepicker.svg';

function datePicker(props) {
    const { label, name, fieldWidth, bgcolor, noDateMessage, onChange, ...rest } = props

    return (
        <div className='w-[100%] mb-8'>
            <div className='md:flex w-[100%] justify-between items-center' >
                {label && <label htmlFor={name} className='text-financeSideBoxText text-font16 w-[45%]'>{label}</label>}

                <div className={`w-[${fieldWidth || '55%'}] flex flex-wrap pt-2 rounded-xl`}>
                    {
                        noDateMessage && 
                        <div className='relative w-[100%] date-wrapper'>
                            <p className={`w-full px-[20px] h-[46px] bg-${bgcolor || 'selectorBg'} rounded-xl flex items-center text-font16 text-left tracking-wider `}
                                style={{ width: '100%', backgroundColor: 'rgba(24, 42, 180, 0.03)' }}
                            >
                                {noDateMessage}
                            </p>

                            <div className='absolute inset-y-0 right-0 flex items-center pr-[20px] pointer-events-none'>
                                <CalendarIcon className='w-5 h-5 text-popupText' />
                            </div>
                        </div>
                    }
                    {
                        !noDateMessage && 
                        <Field
                        name={name}>
                        {
                            ({ form, field }) => {
                                const { setFieldValue } = form
                                const { value } = field
                                return (
                                    <div className='relative w-[100%] date-wrapper'>
                                        <DateView
                                            style={{ width: '100%', backgroundColor: 'rgba(24, 42, 180, 0.03)' }}
                                            className={`w-full px-[20px] h-[46px] bg-${bgcolor || 'selectorBg'} rounded-xl flex justify-center items-center 
                                            text-font16 text-left tracking-wider `}
                                            // calendarIcon={<CalendarIcon />}
                                            id={name} {...field}
                                            placeholderText='dd/mm/yyyy'
                                            selected={value}
                                            onChange={val => {
                                                setFieldValue(name, val)
                                                onChange && onChange(val);
                                            }}
                                            {...rest}
                                            autoComplete='off'
                                            dateFormat={'dd/MM/yyyy'}
                                        />
                                        <div className='absolute inset-y-0 right-0 flex items-center pr-[20px] pointer-events-none'>
                                            <CalendarIcon className='w-5 h-5 text-popupText' />
                                        </div>
                                    </div>
                                )
                            }
                        }
                    </Field>
                    }
                </div>
            </div>
            
            <div className='w-full relative'>
                <div className='absolute'>
                    <ErrorMessage name={name} component={textError} />
                </div>
            </div>
        </div>
    )
}

export default datePicker