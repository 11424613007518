import React from 'react'

function deleteButtonRed(props) {
  const {bgColor,btnWidth,value,onClick,disabled,type,pdX, pdY, ...rest} = props
  const backgroundCol = bgColor ? `bg-${bgColor}` : 'bg-deleteBtnRed'
  return (
    <div className={`${disabled ? 'bg-gray-400 cursor-not-allowed' : backgroundCol } w-${btnWidth} rounded-lg py-${pdY} px-${pdX}`}>
        <button disabled={disabled} type={type} onClick={onClick} className={`h-16 text-bgWhite text-2xl w-full flex justify-center items-center`}>
            {value}
        </button>
    </div>
  )
}

export default deleteButtonRed