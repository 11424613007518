import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { SubmitButton } from '../../../../components/ui/buttons/submitButton'

function ProductCard({purchase}) {
    const navigate = useNavigate()
    const [ warrantyLeft, setWarrantyLeft ] = useState(0);

    const calculateWarantyLeft = useCallback(() => {
        const currentDate = new Date();
        const purchaseDate = new Date(purchase.purchaseDate);

        const timeDifference = currentDate - purchaseDate;
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
        
        return purchase.warrantyPeriod - daysDifference;
    }, [purchase.purchaseDate, purchase.warrantyPeriod]);

    useEffect(() => {
        setWarrantyLeft(Math.floor(calculateWarantyLeft()))
    }, [calculateWarantyLeft])

    const handleProductDetails = () => {
        navigate('/product-details', { state: { product: purchase }});
    };

    return (
        <div key={purchase.id} className="bg-white p-[20px] rounded-[12px] border border-navBorderLight flex justify-between">
            <div className='flex items-center'>
                <div className='h-full flex justify-center items-start mr-10'>
                    <div className='h-[15rem] aspect-square'>
                        <img className="w-full h-full rounded-[12px]" src={purchase?.imageProd} alt="profile-img" />
                    </div>
                </div>
                <div className='flex justify-between flex-wrap gap-y-10'>
                    <div>
                        <h4 className='text-sideBarActions text-font22 font-semibold'>{purchase?.name.length <= 20? purchase?.name : purchase?.name.slice(0, 20) + '...'}</h4>
                        <span className='text-black text-opacity-40 font-medium text-font14'>{purchase?.brand}</span>
                    </div>
                    <p className='w-full text-[#0D0D0E] font-normal text-font14'>{warrantyLeft > 0 ? `${warrantyLeft} days warranty left`: 'Warranty expired'}</p>
                </div>
            </div>
            <div className='flex items-center w-[25rem]'>
                <SubmitButton value={'Product Details'} type={'submit'} btnWidth={'full'} onClick={() => handleProductDetails()} />
            </div>
        </div>
    )
}

export default ProductCard;