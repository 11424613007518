import { React, useState } from 'react'
import { Formik, Form } from 'formik';
import {Header} from '../../../layouts/header'
import Navbar from '../../../layouts/navbar'
import Sidebar from '../../../layouts/sidebar'
import sideBarData from '../utils/sidebarData'
import CreateSku from './skuCreate'
import EditSku from './skuEdit';
import FormikControl from '../../../components/form/formikControl';
import Loader from '../../../components/ui/Loader';

import useAuth from '../../../hooks/useAuth'
import initialValues from './utils/initialValues';
import { actionOptions, batchTypeOptions } from './utils/options'
import validationSchema from './validations/validations';

import useSidebar from '../../../hooks/useSidebar';
import navData from './utils/navData';

function Index() {

    const { auth } = useAuth();
    const clientId = auth.masterClientId;
    const { isSidebarOpen, toggleSidebar } = useSidebar();

    const [actionSelected, setactionSelected] = useState('');
    const [modeSelected, setModeSelected] = useState('');

    return (
        <div className='layout-wrapper'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>

            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sideBarData} />
                </div>
                
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className='px-[20px]'>
                        <div className='h-10 flex items-center'>
                            <Navbar navData={navData} />
                        </div>
                        <div className='mt-5 flex flex-col border-solid border-navBorderLight rounded-[12px] bg-popupScreen p-10'>
                            <div className='flex items-start'>
                                <div className='w-[100%]'>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                    >
                                        {
                                            formik => {
                                                return (
                                                    <Form>
                                                        <div>
                                                            <div className='w-[100%] flex'>
                                                                <div className='flex flex-col w-[50%]'>
                                                                    {/* Action */}
                                                                    <div className='flex w-[100%] justify-start items-center h-20 my-3'>
                                                                        <label className={`pl-4 text-left text-popupText font-normal text-font16`}> Action: </label>
                                                                        <div className='text-couponsRadioOPtions font-semibold text-3xl radio-btn-custom -ml-[15%]'>
                                                                            <FormikControl nextLineError={true} control='radio' name="actions" options={actionOptions}
                                                                                onChange={(value) => {
                                                                                    formik.setFieldValue('actions', value)
                                                                                    setactionSelected(value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {/* Batch Type */}
                                                                    <div className='flex w-[100%] justify-start items-center h-20 my-3'>
                                                                        <label className={`pl-4 text-left text-popupText font-normal text-font16`}> Mode: </label>
                                                                        
                                                                        <div className='text-couponsRadioOPtions font-semibold text-3xl radio-btn-custom -ml-[15%]'>
                                                                            <FormikControl nextLineError={true} control='radio' name="batchType" options={batchTypeOptions}
                                                                                onChange={(value) => {
                                                                                    formik.setFieldValue('batchType', value)
                                                                                    setModeSelected(value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )
                                            }
                                        }
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        {actionSelected === 'Create' && modeSelected &&
                            <div>
                                <CreateSku clientId={clientId} batchType={modeSelected} />
                            </div>
                        }

                        {actionSelected === 'Edit' && modeSelected &&
                            <div>
                                <EditSku clientId={clientId} batchType={modeSelected} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index