import dashboard from '../../../assets/svg/sidebar_icons_3/dashboard_icon.svg';
import dataSummary from '../../../assets/svg/sidebar_icons_3/data_icon.svg'

export default [
    {
        name:"dashboard",
        img: dashboard,
        desc: "Dashboard"
    },
    {
        name: "data-summary",
        img: dataSummary,
        desc: "Data Summary"
    }
]