import axios from '../../../../axios/axios';
import { handleError } from "../../../../utils/handles/errorHandle";

async function forgotPasswordRequestOtp(isMobile, emailId){
    try{
        const response = await axios.post(`/api/auth/sub-client/forgot-password/request`, {
            isMobile, emailId
        }, {
            // withCredentials: true
        });
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function forgotPasswordVerification(isMobile, emailId, otp) {
    try{
        const response = await axios.post('/api/auth/sub-client/forgot-password/verify', {
            isMobile, emailId, otp
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        });
        
        return {
            data: response.data,
            headers: response.headers
        };
    }catch(error){
        handleError(error);
    }
}

async function forgotPasswordReset(password){
    try{
        const response = await axios.post('/api/auth/sub-client/forgot-password/reset', { password }, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        });
        
        console.log(response);
        return response.data;
    }catch(error){
        console.log(error);
        handleError(error);
    }
}

export {
    forgotPasswordRequestOtp,
    forgotPasswordVerification,
    forgotPasswordReset
}