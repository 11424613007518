import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { motion, AnimatePresence, MotionConfig } from 'framer-motion';

import { FormHeadingComponent, FormSubHeadingComponent } from '../../../../components/ui/formSection';
import FormikControl from '../../../../components/form/formikControl';
import { SubmitButtonWide } from '../../../../components/ui/buttons/submitButton';
import Loader from '../../../../components/ui/Loader';
import PopUpManager from '../../../../components/popups2/popupManager';

import { radioOptions, batchTypeOptions } from "../../../../utils/QR/batchDropdowns";
import validationSchema from "../validations/validation";
import initialValues from '../utils/initialValues';

import useAuth from '../../../../hooks/useAuth';
import monthNameToNumber from '../utils/monthNameToNumber';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'

import ManufacturerForm, { manuForm } from './manufactureForm';
import RetailerForm, { retaForm } from './retailerForm';
import {getSkuData, getSkuIdsByBatchType, generateQrs} from "../services/api";

import { calculateShelfLifeChange } from '../../../../utils/QR/handleDateChanges';
import { URL_TYPES } from '../../../../config/constants';

function GenerateQRCodeForm() {
    const navigate = useNavigate();
    const {auth} = useAuth();
    const clientid = auth.masterClientId;
    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR);
    const [clickedSolution, setclickedSolution] = useState(true)
    const [clickedSkuDetails, setclickedSkuDetails] = useState(true)
    const [clickedQRDetails, setclickedQRDetails] = useState(true)
    const [clickedSolutionSub, setclickedSolutionContainer] = useState(false)
    const [clickedSkuDetailsSub, setclickedSkuDetailsSub] = useState(false)
    const [clickedQRDetailsSub, setclickedQRDetailsSub] = useState(false)
    const [clickedClientDetails, setClickedClientDetails] = useState(true)
    
    const [skuData, setSkuData] = useState(initialValues);
    const [skuIds, setSkuIds] = useState([])
    const [slectedSku_Id, setSelectedSku_Id] = useState('')
    
    const [loading, setLoading] = useState(false);
    const [showTextPopup, setShowTextPopup] = useState(false)
    const [alertText, setAlertText] = useState("");
    const [isBatchGenerating, setIsBatchGenerating] = useState(false);

    const handleClickClientDetailsHeading = (event) => {
        setClickedClientDetails(!event)
    }
    const handleClickSolutionHeading = (event) => {
        setclickedSolution(!event)
        setclickedSolutionContainer(false);
    }
    const handleClickSkudetails = (event) => {
        setclickedSkuDetails(!event)
        setclickedSkuDetailsSub(false);
    }
    const handleClickQRDetails = (event) => {
        setclickedQRDetails(!event)
        setclickedQRDetailsSub(false)
    }
    const handleClickSolutionSub = (event) => {
        setclickedSolutionContainer(!event)
    }
    const handleClickSkuDetailsSub = (event) => {
        setclickedSkuDetailsSub(!event)
    }
    const handleClickQRDetailsSub = (event) => {
        setclickedQRDetailsSub(!event)
    }

    const openAllTabs = () => {
        setClickedClientDetails(true);
        setclickedSolution(true);
        setclickedSkuDetails(true);
        setclickedQRDetails(true);
        setclickedSolutionContainer(true);
        setclickedSkuDetailsSub(true);
        setclickedQRDetailsSub(true);
    }

    //To open All subs by default
    useEffect(() => {
        setTimeout(() => {
            setclickedSolutionContainer(true)
            setclickedQRDetailsSub(true)
            setclickedSkuDetailsSub(true)
        }, 300)
    }, []);

    useEffect(() => {
        if(!isBatchGenerating) return;
        
        const handlePopEvent = (event) => {
            const confirmLeave = window.confirm(`If you navigate away from this screen, you won't be able to view the generated file. However, it will be saved under the 'Download QR' option once processing is complete. Are you sure you want to continue?`);
            if(!confirmLeave) {
                navigate('/new-batch-details');
            }
        }

        const handleBeforeUnLoad = (event) => {
            event.preventDefault();
            return(event.returnValue = '');
        }


        window.addEventListener('beforeunload', handleBeforeUnLoad, { capture: true })
        window.addEventListener('popstate', handlePopEvent, { capture: true });

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnLoad, { capture: true });
            setTimeout(() => {
                window.removeEventListener('popstate', handlePopEvent, { capture: true });
            }, 100);
        };
    }, [isBatchGenerating]);

    const handleSubmit = async (values) => {
        let batchData;

        if(values.batchType === "Manufacturer") {
            if(!manuForm.isFormValid()) return;
            batchData = Object.assign({}, {...values, ...manuForm.values});
        }

        if(values.batchType === "Retailer") {
            if(!retaForm.isFormValid) return;
            batchData = Object.assign({}, {...values, ...retaForm.values})
        }
        
        Object.keys(initialValues).forEach((key) => {
            batchData[key] = values[key];
        })

        if (batchData.type == 1 || batchData.warrantyApp == "false") {
            batchData.warrantyApp = 'false';
            batchData.warrantyPeriod = 0;
        }
        if (batchData.type != "3") {
            batchData.QROnprod = '';
            batchData.preSaleQRLocation = '';
        }
        if (batchData.warrantyApp === "true" && !batchData.warrantyPeriod) {
            setAlertText("Please enter the warranty period");
            setShowTextPopup(true);
            return
        }
        batchData.verb = "new"
        batchData.batchNo = batchData.batchid
        if(slectedSku_Id) batchData.skuId = slectedSku_Id;

        //parse dates
        if(values.batchType === "Manufacturer") {
            if(batchData.mfgDate.year && batchData.mfgDate.month) {
                if(batchData.shelfLife && batchData.expiryDate === 'notAvailable') {
                    const mfgDate = new Date(`${batchData.mfgDate.year} ${batchData.mfgDate.month} ${batchData.mfgDate.day && batchData.mfgDate.day !== '' && batchData.mfgDate.day !== 'DD' ? batchData.mfgDate.day : '01'}`);
                    const expiryDate = await  calculateShelfLifeChange({
                        shelfLife: batchData.shelfLife, mfgDate
                    });
    
                    batchData.expiryDate = {
                        year: expiryDate.getFullYear(), 
                        month: expiryDate.toLocaleString('default', { month: 'long' }), 
                        day: expiryDate.getDate()
                    };
                }

                batchData.mfgDate = `${batchData.mfgDate.year}-${monthNameToNumber(batchData.mfgDate.month)}-${batchData.mfgDate.day && batchData.mfgDate.day !== 'DD'? batchData.mfgDate.day : '01'}`
            }
            if(batchData.expiryDate.year && batchData.expiryDate.month) {
                const lastDay = new Date(batchData.expiryDate.year, monthNameToNumber(batchData.expiryDate.month), 0);
                batchData.expiryDate = `${batchData.expiryDate.year}-${monthNameToNumber(batchData.expiryDate.month)}-${batchData.expiryDate.day && batchData.expiryDate.day !== 'DD'? batchData.expiryDate.day : lastDay.getDate()}`
            }
        }

        batchData.clientId = auth.masterClientId;

        try {
            setLoading(true);
            setIsBatchGenerating(true);

            const qrCodeLinks = await generateQrs(batchData, axiosPrivate);
            navigate(`/generated-qr-codes`, { state: { qrCodeLinks, batchData } });
        } catch (error) {
            setAlertText(error.message || 'Unable to genreate QR codes');
            setShowTextPopup(true);
        } finally {
            setLoading(false);
            setIsBatchGenerating(false);
        }
    }

    const reqSkuData = async (values, skuid) => {
        if (skuid) {
            try {
                setLoading(true);
                const data = await getSkuData(clientid, skuid, axiosPrivate);

                setSkuData({ ...values, ...data[0], "warrantyApp": data[0].warrantyApp ? "true" : "false" });

                setAlertText(`${skuid} data autofilled`);
                setShowTextPopup(true);
            } catch (error) {
                setAlertText(error.message);
                setShowTextPopup(true);
            } finally {
                setLoading(false);
            }
        }
    }

    const validateManufacturerAndRetailerForms = (values) => {
        if(values.batchType === "Manufacturer") {
            manuForm.valideForm && manuForm.valideForm(); //This will active all manuform errors
        }

        if(values.batchType === "Retailer") {
            retaForm.valideForm && retaForm.valideForm(); //This will active all retaForm errors
        }
    }

    const validateForm = (values) => {
        const { totalQRGen, trackingQr_tert, trackingQr_quat } = values;
        if (!totalQRGen && !trackingQr_tert && !trackingQr_quat) {
            return {
                totalQRGen: 'At least one field is required',
                trackingQr_tert: 'At least one field is required',
                trackingQr_quat: 'At least one field is required',
            };
        }
        return {};
    };

    const handleBatchChange = async (formik, batchtype) => {
        try {
            setLoading(true);
            const ids = await getSkuIdsByBatchType(clientid, batchtype ,axiosPrivate);
            setSkuIds(ids);
        } catch (error) {
            setAlertText(error.message);
            setShowTextPopup(true);
        } finally {
            setLoading(false);
        }
    }

    const animationSetUp = {
        initial: { height: 0, opacity: 0, overflow: 'hidden' },
        animate: { height: 'auto' , opacity: 1 },
        exit: { height: 0, opacity: 0},
    }

    return (
        <>
        {loading && <Loader />}
        <PopUpManager 
            control = {'text'}
            disable = {!showTextPopup}
            heading = {`Alert`}
            description = {alertText}
            onClose = {() => {
                setShowTextPopup(false)
            }}
        />
        <Formik
            initialValues={skuData || initialValues}
            enableReinitialize={true}
            validate={validateForm}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}

            // validateOnBlur={false}
            // validateOnChange={true}
        >
            {
                formik => {
                    return (
                        <Form>
                            <MotionConfig
                                transition={{
                                    duration: 0.25,
                                    ease: 'easeInOut'
                                }}
                            >
                            <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                <div onClick={() => handleClickClientDetailsHeading(clickedClientDetails)}>
                                    <FormHeadingComponent name={"Batch Type"} clicked={clickedClientDetails} />
                                </div>
                                <AnimatePresence>
                                {
                                    clickedClientDetails && 
                                    <motion.div 
                                        {...animationSetUp}
                                        className='bg-white rounded-b-[12px]'
                                    >
                                        <div className='mt-7 mb-5 flex items-center gap-10 text-couponsRadioOPtions font-semibold text-3xl radio-btn-custom bg-white pb-5 rounded-b-[12px]'>
                                            <h5 className='text-sideBarBtnText font-medium pl-5 inline'> Mode: </h5>
                                            <div className='-ml-14'>
                                                <FormikControl control='radio' name="batchType" options={batchTypeOptions}
                                                    onChange={async (value) => {
                                                        await formik.setFieldValue('batchType', value)
                                                        await handleBatchChange(formik, value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </motion.div>
                                }
                                </AnimatePresence>
                            </div>

                            <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                <div onClick={() => handleClickSolutionHeading(clickedSolution)}>
                                    <FormHeadingComponent name={"Solution"} clicked={clickedSolution} />
                                </div>
                                <AnimatePresence>
                                {clickedSolution && 
                                    <motion.div 
                                        {...animationSetUp}
                                        className='flex flex-col rounded-b-[12px]' 
                                    >
                                        <div onClick={() => handleClickSolutionSub(clickedSolutionSub)}>
                                            <FormSubHeadingComponent name={"Choose Solution"} clicked={clickedSolutionSub} />
                                        </div>
                                        <AnimatePresence>
                                        {
                                            clickedSolution && clickedSolutionSub &&
                                            <motion.div 
                                                {...animationSetUp}
                                                className='flex items-center gap-10 text-couponsRadioOPtions font-semibold text-3xl radio-btn-custom bg-white rounded-b-[12px]'
                                            >
                                                <FormikControl 
                                                    control='radio' 
                                                    name="type" 
                                                    options={radioOptions} 
                                                    onChange={(value) => {
                                                        formik.setFieldValue('type', value)
                                                    }}
                                                    mdB={'5'}
                                                />
                                            </motion.div>
                                        }
                                        </AnimatePresence>
                                    </motion.div>
                                }
                                </AnimatePresence>
                            </div>
                            
                            {
                                skuIds && skuIds.length > 1 && formik.values.batchType && 
                                    <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                        <div onClick={() => handleClickSkudetails(clickedSkuDetails)}>
                                            <FormHeadingComponent name={"SKU Details"} clicked={clickedSkuDetails} />
                                        </div>
                                        <AnimatePresence>
                                        {
                                            clickedSkuDetails &&
                                            <motion.div 
                                                {...animationSetUp} 
                                                className='bg-bgWhite rounded-b-[12px]'
                                            >
                                                <div onClick={() => handleClickSkuDetailsSub(clickedSkuDetailsSub)}>
                                                    <FormSubHeadingComponent name={"Select the sku id from drop down"} clicked={clickedSkuDetailsSub} />
                                                </div>
                                                <AnimatePresence>
                                                {clickedSkuDetailsSub &&
                                                    <motion.div 
                                                        {...animationSetUp}
                                                        className='bg-bgWhite border-solid border-navBorder rounded-b-[12px] w-1/2 dropdown-icon'
                                                    >
                                                        <div className='mb-6 ml-12'>
                                                        <FormikControl
                                                            control='select'
                                                            label='SKU ID'
                                                            name='skuId'
                                                            options={skuIds}
                                                            bgcolor={'selectorBg'}
                                                            onChange={(e) => {
                                                                const _skuId = e.target.value;
                                                                formik.setFieldValue('skuId', _skuId)
                                                                reqSkuData(formik.values, _skuId)

                                                                skuIds.forEach(sku => {
                                                                    if(sku.value === _skuId) {
                                                                        console.log(sku._id)
                                                                        setSelectedSku_Id(sku._id);
                                                                    }
                                                                });
                                                            }}
                                                            height={'[46px]'}
                                                            radius={'[5px]'}
                                                        />
                                                        </div>
                                                    </motion.div>
                                                }
                                                </AnimatePresence>
                                            </motion.div>
                                        }
                                        </AnimatePresence>
                                    </div>
                            } 

                            {
                                formik.values.batchType === "Manufacturer" && <ManufacturerForm type={formik.values.type} skuData={skuData} animationSetUp={animationSetUp} />
                            }                                                    

                            {
                                formik.values.batchType === "Retailer" && <RetailerForm type={formik.values.type} skuData={skuData} animationSetUp={animationSetUp} />
                            }

                            {
                                formik.values.batchType && 
                                <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                    <div onClick={() => { handleClickQRDetails(clickedQRDetails) }}>
                                        <FormHeadingComponent name={"QR Details"} clicked={clickedQRDetails} />
                                    </div>
                                    <AnimatePresence>
                                    {
                                        clickedQRDetails &&
                                        <motion.div 
                                            {...animationSetUp}  
                                            className='bg-bgWhite rounded-b-[12px]'
                                        >
                                            <div onClick={() => handleClickQRDetailsSub(clickedQRDetailsSub)}>
                                                <FormSubHeadingComponent name={"Provide Manufacturing Details "} clicked={clickedQRDetailsSub} extraText='No of tracking QR code:' />
                                            </div>
                                            <AnimatePresence>
                                            {
                                                clickedQRDetailsSub &&
                                                <motion.div 
                                                    {...animationSetUp}  
                                                >
                                                    <div className='w-[100%] grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 rounded-b-[12px] py-10 provide-manufacturing-details'>
                                                        <FormikControl control='input' type='number' label={"No of Product QR's:"} name={'totalQRGen'} bgcolor={'selectorBg'} />
                                                        <FormikControl control='input' type='number' label={"level 1 :"} name={'trackingQr_tert'} bgcolor={'selectorBg'} width='1/2' />
                                                        <div></div>
                                                        <FormikControl control='input' type='number' label={"level 2  :"} name={'trackingQr_quat'} bgcolor={'selectorBg'} width='1/2' />
                                                    </div>
                                                </motion.div>
                                            }
                                            </AnimatePresence>
                                        </motion.div>
                                    }
                                    </AnimatePresence>
                                </div>
                            }

                            <div className='h-40'>
                                <SubmitButtonWide value={"Process"} type={'submit'} onClick={() => {
                                    validateManufacturerAndRetailerForms(formik.values)
                                    openAllTabs()
                                }} />
                            </div>
                            </MotionConfig>
                        </Form>
                    )
                }
            }

        </Formik>
    </>
    )
}

export default GenerateQRCodeForm