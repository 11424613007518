import React, { useEffect, useState } from 'react'
import { Header } from '../../../../layouts/header'
import Navbar from '../../../../layouts/navbar'
import Sidebar from '../../../../layouts/sidebar'
import sidebarData from '../../utils/sidebarData';
import ManuBatchAppend from './components/manuBatchAppend'
import RetaBatchAppend from './components/retaBatchAppend'
import { useParams } from 'react-router-dom'

import PopUpManager from '../../../../components/popups2/popupManager'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';

import { getBatchData } from "../services/api";
import useAuth from '../../../../hooks/useAuth'
import Loader from '../../../../components/ui/Loader';
import useSidebar from '../../../../hooks/useSidebar';
import { URL_TYPES } from '../../../../config/constants';

function Index() {
    const { isSidebarOpen, toggleSidebar } = useSidebar(URL_TYPES.QR);

    const { auth } = useAuth();
    const clientid = auth.masterClientId;
    const { batchno } = useParams();
    const [ rawBatchData, setRawBatchData] = useState(undefined);
    const axiosPrivate = useAxiosPrivate();
    const [ loading, setLoading ] = useState(false);
    const [ textPopupDisabled, setTextPopupDisabled ] = useState(true);
    const [ textPopupDescription, setTextPopupDescription ] = useState('');

    const navData = [
        {
            name: 'Qr',
            link: '/new-batch-details'
        },
        {
            name: 'Batch Append',
            link: `/batch-actions/append/${batchno}`
        }
    ];


    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                const data = await getBatchData(clientid, batchno, axiosPrivate);
                setRawBatchData(data);
            } catch (error) {
                setTextPopupDescription(error.message || 'Unable to load batch Data');
                setTextPopupDisabled(false);
            } finally {
                setLoading(false);
            }
        }
        if(clientid && batchno)
            getData();
    }, [clientid, batchno]);

    return (
        <>
            { loading && <Loader /> }
            <PopUpManager 
                disable={textPopupDisabled}
                control='text'
                heading='Alert'
                description={textPopupDescription}
                onClose={() => setTextPopupDisabled(true)}
            />
                
            <div className='layout-wrapper'>
                <div className='top-header w-full z-10 bg-#FFFFFF'>
                    <Header toggleSidebar={toggleSidebar} />
                </div>

                <div className='content-wrapper flex flex-row'>
                    <div className={`sidebar bg-white ${isSidebarOpen ? 'open' : ''}`}>
                        <Sidebar details={sidebarData} />
                    </div>

                    <div className='w-full bg-bgLightBlue page-content'>
                        <div className='px-[20px]'>
                            <div className='h-10 flex items-center'>
                                <Navbar navData={navData} />
                            </div>
                            <div className='text-generateQRheading font-bold h-16  flex justify-start items-center'>
                                <h5>Append QRs</h5>
                            </div>
                            <div>
                                <div className=''>
                                    {
                                        rawBatchData?.batchType && rawBatchData.batchType == 'Manufacturer' &&
                                        <ManuBatchAppend clientid={clientid} batchno={batchno} rawBatchData={rawBatchData} />
                                    }
                                    {
                                        rawBatchData?.batchType && rawBatchData.batchType == 'Retailer' &&
                                        <RetaBatchAppend clientid={clientid} batchno={batchno} rawBatchData={rawBatchData} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        </>
    )
}

export default Index