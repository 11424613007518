const radioOptions = [
    { key: 'Info', value: '1' },
    { key: 'Overt', value: '2' },
    { key: 'Covert', value: '3' }
]

const batchTypeOptions = [
    { key: 'Manufacturer', value: 'Manufacturer' },
    { key: 'Retailer', value: 'Retailer' },
]

const warrantyDropDown = [
    { key: 'Select an option', value: '' },
    { key: 'Yes', value: "true" },
    { key: 'No', value: "false" }
]

export {
    radioOptions,
    batchTypeOptions,
    warrantyDropDown
}