import { React, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik';
import { motion, AnimatePresence, MotionConfig } from 'framer-motion';

import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { FormHeadingComponent, FormSubHeadingComponent } from '../../../../../components/ui/formSection';
import { SubmitButtonWide } from '../../../../../components/ui/buttons/submitButton';
import FormikControl from '../../../../../components/form/formikControl';
import Loader from '../../../../../components/ui/Loader';
import PopUpManager from '../../../../../components/popups2/popupManager';

import { warrantyDropDown } from '../../../../../utils/QR/batchDropdowns';
import manuIntialValues from '../utils/manuInitialValues';
import { URL_TYPES } from '../../../../../config/constants';
import { addBatch } from '../../services/api';

function BatchAppend(props) {

    const { clientid, batchno, rawBatchData} = props;
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR);

    const [isLoading, setIsLoading] = useState(false); 
    const [isErrPopupOpen, setIsErrPopupOpen] = useState(false);
    const [errPopupText, setErrPopupText] = useState('');
    const [isBatchGenerating, setIsBatchGenerating] = useState(false);

    const [clickedProductDetails, setclickedProductDetails] = useState(true)
    const [clickedManufacturingDetails, setclickedManufacturingDetails] = useState(true)
    const [clickedQRDetails, setclickedQRDetails] = useState(true)
    const [clickedProductDetailsSub, setclickedProductDetailsSub] = useState(false)
    const [clickedManufacturingsub, setclickedManufacturingsub] = useState(false)
    const [clickedQRDetailsSub, setclickedQRDetailsSub] = useState(false)

    const [batchData, setBatchData] = useState({});

    const handleClickProductDetails = (event) => {
        setclickedProductDetails(!event)
        setclickedProductDetailsSub(false)
    }
    const handleClickManufacturingDetails = (event) => {
        setclickedManufacturingDetails(!event)
        setclickedManufacturingsub(false)
    }
    const handleClickQRDetails = (event) => {
        setclickedQRDetails(!event)
        setclickedQRDetailsSub(false)
    }
    const handleClickProductDetailsSub = (event) => {
        setclickedProductDetailsSub(!event)
    }
    const handleClickManufacturingsub = (event) => {
        setclickedManufacturingsub(!event)
    }
    const handleClickQRDetailsSub = (event) => {
        setclickedQRDetailsSub(!event)
    }

    const openAllTabs = () => {
        setclickedProductDetails(true)
        setclickedManufacturingDetails(true)
        setclickedQRDetails(true)
        setclickedProductDetailsSub(true)
        setclickedManufacturingsub(true)
        setclickedQRDetailsSub(true)
    }

    useEffect(() => {
        setTimeout(() => {
            setclickedProductDetailsSub(true)
            setclickedManufacturingsub(true)
            setclickedQRDetailsSub(true)
        }, 300)
    }, []);

    useEffect(() => {
        if(!isBatchGenerating) return;
        
        const handlePopEvent = (event) => {
            const confirmLeave = window.confirm(`If you navigate away from this screen, you won't be able to view the generated file. However, it will be saved under the 'Download QR' option once processing is complete. Are you sure you want to continue?`);
            if(!confirmLeave) {
                navigate('/new-batch-details');
            }
        }

        const handleBeforeUnLoad = (event) => {
            event.preventDefault();
            return(event.returnValue = '');
        }


        window.addEventListener('beforeunload', handleBeforeUnLoad, { capture: true })
        window.addEventListener('popstate', handlePopEvent, { capture: true });

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnLoad, { capture: true });
            setTimeout(() => {
                window.removeEventListener('popstate', handlePopEvent, { capture: true });
            }, 100);
        };
    }, [isBatchGenerating]);

    const handleSubmit = async (values) => {
        let batchData = Object.assign({}, values);
        if (batchData.type === 1 || batchData.warrantyApp === "false") {
            batchData.warrantyApp = 'false';
            batchData.warrantyPeriod = 0;
        }
        if (batchData.type !== 3) {
            batchData.QROnprod = '';
            batchData.preSaleQRLocation = '';
        }
        
        batchData.verb = "add";
        batchData.clientId = clientid;
        batchData.batchNo = batchno;

        batchData["totalQRGen"] = values.totalQRGen;
        batchData["trackingQr_tert"] = values.trackingQr_tert;
        batchData["trackingQr_quat"] = values.trackingQr_quat;

        try {
            setIsLoading(true);
            setIsBatchGenerating(true);

            const qrCodeLinks = await addBatch(batchData, axiosPrivate);
            navigate(`/generated-qr-codes`, { state: { qrCodeLinks, batchData } });
        } catch (error) {
            setErrPopupText(error.message || 'Unable to genreate QR codes');
            setIsErrPopupOpen(true);
        } finally {
            setIsLoading(false);
            setIsBatchGenerating(false);
        }
    }

    const validateForm = (values) => {
        const { totalQRGen, trackingQr_tert, trackingQr_quat } = values;
        if (!totalQRGen && !trackingQr_tert && !trackingQr_quat) {
            return {
                totalQRGen: 'At least one field is required',
                trackingQr_tert: 'At least one field is required',
                trackingQr_quat: 'At least one field is required',
            };
        }
        return {};
    };

    const [mfgNoDateMessage, setMfgNoDateMessage] = useState('');
    const [expNoDateMessage, setExpNoDateMessage] = useState('');

    useEffect(() => {
        const data = {
            ...manuIntialValues,
            ...rawBatchData,
            mfgDate: rawBatchData.mfgDate != "notAvailable" ? new Date(rawBatchData.mfgDate) : rawBatchData.mfgDate,
            expiryDate: rawBatchData.expiryDate != "notAvailable" && rawBatchData.expiryDate != 'notApplicable' ? new Date(rawBatchData.expiryDate)  : rawBatchData.expiryDate,
            warrantyApp: rawBatchData.warrantyApp.toString()
        }

        // mfgDate: rawBatchData.mfgDate != "notAvailable" ? new Date(rawBatchData.mfgDate) : rawBatchData.mfgDate,
        // expiryDate: rawBatchData.expiryDate != "notAvailable" ? new Date(rawBatchData.expiryDate)  : rawBatchData.expiryDate,

        if(rawBatchData.mfgDate == 'notAvailable') setMfgNoDateMessage('Not Available')

        if(rawBatchData.expiryDate == 'notAvailable') setExpNoDateMessage('Not Available')
        if(rawBatchData.expiryDate == 'notApplicable') setExpNoDateMessage('Not Applicable')
        setBatchData(data)
    }, [rawBatchData]);

    const animationSetUp = {
        initial: { height: 0, opacity: 0, overflow: 'hidden' },
        animate: { height: 'auto' , opacity: 1 },
        exit: { height: 0, opacity: 0},
    }

    return (
        <>
            {
                isLoading && <Loader />
            }
            <PopUpManager 
                control={'text'}
                disable={!isErrPopupOpen}

                heading={'Error'}
                description={errPopupText}
                onClose={() => setIsErrPopupOpen(false)}
            />
            <Formik
                initialValues={batchData || manuIntialValues}
                enableReinitialize={true}
                validate={validateForm}
                onSubmit={handleSubmit}
            >
                {
                    formik => {
                        return (
                            <Form>
                                <MotionConfig
                                    transition={{
                                        duration: 0.25,
                                        ease: 'easeInOut'
                                    }}
                                >
                                <div>
                                    <div className='pb-20'>
                                        <div onClick={() => handleClickProductDetails(clickedProductDetails)}>
                                            <FormHeadingComponent name={"Product Details"} clicked={clickedProductDetails} />
                                        </div>
                                        <AnimatePresence>
                                        {
                                            clickedProductDetails &&
                                            <motion.div 
                                                {...animationSetUp} 
                                                className='bg-bgWhite w-full rounded-b-[12px]'
                                            >
                                                <div onClick={() => handleClickProductDetailsSub(clickedProductDetailsSub)}>
                                                    <FormSubHeadingComponent name={"Provide Product Details"} clicked={clickedProductDetailsSub} />
                                                </div>
                                                <AnimatePresence>
                                                {
                                                    clickedProductDetailsSub &&
                                                    <motion.div 
                                                        {...animationSetUp} 
                                                        className='bg-bgWhite rounded-b-[12px]'
                                                    >
                                                    <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 pl-10 pr-10 pb-10'>
                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            label={"Product Image :"}
                                                            name='Product-image'
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            defaultImageUrl={formik.values.Pimage}
                                                            disabled
                                                        />
                                                        
                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            defaultImageUrl={formik.values.expiryImage}
                                                            label={"Expiry Product :"}
                                                            name={'expiry-prod'}
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            customerror={formik.touched.expiryImage && formik.errors.expiryImage}
                                                            onFileSelect={(url) => {
                                                                formik.setFieldValue("expiryImage", url);
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        formik.values.type == '3' && <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 pl-10 pr-10 pb-10'>
                                                            <FormikControl
                                                                control='file'
                                                                type='file'
                                                                label={"Hidden QR on Product :"}
                                                                name={'hiddend-qr-on-prod'}
                                                                acceptableFiles='.jpg,.png,.jpeg'
                                                                defaultImageUrl={formik.values.QROnprod}
                                                                disabled
                                                            />

                                                            <FormikControl
                                                                control='file'
                                                                type='file'
                                                                label={"Outer QR on Box :"}
                                                                name={'outer-qr-on-box'}
                                                                acceptableFiles='.jpg,.png,.jpeg'
                                                                defaultImageUrl={formik.values.preSaleQRLocation}
                                                                disabled
                                                            />
                                                        </div>
                                                    }
                                                    <div className='pb-10 grid grid-cols-2 gap-x-20 gap-y-10 pl-10 pr-10'>
                                                        <FormikControl control='input' type='text' label={"Brand :"} name={'brand'} disabled bgcolor={'slate-50'} />
                                                        <FormikControl control='input' type='text' label={"Product Name :"} name={"prodName"} disabled bgcolor={'slate-50'} />
                                                        <FormikControl control='input' type='text' label={"Batch number :"} name={"batchNo"} disabled bgcolor={'slate-50'} />
                                                        <FormikControl noDateMessage={mfgNoDateMessage}  control='date' type='date' label={"Mfg Date :"} name={"mfgDate"} fieldWidth={''} disabled bgcolor={'slate-50'} />
                                                        <FormikControl control='input' type='number' label={"MRP. :"} name={"mrp"} disabled bgcolor={'slate-50'} />
                                                        <FormikControl control='input' type='text' label={"Serial No. :"} name={"serialNo"} disabled bgcolor={'slate-50'} />
                                                        <FormikControl control='input' type='text' label={"Shelf Life in days:"} name={"shelfLife"} disabled bgcolor={'slate-50'} />
                                                        <FormikControl noDateMessage={expNoDateMessage} control='date' type='date' label={"Expiry Date:"} name={"expiryDate"} fieldWidth={''} disabled bgcolor={'slate-50'} />
                                                        {
                                                            formik.values.type !== '1' &&
                                                            <>
                                                                <FormikControl
                                                                    control='select'
                                                                    className="dropdown-icon"
                                                                    label='Warranty Applicable'
                                                                    name='warrantyApp'
                                                                    options={warrantyDropDown}
                                                                    height={'[46px]'}
                                                                    padX={'10'}
                                                                    disabled
                                                                    value={formik.values.warrantyApp}
                                                                />
                                                                {
                                                                    formik.values.warrantyApp === "true" &&
                                                                    <FormikControl control='input' type='number' label={"Warranty in days"} name={"warrantyPeriod"} disabled bgcolor={'slate-50'} />
                                                                    
                                                                }
                                                                {
                                                                    formik.values.warrantyApp === "true" && <div className='col-span-full md:col-span-2'>
                                                                        <div className='grid grid-cols-1 gap-y-10 rounded-[12px] qr-manufacturing-details'>
                                                                        <FormikControl disabled control='input-textarea-large' width={'full'} label={"Warranty T&C"} name={"warrantyTC"} bgcolor='slate-50' />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                    </motion.div>
                                                }
                                                </AnimatePresence>
                                            </motion.div>
                                        }
                                            </AnimatePresence>
                                    </div>

                                    <div className=''>
                                        <div onClick={() => { handleClickManufacturingDetails(clickedManufacturingDetails) }}>
                                            <FormHeadingComponent name={"Manufacturing Details"} clicked={clickedManufacturingDetails} />
                                        </div>
                                        <AnimatePresence>
                                        {
                                            clickedManufacturingDetails &&
                                            <motion.div 
                                                {...animationSetUp} 
                                                className='bg-bgWhite rounded-b-[12px]'
                                            >
                                                <div onClick={() => handleClickManufacturingsub(clickedManufacturingsub)}>
                                                    <FormSubHeadingComponent name={"Provide Manufacturing Details "} clicked={clickedManufacturingsub} />
                                                </div>
                                                <AnimatePresence>
                                                {
                                                    clickedManufacturingsub &&
                                                    <motion.div 
                                                        {...animationSetUp} 
                                                    >
                                                    <div className='px-[30px] pb-20 grid grid-cols-1 gap-y-10 rounded-[12px] qr-manufacturing-details'>
                                                        <FormikControl
                                                            control='input-textarea-small'
                                                            width={'full'}
                                                            type='description'
                                                            label={"Manufacturing license no :"}
                                                            name={'manuLicenseNo'}
                                                            placeholder={"Type your text here"}
                                                            bgcolor='slate-50'
                                                            disabled />
                                                        <FormikControl
                                                            control='input-textarea-large'
                                                            width={'full'}
                                                            type='description'
                                                            label={"Manufacturing Address :"}
                                                            name={'manuAdd'}
                                                            placeholder={"Type your text here"}
                                                            bgcolor='slate-50'
                                                            disabled />
                                                        <FormikControl
                                                            control='input-textarea-large'
                                                            width={'full'}
                                                            type='description'
                                                            label={"Additional Details :"}
                                                            name={'addDetails'}
                                                            placeholder={"Type your text here"}
                                                            bgcolor='slate-50'
                                                            disabled />
                                                        <FormikControl
                                                            control='input-textarea-small'
                                                            width={'full'}
                                                            type='description'
                                                            label={"Image or Video Header :"}
                                                            name={'additionalImageDetails'}
                                                            placeholder={"image header"}
                                                            bgcolor='slate-50'
                                                            disabled
                                                        />
                                                        <div className='flex flex-wrap md:flex-nowrap mb-[25px] md:mb-0 items-center h-[100%] gap-5 generate-img'>
                                                            <div className='generate-img-upload-wrapper w-[100%]'>
                                                                <div className='flex sm:flex-col md:flex-row flex-wrap w-[100%]'>
                                                                    <FormikControl
                                                                        control='file'
                                                                        type='file'
                                                                        label={""}
                                                                        name='extra-Image-1'
                                                                        disabled={true}
                                                                        defaultImageUrl={formik.values.extraImage1}
                                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                                        pdY={'1rem'}
                                                                />
                                                                    <FormikControl
                                                                        control='file'
                                                                        type='file'
                                                                        label={""}
                                                                        name='extra-Image-2'
                                                                        disabled={true}
                                                                        defaultImageUrl={formik.values.extraImage2}
                                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                                        pdY={'1rem'}
                                                                />
                                                                    <FormikControl
                                                                        control='video'
                                                                        type='file'
                                                                        label={""}
                                                                        name='extra-video-1'
                                                                        disabled={true}
                                                                        defaultVideoUrl={formik.values.extraVideo1}
                                                                        acceptableFiles='.mp4'
                                                                        pdY={'1rem'}
                                                                />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <FormikControl
                                                            control='input-textarea-small'
                                                            width={'full'}
                                                            type='description'
                                                            label={"Website Link :"}
                                                            name={'manuWebsiteLink'}
                                                            placeholder={"Type your text here"}
                                                            bgcolor='slate-50'
                                                            disabled />
                                                    </div>
                                                    </motion.div>
                                                }
                                                </AnimatePresence>
                                            </motion.div>
                                        }
                                        </AnimatePresence>
                                    </div>

                                    <div className='mt-20 mb-20'>
                                        <div onClick={() => { handleClickQRDetails(clickedQRDetails) }}>
                                            <FormHeadingComponent name={"QR Details"} clicked={clickedQRDetails} />
                                        </div>
                                        <AnimatePresence>
                                        {
                                            clickedQRDetails &&
                                            <motion.div 
                                                {...animationSetUp} 
                                                className='bg-bgWhite rounded-b-xl'
                                            >
                                                <div onClick={() => handleClickQRDetailsSub(clickedQRDetailsSub)}>
                                                    <FormSubHeadingComponent name={"Provide Manufacturing Details "} clicked={clickedQRDetailsSub} extraText='No of tracking QR code:' />
                                                </div>
                                                <AnimatePresence>
                                                {
                                                    clickedQRDetailsSub &&
                                                    <motion.div 
                                                        {...animationSetUp} 
                                                    >
                                                    <div className='w-[100%] grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 rounded-b-[12px] py-10 provide-manufacturing-details'>
                                                        <FormikControl control='input' type='number' label={"No of Product QR's:"} name={'totalQRGen'} bgcolor={'selectorBg'} />
                                                        <FormikControl control='input' type='number' label={"level 1 :"} name={'trackingQr_tert'} bgcolor={'selectorBg'} width='1/2' />
                                                        <div></div>
                                                        <FormikControl control='input' type='number' label={"level 2  :"} name={'trackingQr_quat'} bgcolor={'selectorBg'} width='1/2' />
                                                    </div>
                                                    </motion.div>
                                                }
                                                </AnimatePresence>
                                            </motion.div>
                                        }
                                        </AnimatePresence>
                                    </div>

                                    <div className='h-40'>
                                        <SubmitButtonWide value={"Process"} type={'submit'} onClick={openAllTabs} />
                                    </div>
                                </div>
                                </MotionConfig>
                            </Form>
                        )
                    }
                }

            </Formik>
            {/* <PopUpScreen header = {popupScreenView} title = {""}/> */}
        </>
    )
}

export default BatchAppend