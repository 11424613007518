import React from "react";
import { Header } from "../../../layouts/header"
import Sidebar from '../../../layouts/sidebar'
import Navbar from '../../../layouts/navbar'
import sideBarData from "../utils/sidebardata"
import useSidebar from '../../../hooks/useSidebar';
import Profile from "./components/customerProfile";

import navData from "./utils/navData";

function Index() {
    const { isSidebarOpen, toggleSidebar } = useSidebar();

    return (
        <div className='layout-wrapper'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>

            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sideBarData} />
                </div>
                
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className='px-[20px]'>
                        <div className='h-10 flex items-center'>
                            <Navbar navData={navData} />
                        </div>
                            <Profile />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index