import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import useAuth from '../../../../hooks/useAuth';
import FormikControl from '../../../../components/form/formikControl';
import { SubmitButton } from '../../../../components/ui/buttons/submitButton';
import PopUpManager from '../../../../components/popups2/popupManager';
import Loader from '../../../../components/ui/Loader/Loader';

import useUserData from '../../../../hooks/useUserData';
import { getDataSummary } from '../services/api';

function createRangeArray(start, end) {
    return Array.from({ length: end - start + 1 }, (v, i) => start + i);
}

const DataSummary = () => {

    const { auth } = useAuth();
    const { user } = useUserData();
    const axiosPrivate = useAxiosPrivate();
    const [downloadLink, setDownloadLink] = useState('');

    //Popup
    const [loading, setLoading] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [textPopupDisabled, setTextPopupDisabled] = useState(true);

    //Values
    const typeOptions = [
        {key: '-- Select Type --', value: ''},
        {key: 'Tracking Insights', value: 'Client_Tracking_Insights'},
        {key: 'Business Insights', value: 'Client_Business_Insights'},
        // {key: 'Finance Insights', value: 'Admin_Finance_Insights'},
    ];


    const yearOptions = [
        {key: '-- Select Type --', value: ''},
        ...createRangeArray(new Date(user?.contractDate).getFullYear(), new Date().getFullYear()).map(year => ({key: year, value: year}))
    ] 
    
    const monthOptions = [
        {key: '-- Select Month --', value: ''},
        { key: 'January', value: 'Jan' },
        { key: 'February', value: 'Feb' },
        { key: 'March', value: 'Mar' },
        { key: 'April', value: 'Apr' },
        { key: 'May', value: 'May' },
        { key: 'June', value: 'Jun' },
        { key: 'July', value: 'Jul' },
        { key: 'August', value: 'Aug' },
        { key: 'September', value: 'Sep' },
        { key: 'October', value: 'Oct' },
        { key: 'November', value: 'Nov' },
        { key: 'December', value: 'Dec' }
    ];

    const initialValues = {
        type: '',
        year: '',
        month: '',
    }

    const validationSchema = Yup.object({
        type: Yup.string().required('This field is required'),
        year: Yup.string().required('This field is required'),
        month: Yup.string().required('This field is required'),
    })

    const handleSubmit = async (values) => {
        console.log('hii');
        try {
            setLoading(true)
            const blobData = await getDataSummary(values.type, values.year, values.month, auth.masterClientId, axiosPrivate);
            const blobUrl = URL.createObjectURL(blobData);
            setDownloadLink(blobUrl);
        } catch (error) {
            setAlertText(error.message || 'Unable to fetch Data');
            setTextPopupDisabled(false);
        } finally {
            setLoading(false)
        }
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = downloadLink;
        link.target = '_blank';

        link.click();
    }
 
    return (
        <>
            { loading && <Loader /> }
            <PopUpManager 
                disable={textPopupDisabled}
                control={"text"}
                heading={"Alert"}
                description={alertText}
                onClose={() => setTextPopupDisabled(true)}
            />
            <div className='bg-white w-full rounded-[12px] p-10'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {
                        formik => {
                            return (
                                <Form className="bg-white rounded-3xl w-full p-10 flex flex-col gap-10 items-end">
                                    <div className='w-full flex flex-col gap-10'>
                                        <div className="profile-search items-center w-1/2 pr-5">
                                            <FormikControl 
                                                control='select'
                                                name='type'
                                                bgcolor={'selectorBg'}
                                                label='Type: '
                                                options={typeOptions}
                                                onChange={e => {
                                                    setDownloadLink('');
                                                    formik.setFieldValue('type', e.target.value);
                                                }}
                                            />
                                        </div>

                                        <div className='w-full flex gap-10'>
                                            <div className="profile-search items-center w-1/2">
                                                <FormikControl 
                                                    control='select'
                                                    name='year'
                                                    bgcolor={'selectorBg'}
                                                    label='Year: '
                                                    options={yearOptions}
                                                    onChange={e => {
                                                        setDownloadLink('');
                                                        formik.setFieldValue('year', e.target.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="profile-search items-center w-1/2">
                                                <FormikControl 
                                                    control='select'
                                                    name='month'
                                                    bgcolor={'selectorBg'}
                                                    label='Month: '
                                                    options={monthOptions}
                                                    onChange={e => {
                                                        setDownloadLink('');
                                                        formik.setFieldValue('month', e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h-full w-full flex justify-start">
                                        <div className='w-[25rem]'>
                                            <SubmitButton value={'Submit'} btnWidth={'[100%]'} type={'submit'} />
                                        </div>
                                    </div>

                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>

            {
                downloadLink && <div className='mt-10 bg-white rounded-[12px] mx-[20%]'>
                    <SubmitButton 
                        value={'Download'} 
                        btnWidth={'[100%]'} 
                        type={'submit'} 
                        onClick={handleDownload}
                        />
                </div>
            }
        </>
    );
}

export default DataSummary;
