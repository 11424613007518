class APIError extends Error {
    constructor(message, status, headers, data) {
        super(message);
        this.status = status;
        this.headers = headers;
        this.data = data;
    }
}

function handleError(error) {
    console.log(error, "uni error");
    throw new APIError(error?.response?.data?.errorMessage || error?.response?.data?.message || error?.response?.errorMessage || error?.errorMessage || error?.message || 'Something went wrong!', error?.response?.status, error?.response?.headers, {});
}

export {
    handleError
}