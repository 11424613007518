import { handleError } from "../utils/handles/errorHandle";

async function clientLogout(axiosPrivate){
    try{
        const res = await axiosPrivate.post('/api/auth/sub-client/logout', {} , {
            withCredentials: true
        })

        return res.data;
    }catch(error){
        handleError(error)
    }
}

async function getSubClinetInfo(axiosPrivate){
    try{
        const response = await axiosPrivate.get(`/api/client/fetch/sub-client-info`);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

export {
    clientLogout,
    getSubClinetInfo
}