import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './textError'

function InputTextBox(props) {
  const { label, name, placeholder, bgColor, placeholderColor, width, height, ...rest } = props

  return (
    <div className='flex h-[96px] w-full'>
      <div className='md:flex h-[96px] w-full'>
        {label && <label htmlFor={name} control='input' className='w-[45%] text-sideBarBtnText text-3xl font-medium pt-5'>{label}</label>}
        <div className='flex flex-wrap w-full'>
          <Field as='textarea' id={name} name={name} {...rest} className={`w-full ${bgColor ? `bg-${bgColor}` : 'bg-selectorBg'} ${placeholderColor ? `placeholder-${placeholderColor}` : 'placeholder-sideBarBtnText'} ${width ? `w-${width}` : 'w-[65%]'} h-${height} px-[20px] py-3 placeholder-top-0 placeholder-left-0 text-font16 rounded-[5px]`} placeholder={placeholder} />
          <ErrorMessage name={name} component={TextError} />
        </div>
        {/* <Field id={name} name={name} {...rest} className='w-96 h-14 bg-selectorBg rounded-xl' type='text'/> */}
      </div>
    </div>
  )
}

function InputTextBoxSmallHeight(props) {
  const { label, name, placeholder, bgColor, width, ...rest } = props

  return (
    <div className='flex items-center md:h-[46px] h-full flex-wrap md:flex-nowrap mb-8'>
      <label htmlFor={name} control='input' className='md:w-[25%] w-full text-sideBarBtnText text-3xl'>{label}</label>
      <div className='flex flex-wrap w-full small-textbox'>
        <Field as='input' id={name} name={name} {...rest} className={`${bgColor ? `bg-${bgColor}` : 'bg-selectorBg'} ${width ? `w-${width}` : 'w-[65%]'} text-font14 h-full px-10`} type='textarea' placeholder={placeholder} />
        <div className='relative w-full'>
          <div className='absolute'>
            <ErrorMessage name={name} component={TextError} />
          </div>
        </div>
      </div>
    </div>
  )
}
export { InputTextBox, InputTextBoxSmallHeight }