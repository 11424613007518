import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './textError';

function Checkbox(props) {
    const { label, name,defaultChecked, ...rest } = props;

    return (
        <div className="flex w-[100%] relative items-center h-20">
            <div className="w-[45%] text-2xl">
                <label htmlFor={name} className="text-popupText font-normal flex">
                    <Field id={name} name={name} type="checkbox"render={({ field }) => (
                            <input {...field} type="checkbox" checked={defaultChecked} {...rest} />
                        )}/>
                    <div className='pl-2'>
                        {label}
                    </div>
                </label>
            </div>
            <div className="w-[55%] flex flex-wrap pt-2">
                <div className="">
                    <ErrorMessage name={name} component={TextError} />
                </div>
            </div>
        </div>
    );
}

export default Checkbox;
